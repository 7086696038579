import React from "react";
import { CustomCard } from "../../CustomCard";
import { Box, Divider } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

//styles
import useStyles from "./styles";

function DocumentsContactCardSkeleton({ CardSkeletonNumber }) {
  const styles = useStyles();
  const skeletonCards = Array.from(
    { length: CardSkeletonNumber },
    (_, index) => (
      <CustomCard
        key={index}
        borderRadius="xl"
        borderColor="secondary"
        classes={styles.container_DocumentsContactCard}
      >
        <Box className={styles.ImageSection}>
          <Skeleton
            animation="wave"
            width="20px"
            height="30px"
            variant="text"
          />
          <Box className={styles.ImageWrapper}>
            <Skeleton
              animation="wave"
              width="178px"
              height="119px"
              variant="rectangular"
            />
          </Box>
          <Box className={styles.conatainer_MoreIcon}>
            <Skeleton
              className={styles.dot}
              variant="circle"
              width={3}
              height={3}
            />
            <Skeleton
              className={styles.dot}
              variant="circle"
              width={3}
              height={3}
            />
            <Skeleton
              className={styles.dot}
              variant="circle"
              width={3}
              height={3}
            />
          </Box>
        </Box>
        <Box className={styles.DetailsSection}>
          <Box className={styles.Title_Container}>
            <Skeleton
              animation="wave"
              width="150px"
              height="30px"
              variant="text"
            />
            <Box className={styles.containerType}>
              <Skeleton
                animation="wave"
                width="20px"
                height="30px"
                variant="text"
              />
              <Skeleton
                animation="wave"
                width="110px"
                height="30px"
                variant="text"
              />
            </Box>
          </Box>
          <Box className={styles.Date_Container}>
            <Divider className={styles.divider} />
            <Box className={styles.DateItem}>
              <Skeleton
                animation="wave"
                width="100px"
                height="30px"
                variant="text"
              />
              <Skeleton
                animation="wave"
                width="100%"
                height="30px"
                variant="text"
              />
            </Box>
          </Box>
        </Box>
      </CustomCard>
    )
  );
  return <>{skeletonCards}</>;
}
export default DocumentsContactCardSkeleton;
