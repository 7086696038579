import React, { useEffect, useState } from 'react'
import UnitOverviewComponent from '../Utilities/UnitOverviewComponent/UnitOverviewComponent'
import { GetInquiryMatchedUnitDetails } from '../../../../../../../Services';

const MatchedUnitOverview = ({
  unitId,
  actionButtons,
  inquiryId
}) => {

  const [data, setData] = useState({});

  const getInquiryMatchedUnitDetails = async () => {
    const res = await GetInquiryMatchedUnitDetails(unitId);
    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    }
  };

  useEffect(() => {
    if (unitId) getInquiryMatchedUnitDetails();
  }, [unitId]);

  return (
    <>
      <UnitOverviewComponent
        actionButtons={actionButtons}
        data={data}
        unitId={unitId}
        inquiryId={inquiryId}
        reload={getInquiryMatchedUnitDetails}
      />
    </>
  )
}

export default MatchedUnitOverview
