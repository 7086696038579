import React, { useState, useEffect, useCallback } from 'react';
import { CommunityTypeIdEnum } from '../../../../../../../../../Enums';
import { AutocompleteComponent } from '../../../../../../../../../Components';
import { lookupItemsGetId } from '../../../../../../../../../Services/LookupsServices';

export const DistrictComponent = ({
  parentTranslationPath,
   translationPath,
   addressLoadings ,
   setAddressLoadings ,
   labelClasses , 
   isSubmitted,
   helperText,
   error,
   onUpdateValue , 
   setAddressList , 
   addressList , 
   propertyValues , 
   isDisabled,
}) => {

  const GetCommunities = useCallback(async (districtId) => {
    setAddressLoadings((item) => ({ ...item, community :true }));
    const res = await lookupItemsGetId({
      lookupTypeId: CommunityTypeIdEnum.lookupTypeId,
      lookupParentId: districtId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setAddressList((item) => ({ ...item, communities: res }));

    }
    else
    setAddressList((item) => ({ ...item, communities: [] }));
    setAddressLoadings((item) => ({ ...item, community :false }));
  }, []);


  return (
   <div>
    <AutocompleteComponent
        idRef='DistrictRef'
        labelValue='district'
        selectedValues={propertyValues && propertyValues.district}
        isLoading={addressLoadings.district}
        labelClasses={labelClasses}
        data={addressList && addressList.districts  || []}
        multiple={false}
        displayLabel={(option) => (option && option.lookupItemName) || ''}
        withoutSearchButton
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
         setAddressList((item) => ({ ...item , communities : [] , subCommunities:[] }));
        if(newValue)
        GetCommunities(newValue.lookupItemId) ; 
         onUpdateValue(newValue);
        }}
       isSubmitted={isSubmitted}
       helperText={helperText}
       error={error}
       isWithError
       isDisabled={isDisabled}
    />
  </div>
  );
};
