import React from "react";
import { useSelector } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import clsx from "clsx";
import { AppBar, Toolbar, IconButton, Box, Icon } from "@material-ui/core";
import { useIsDesktop } from "../../../Hooks";
import { SwitchLayoutBtn } from "../../V2";
import { QuickAdd, Notifications, Settings, RenderCalendar, UserMenu } from "./UI";

import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

// Icons
import { ReactComponent as LightLogo } from "../../../assets/images/icons/light-logo.svg";
import { ReactComponent as DarkLogo } from "../../../assets/images/icons/dark-logo.svg";
import { ReactComponent as MobileLogo } from "../../../assets/images/icons/mobile-logo.svg";
import { ReactComponent as MobileMenuIcon } from "../../../assets/images/icons/mobile-menu.svg";

// Styles
import useStyles from "./styles";

function Header() {
  const styles = useStyles();
  const history = useHistory();

  const { isDarkMode } = useSelector((state) => state.theme);

  const { onToggleMobileSideNav } = useVerticalNav();
  
  const { isDesktop } = useIsDesktop();

  const iconColor = isDarkMode ? "white" : "#101828";

  const toggleMobileSideNav = () => {
    onToggleMobileSideNav();
  }

  return (
    <AppBar position="sticky" className={clsx(styles.header)}>
      <Toolbar className={styles.toolbar}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" alignItems="center" style={{ gap: "20px" }}>
            {!isDesktop && (
              <IconButton
                onClick={toggleMobileSideNav}
                style={{ padding: "8px" }}
              >
                <MobileMenuIcon fill={iconColor} />
              </IconButton>
            )}
            <NavLink to="/home">
              {isDesktop ? (isDarkMode ? <DarkLogo /> : <LightLogo />) : <MobileLogo fill={iconColor} />}
            </NavLink>
          </Box>
          {isDesktop ? 
          <Box display="flex" alignItems="center">
            <SwitchLayoutBtn />

            <QuickAdd />

            <Settings />

            <IconButton
              onClick={() => history.push("/home/my-tasks")}
              className={styles.menuButton}
              disableRipple
            >
              <Icon className={clsx("icon-header-menu", styles.menuIcon)}></Icon>
            </IconButton>

            <RenderCalendar />

            <Notifications />

            <UserMenu />
          </Box>
          : 
          <Box display="flex" alignItems="center">
            <UserMenu />
          </Box>
          }
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
