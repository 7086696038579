
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  ButtonBase } from '@material-ui/core';
import {
  TabsComponent,
  RadiosGroupComponent,
  CompletedDataComponent,
  Spinner
} from '../../../../../Components';
import { GetParams   } from '../../../../../Helper';
//import { ContactsVerticalTabsData } from '../../Common/OpenFileView/OpenFileUtilities/OpenFileData';
import { GetInquiryDetailsByIdService  } from '../../../../../Services';
import {  ZeroMatchingLeaseVerticalTabsData    } from './Sections' ; 
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const parentTranslationPath = 'Inquires';
const translationPath = '';

export const ZeroMatchingLeaseProfileManagementView  = () => {
  const history = useHistory();
  const location = useLocation();
  const isForMatchUnits = GetParams('isForMatchUnits');
  const [isLoading, setIsLoading] = useState(false);
  const [activeInquiryData, setActiveInquiryData] = useState(null);
  const [isCompletInquiry , setCompletInquiry] = useState(false)  ;
  const [inquiryEmptyFields ,setInquiryEmptyFields ] = useState(null); 
 
  const [activeTab, setActiveTab] = useState(0);
  const [viewType, setViewType] = useState(1);
  const activeItem = useSelector((state) => state.ActiveItemReducer);

  const [reloadInquiryData , setReloadInquiryData] = useState(null) ; 

  const [filterBy, setFilterBy] = useState({
    id: null,
    formType: null,
  });

  const pathName = window.location.pathname
  .split('/home/')[1]
  .split('/view')[0]
  .split('/')[0]; 


  const getInquiryDetails = useCallback(async (id) => {
    setIsLoading(true);
    const InquiryDetailsRes = await GetInquiryDetailsByIdService(id);
    if (!(InquiryDetailsRes && InquiryDetailsRes.status && InquiryDetailsRes.status !== 200)) {
      setInquiryEmptyFields(InquiryDetailsRes && InquiryDetailsRes.inquiryJson && JSON.parse(InquiryDetailsRes.inquiryJson)); 

        setActiveInquiryData(InquiryDetailsRes);
    } else {
        setActiveInquiryData(null);
    }
    setIsLoading(false);
  }, [isCompletInquiry , reloadInquiryData ]);


  useEffect(() => {
    const inquiryId = +GetParams('id');
    if (inquiryId) 
    getInquiryDetails(inquiryId);
  }, [getInquiryDetails]);


  const [formType, setformType] = useState(+GetParams('formType'));
  const [list, setList] = useState([]);

  const onTabChanged = (_, newTab) => {
    setActiveTab(newTab);
  };
  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
  };

  const matchedUnitsHandler = () => {
    const assignedUnitTabIndex = ZeroMatchingLeaseVerticalTabsData.findIndex(item => item.label === "Assigned-Units");
    setActiveTab(assignedUnitTabIndex);
    removeMatchedUnitsParam();
  }
  
  const removeMatchedUnitsParam = () => {
    const params = new URLSearchParams(location.search);
    params.delete("isForMatchUnits");
    history.replace(`${location.pathname}?${params.toString()}`);
  };

  useEffect(() => {
    setFilterBy({
      formType: (GetParams('formType') && +GetParams('formType')) || null,
      id: (GetParams('id') && +GetParams('id')) || null,
    });
    if (isForMatchUnits === "true") matchedUnitsHandler();

  }, []);

  return (
    <div className='contact-profile-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='d-flex-v-center'>
      <ButtonBase
              onClick={() => {} }
              disabled={activeInquiryData && activeInquiryData.inquiryStatus && activeInquiryData.inquiryStatus ===  'Completed'}
              className={'btns theme-solid mb-2 mx-2  px-4'}
            >
              { activeInquiryData && activeInquiryData.inquiryStatus} 
          </ButtonBase>
        <RadiosGroupComponent
          idRef='viewDataRef'
          data={[
            {
              key: 1,
              value: 'all-data',
            },
            {
              key: 2,
              value: 'missing-data',
            },
          ]}
          value={viewType}
          labelValue='view'
          labelInput='value'
          valueInput='key'
          themeClass='theme-line'
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          onSelectedRadioChanged={onViewTypeChangedHandler}
        />
      </div>
       
      <TabsComponent
        data = {ZeroMatchingLeaseVerticalTabsData}
        labelInput='label'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-solid'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          id: filterBy.id,
          viewType,
          parentTranslationPath,
          translationPath,
          setActiveTab, 
          activeTab,
          isCompletInquiry,
          setCompletInquiry ,
          typeOf:'2',
          activeInquiryData ,
          setReloadInquiryData  ,
          inquiryEmptyFields , 

        }}
      />
    </div>
  );
};