import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spinner, Tables } from '../../../../../Components';
import { GetParams } from '../../../../../Helper';
import { GetDeveloperAgentsAPI } from '../../../../../Services';

export const DeveloperProfileAgentsOverviewComponent = ({ 
  id,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [developerId, setDeveloperId] = useState(+GetParams('id'));
  const [agentsOverview, setAgentsOverview] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: localStorage.getItem('Pagination') || 25,
    pageIndex: 0,
  });
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const GetAllDeveloperAgents = useCallback(async () => {
    setIsLoading(true);
    const res = await GetDeveloperAgentsAPI({ developerId  : developerId , pageIndex : filter.pageIndex +1 , pageSize : filter.pageSize});
    if (!(res && res.status && res.status !== 200)) {
      setAgentsOverview({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAgentsOverview({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter]);

  useEffect(() => {
    GetAllDeveloperAgents();
  }, [GetAllDeveloperAgents]);

  return (
    <div className='childs-wrapper p-relative mt-3 px-2'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section'>
        <span>{t(`${translationPath}agents-overview`)}</span>
      </div>
      <div className='w-100 px-2'>
        <div className='filter-sections-wrapper' />
        <Tables
          data={agentsOverview.result}
          headerData={[
            {
              id: 1,
              label: 'agent-name',
              input: 'agentName',
            },
            {
              id: 2,
              label: 'staff-id',
              input: 'staffId',
            },
            {
              id: 3,
              label: 'branch-name',
              input: 'branchName',
            },
            {
              id: 4,
              label: 'mobile',
              input: 'mobile',
            },
            {
              id: 5,
              label: 'email',
              input: 'email',
            },
         
            {
              id:6,
              label:'number-of-transacted-units',
              input:'numberOfTransactedUnits'
            } ,
            {
              id: 7,
              label: 'specialty-scope',
              input: 'specialtyScope',
            },
            {
              id: 8,
              label: 'transaction-value',
              input: 'transactionValue',
            },

          ]}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          dateFormat='DD, MMM, YYYY'
          defaultActions={[]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={agentsOverview.totalCount}
        />
      </div>
    </div>
  );
};

DeveloperProfileAgentsOverviewComponent.propTypes = {
  id: PropTypes.number.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
