import React, { useState, useEffect, useCallback } from 'react';
import { StaticLookupsIds } from '../../../../../../../../../assets/json/StaticLookupsIds';
import { AutocompleteComponent } from '../../../../../../../../../Components';
import { lookupItemsGetId } from '../../../../../../../../../Services/LookupsServices';

export const PropertyTypeComponent = ({
  parentTranslationPath,
  translationPath,
  labelClasses,
   isSubmitted,
   helperText,
   error,
   onUpdateValue,
   value,
   isDisabled,
}) => {
  const [allPropertyType, setAllPropertyType] = useState([]);
  const GetAllPropertyPlan = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.PropertyType,
    });

    if (!(res && res.status && res.status !== 200))
      setAllPropertyType(res);
     else
    setAllPropertyType([]);
  }, []);

  useEffect(() => {
    GetAllPropertyPlan();
  }, [GetAllPropertyPlan]);

  return (
    <div>
      <AutocompleteComponent
        idRef='PropertyTypeRef'
        labelValue='Property Type'
        labelClasses={labelClasses}
        data={allPropertyType || []}
        selectedValues={value}
        multiple={false}
        displayLabel={(option) => (option && option.lookupItemName) || ''}
        withoutSearchButton
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
           onUpdateValue(newValue);
          }}
        isSubmitted={isSubmitted}
        helperText={helperText}
        error={error}
        isWithError
        isDisabled={isDisabled}
      />

    </div>
  );
};
