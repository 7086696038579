import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Box, Divider } from "@material-ui/core";
import {
  CustomAutocomplete,
  CustomCheckbox,
} from "../../../../../../../Components";

import { EmailIcon, WhatsappIcon } from "../../../../../../../assets/icons";
import { useSelectedTheme } from "../../../../../../../Hooks";
import {
  AddShareDocumentLog,
  GetLeads,
  OrganizationUserSearch,
  ShareScopeDocuments,
} from "../../../../../../../Services";
import { LeadsStatusEnum, SharedViaEnum } from "../../../../../../../Enums";
import { useDebouncedAction } from "../../../../../../../Hooks/DebouncedAction";
import {
  GetParams,
  getPublicDownloadableLink,
  showError,
  showSuccess,
} from "../../../../../../../Helper";
import SelectedChip from "./SelectedChip";

// Styles
import useStyles from "./styles";
import Joi from "joi";
function ContactShareDocumentsDialogContent({
  document,
  translate,
  isShareConfirmed,
  setIsShareConfirmed,
  setIsShareDocumentsDialogOpen,
}) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const contactId = +GetParams("id");
  const [isShareViaEmail, setIsShareViaEmail] = useState(true);
  const [selected, setSelected] = useReducer(reducer, {
    emailSubject: null,
    emailBody: null,
    messageBody: null,
    systemUsers: [],
    selectedLeads: [],
  });
  const [data, setData] = useReducer(reducer, {
    systemUsers: [],
    leads: [],
  });

  const getRecipientWithMissingWhatsAppNo = () => {
    if (isShareViaEmail) {
      const missingNumbersInUsers = selected?.systemUsers?.some(
        (user) => !user?.whatsAppNumber
      );
      const missingNumbersInLeads = selected?.selectedLeads?.some(
        (lead) => !lead?.contactWhatsApp
      );

      if (missingNumbersInUsers || missingNumbersInLeads) return false;

      return true;
    }

    const isNumberExist =
      selected?.systemUsers?.whatsAppNumber ||
      selected?.selectedLeads?.contactWhatsApp;

    return !isNumberExist;
  };

  function getUsersWithMissingEmails() {
    const usersList = selected?.systemUsers
      ? selected?.systemUsers?.filter((item) => !item.email)
      : [];

    if (usersList.length != 0) {
      const userNamesList = usersList.map((item) => item.fullName);
      const userNamesString = userNamesList.reduce(
        (result, item) => result + item + " , ",
        ""
      );
      return userNamesString;
    }
  }

  const debouncedGetLeads = useDebouncedAction(getLeads, 700);
  const debouncedGetSystemUsers = useDebouncedAction(getSystemUsers, 700);
  async function getSystemUsers(value) {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: value || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: "systemUsers",
          value: localValue,
        });
      } else {
        setData({
          id: "systemUsers",
          value: [],
        });
      }
    }
  }

  async function getLeads(value) {
    const res = await GetLeads({
      pageIndex: 0,
      pageSize: 10,
      search: value || "",
      leadStatus: LeadsStatusEnum.Open.status,
    });
    if (!(res && res.status && res.status !== 200))
      setData({
        id: "leads",
        value: (res && res.result) || [],
      });
    else setData({ id: "leads", value: [] });
  }

  function getLeadsWithMissingEmails() {
    const leadList = selected.selectedLeads
      ? selected.selectedLeads.filter((item) => !item?.contactEmailAddress)
      : [];

    if (leadList.length != 0) {
      const leadNamesList = leadList.map(
        (item) => item.lead?.contact_name?.name
      );
      const leadNamesString = leadNamesList.reduce(
        (result, item) => result + item + " , ",
        ""
      );
      return leadNamesString;
    }
  }

  const changeShareType = (value) => {
    setSelected({
      id: "edit",
      value: {
        ...selected,
        systemUsers: value ? [] : null,
        selectedLeads: value ? [] : null,
      },
    });
    setIsShareViaEmail(value);
  };

  async function getWhatsAppDocUrl() {
    let URL = "";
    const fileURL = document?.documentId
      ? getPublicDownloadableLink(document?.documentId)
      : "";
    const isLink = document?.documentLink;
    URL = isLink ? document?.documentLink : fileURL;

    const finalURL = `Please find the document you requested here: \n\n${URL}`;
    return finalURL;
  }

  async function fillInitialWhatsappMsgBody() {
    const msg = await getWhatsAppDocUrl();
    setSelected({ id: "messageBody", value: msg });
  }

  function getShareDocsAPIBody() {
    const mappedSystemUsers =
      (selected?.systemUsers &&
        selected?.systemUsers.map((item) => item?.id)) ||
      [];
    const mappedLeads =
      (selected?.selectedLeads &&
        selected?.selectedLeads?.map((item) => item?.leadId)) ||
      [];

    const mappedFiles =
      document && document.documentName && document.documentId
        ? [
            {
              fileName: document.documentName,
              uuid: document.documentId,
            },
          ]
        : [];
    const mappedLinks =
      document && document.documentLink ? [document.documentLink] : [];

    return {
      systemUsersId: mappedSystemUsers,
      leadsId: mappedLeads,
      files: mappedFiles,
      links: mappedLinks,
      emailSubject: selected.emailSubject,
      emailBody: selected.emailBody,
      categoryId: document?.categoryId,
      contactId,
    };
  }

  function getShareDocumentLogWahtsAppAPIBody() {
    const SystemUser = selected?.systemUser?.id
      ? [selected?.systemUsers.id]
      : [];
    const Lead = selected?.selectedLeads?.leadId
      ? [selected?.selectedLeads?.leadId]
      : [];
    const mappedFiles =
      document && document.documentName && document.documentId
        ? [
            {
              fileName: document.documentName,
              uuid: document.documentId,
            },
          ]
        : [];
    const mappedLinks =
      document && document.documentLink ? [document.documentLink] : [];
    return {
      systemUsersId: SystemUser,
      leadsId: Lead,
      files: mappedFiles,
      links: mappedLinks,
      emailSubject: selected.emailBody,
      emailBody: selected.emailBody,
      categoryId: document?.categoryId,
      contactId,
      sharedVia: SharedViaEnum.WhatsApp.id,
    };
  }

  async function shareTempleteViaWhatsAppToDocumentLog() {
    const isWhatsAppLog = true;
    const body = getShareDocumentLogWahtsAppAPIBody();
    const res = await AddShareDocumentLog(body, isWhatsAppLog);
    if (res === true) {
      showSuccess(translate("template-whats-up-sent-successfully"));
      setIsShareDocumentsDialogOpen(false);
    } else {
      showError(translate("template-whats-up-sent-failed"));
    }
  }

  function shareViaWhatsApp() {
    const WhatsAppMsg = selected.messageBody || "";
    const UrlEncodedMessage = encodeURIComponent(WhatsAppMsg);
    const whatsAppNumber =
      selected?.systemUsers?.whatsAppNumber ||
      selected.selectedLeads?.contactWhatsApp;

    if (!whatsAppNumber) {
      showError(translate("Recipient's WhatsApp number is missing."));
      return;
    }

    const UrlToWhatsApp = `https://wa.me/${whatsAppNumber}?text=${UrlEncodedMessage}`;
    window.open(UrlToWhatsApp);
    shareTempleteViaWhatsAppToDocumentLog();
  }

  async function shareViaEmail() {
    const body = getShareDocsAPIBody();

    const res = await ShareScopeDocuments(body, { isFromDeveloper: false });

    if (!(res && res.status && res.status !== 200)) {
      showSuccess(translate("document-sent-successfully"));
      setIsShareDocumentsDialogOpen(false);
    } else {
      showError(translate("sending-document-failed"));
    }
  }

  const EmailFormSchema = Joi.object({
    emailSubject: Joi.string()
      .required()
      .messages({
        "string.base": translate(`emailSubject-is-required`),
        "string.empty": translate(`emailSubject-is-required`),
      }),
    emailBody: Joi.string()
      .required()
      .messages({
        "string.base": translate("emailBody-is-required"),
        "string.empty": translate("emailBody-is-required"),
      }),
    selectedLeads: Joi.any()
      .custom((value, helpers) => {
        if (
          selected?.selectedLeads?.length === 0 &&
          selected?.systemUsers?.length === 0
        )
          return helpers.error("state.required");

        if (getLeadsWithMissingEmails() || getUsersWithMissingEmails())
          return helpers.error("recipient-With-Missing-Email-error");

        return value;
      })
      .messages({
        "state.required": translate(`Recipient-is-required`),
        "recipient-With-Missing-Email-error": translate(
          `There-Are-recipient-With-Missing-Email-Address`
        ),
      }),
    systemUsers: Joi.any()
      .custom((value, helpers) => {
        if (
          selected?.systemUsers?.length === 0 &&
          selected?.selectedLeads?.length === 0
        )
          return helpers.error("state.required");

        if (getLeadsWithMissingEmails() || getUsersWithMissingEmails())
          return helpers.error("recipient-With-Missing-Email-error");

        return value;
      })
      .messages({
        "state.required": translate(`Recipient-is-required`),
        "recipient-With-Missing-Email-error": translate(
          `There-Are-recipient-With-Missing-Email-Address`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);

  const WhatsAppFormSchema = Joi.object({
    selectedLeads: Joi.any()
      .custom((value, helpers) => {
        if (!selected?.selectedLeads && !selected?.systemUsers)
          return helpers.error("state.required");

        if (getRecipientWithMissingWhatsAppNo()) {
          return helpers.error("recipient-With-Missing-WhatsAppNo-error");
        }

        return value;
      })
      .messages({
        "state.required": translate(`Recipient-is-required`),
        "recipient-With-Missing-WhatsAppNo-error": translate(
          `There-Are-recipient-With-Missing-WhatsApp-Number`
        ),
      }),
    systemUsers: Joi.any()
      .custom((value, helpers) => {
        if (!selected?.systemUsers && !selected?.selectedLeads)
          return helpers.error("state.required");

        if (getRecipientWithMissingWhatsAppNo()) {
          return helpers.error("recipient-With-Missing-WhatsAppNo-error");
        }

        return value;
      })
      .messages({
        "state.required": translate(`Recipient-is-required`),
        "recipient-With-Missing-WhatsAppNo-error": translate(
          `There-Are-recipient-With-Missing-WhatsApp-Number`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);

  function handleShare() {
    if (isShareViaEmail) {
      if (EmailFormSchema?.error?.message) {
        showError(translate(EmailFormSchema.error.message));
        return;
      }
    } else {
      if (WhatsAppFormSchema?.error?.message) {
        showError(translate(WhatsAppFormSchema.error.message));
        return;
      }
    }

    if (isShareViaEmail === true) {
      shareViaEmail();
    } else if (isShareViaEmail === false) {
      shareViaWhatsApp();
    }
  }

  useEffect(() => {
    if (isShareConfirmed) {
      handleShare();
      setIsShareConfirmed(false);
    }
  }, [isShareConfirmed]);

  useEffect(() => {
    if (!selected.whatsappMsgBody) fillInitialWhatsappMsgBody();
  }, []);

  return (
    <React.Fragment>
      <div>
        <Box className={styles.shareViaWrapper}>
          <Box className={styles.sectionNameStyles}>
            <span>{translate("share-via")}</span>
          </Box>
          <Box
            className={`${styles.shareViaItem} ${
              isShareViaEmail && styles.selectedBox
            }`}
            onClick={() => changeShareType(true)}
          >
            <EmailIcon
              width="38"
              height="38"
              fill={styles.shareViaIconOptionStyles}
            />
            <span className={styles.shareViaNameOption}>
              {translate("email")}
            </span>
            <CustomCheckbox
              className={styles.checkBoxComponent}
              checked={isShareViaEmail}
              onChange={() => changeShareType(true)}
            />
          </Box>
          <Box
            className={`${styles.shareViaItem} ${
              !isShareViaEmail && styles.selectedBox
            }`}
            onClick={() => changeShareType(false)}
          >
            <WhatsappIcon
              width="38"
              height="38"
              fill={styles.shareViaIconOptionStyles}
            />
            <span className={styles.shareViaNameOption}>
              {translate("whatsapp")}
            </span>
            <CustomCheckbox
              className={styles.checkBoxComponent}
              checked={!isShareViaEmail}
              onChange={() => changeShareType(false)}
            />
          </Box>
        </Box>

        {isShareViaEmail && (
          <React.Fragment>
            <Divider className={styles.divider} light />
            <Box className={styles.shareDocumentDialogEmailSubjectSection}>
              <Box className={styles.sectionNameStyles}>
                <span>{translate("email-subject")}</span>
              </Box>
              <input
                className={styles.emailSubject}
                placeholder={translate("enter")}
                value={selected.emailSubject}
                onChange={(e) => {
                  setSelected({
                    id: "emailSubject",
                    value: e?.target?.value || null,
                  });
                }}
              />
            </Box>
          </React.Fragment>
        )}

        <Divider className={styles.divider} light />

        <Box
          className={
            isShareViaEmail
              ? styles.shareDocumentDialogSection
              : `${styles.shareDocumentDialogSection} ${styles.textPlaceHolderColorForSendViaWhatsApp}`
          }
        >
          <span className={styles.sectionNameStyles}>
            {isShareViaEmail
              ? translate("email-body")
              : translate("message-body")}
          </span>
          <textarea
            key={`shared via email ${isShareViaEmail}`}
            className={styles.textAreaInput}
            placeholder={
              isShareViaEmail
                ? translate("enter-email-body")
                : translate("enter-message-body")
            }
            value={
              isShareViaEmail ? selected?.emailBody : selected?.messageBody
            }
            onChange={(e) => {
              if (isShareViaEmail) {
                setSelected({
                  id: "emailBody",
                  value: e?.target?.value || null,
                });
              } else {
                setSelected({
                  id: "messageBody",
                  value: e?.target?.value || null,
                });
              }
            }}
          />
        </Box>

        <Divider className={styles.divider} light />

        <Box className={styles.shareDocumentDialogSection}>
          <Box className={styles.sectionNameStyles}>
            <span>{translate("send-to")}</span>
          </Box>
          <Box className={styles.shareDocumentDialogSendToOptionsContainer}>
            <CustomAutocomplete
              variant="icon"
              placeholder={translate("select-system-users")}
              value={selected?.systemUsers || []}
              options={data?.systemUsers || []}
              renderOption={(option) => option?.fullName || ""}
              getOptionLabel={(option) => option?.fullName || ""}
              chipsLabel={(option) => option?.fullName || ""}
              isDisabled={!isShareViaEmail && selected?.selectedLeads}
              multiple={isShareViaEmail}
              renderTags={(e, getTagProps) => (
                <div className={styles.chipsContainer}>
                  {e.map((option, index) => (
                    <SelectedChip {...getTagProps({ index })}>
                      {option?.fullName || ""}
                    </SelectedChip>
                  ))}
                </div>
              )}
              onChange={(e, newValue) => {
                setSelected({
                  id: "systemUsers",
                  value: newValue || null,
                });
              }}
              onInputKeyUp={(e) => {
                const value = e?.target?.value || null;
                debouncedGetSystemUsers(value);
              }}
              onOpen={() => {
                if (data.systemUsers && data.systemUsers.length == 0)
                  getSystemUsers();
              }}
            />
            <CustomAutocomplete
              variant="icon"
              placeholder={translate("select-leads")}
              value={selected?.selectedLeads || []}
              options={data?.leads || []}
              isDisabled={!isShareViaEmail && selected?.systemUsers}
              renderOption={(option) => option?.lead?.contact_name?.name || ""}
              chipsLabel={(option) => option?.lead?.contact_name?.name || ""}
              getOptionLabel={(option) =>
                option?.lead?.contact_name?.name || ""
              }
              multiple={isShareViaEmail}
              renderTags={(e, getTagProps) => (
                <div className={styles.chipsContainer}>
                  {e.map((option, index) => (
                    <SelectedChip {...getTagProps({ index })}>
                      {option?.lead?.contact_name?.name || ""}
                    </SelectedChip>
                  ))}
                </div>
              )}
              onChange={(e, newValue) => {
                setSelected({
                  id: "selectedLeads",
                  value: newValue || null,
                });
              }}
              onInputKeyUp={(e) => {
                const value = e?.target?.value || null;
                debouncedGetLeads(value);
              }}
              onOpen={() => {
                if (data?.leads?.length === 0) getLeads();
              }}
            />
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
}

export default ContactShareDocumentsDialogContent;
