import React from "react";

function ErrorImageIcon({ fill, ...restProps }) {
  return (
    <svg
      width="115"
      height="83"
      viewBox="0 0 115 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="58.625" cy="40" r="40" fill="#EAECF0" />
      <circle cx="16.625" cy="10" r="4" fill="#F2F4F7" />
      <circle cx="102.625" cy="63" r="3" fill="#F2F4F7" />
      <circle cx="16.125" cy="69" r="5" fill="#F2F4F7" />
      <circle cx="108.625" cy="19" r="5" fill="#F2F4F7" />
      <circle cx="99.125" cy="5.5" r="3.5" fill="#F2F4F7" />
      <g filter="url(#filter0_dd_18447_128352)">
        <path
          d="M31.6646 62.1068L57.8903 49.8776C59.207 49.2636 59.7767 47.6984 59.1627 46.3817L43.6749 13.168L33.1872 9.35079L11.7299 19.3565C10.4131 19.9705 9.84345 21.5357 10.4575 22.8524L28.1687 60.8344C28.7827 62.1511 30.3479 62.7208 31.6646 62.1068Z"
          fill="url(#paint0_linear_18447_128352)"
        />
        <path
          d="M33.1875 9.35083L43.6752 13.168L36.5227 16.5033L33.1875 9.35083Z"
          fill="#D0D5DD"
        />
      </g>
      <g filter="url(#filter1_dd_18447_128352)">
        <path
          d="M44.2009 53.1697H73.1377C74.5906 53.1697 75.7683 51.9919 75.7683 50.5391V13.8919L67.8765 6H44.2009C42.7481 6 41.5703 7.17777 41.5703 8.63062V50.5391C41.5703 51.9919 42.7481 53.1697 44.2009 53.1697Z"
          fill="url(#paint1_linear_18447_128352)"
        />
        <path d="M67.877 6L75.7688 13.8919H67.877V6Z" fill="#D0D5DD" />
      </g>
      <g filter="url(#filter2_dd_18447_128352)">
        <path
          d="M59.0802 49.862L85.3059 62.0912C86.6226 62.7052 88.1878 62.1355 88.8018 60.8188L104.29 27.6052L100.472 17.1175L79.015 7.11175C77.6983 6.49775 76.1331 7.06742 75.5191 8.38415L57.8078 46.3661C57.1938 47.6828 57.7635 49.248 59.0802 49.862Z"
          fill="url(#paint2_linear_18447_128352)"
        />
        <path
          d="M100.473 17.1174L104.29 27.6051L97.1374 24.2699L100.473 17.1174Z"
          fill="#D0D5DD"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_18447_128352"
          x="-0.654296"
          y="6.0155"
          width="70.9277"
          height="77.203"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_18447_128352"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18447_128352"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_18447_128352"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_18447_128352"
            result="effect2_dropShadow_18447_128352"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_18447_128352"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_18447_128352"
          x="31.5703"
          y="6"
          width="54.1992"
          height="67.1697"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_18447_128352"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18447_128352"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_18447_128352"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_18447_128352"
            result="effect2_dropShadow_18447_128352"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_18447_128352"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_18447_128352"
          x="46.6953"
          y="6"
          width="70.9297"
          height="77.203"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_18447_128352"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18447_128352"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_18447_128352"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_18447_128352"
            result="effect2_dropShadow_18447_128352"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_18447_128352"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_18447_128352"
          x1="30.1239"
          y1="62.2092"
          x2="9.2241"
          y2="24.5044"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E4E7EC" />
          <stop offset="1" stop-color="#F9FAFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18447_128352"
          x1="42.7613"
          y1="52.6113"
          x2="39.7544"
          y2="9.60657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E4E7EC" />
          <stop offset="1" stop-color="#F9FAFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_18447_128352"
          x1="58.0114"
          y1="48.7475"
          x2="73.4609"
          y2="8.50121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E4E7EC" />
          <stop offset="1" stop-color="#F9FAFB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export default ErrorImageIcon;