import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => {
  return {
    containeAddNewLeadsDialog: {
      padding: "48px 80px",
      display: "flex",
      flexDirection: "row",
      gap: "32px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        padding: "20px 30px",
      },
    },
    containerBox: {
      padding: "24px",
      border: `1px solid ${theme.palette.background.quarterary}`,
      borderRadius: "12px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
      "&:hover": {
        borderColor: theme.palette.text.brand_tertiary,
        cursor: "pointer",
      },
    },
    titleBox: {
      fontSize: "24px",
      fontWeight: "600",
      lineHeight: "32px",
      color: theme.palette.text.primary,
    },
    descriptionBox: {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      color: theme.palette.text.tertiary,
    },
  };
});
