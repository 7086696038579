import React from 'react';
// import moment from 'moment';
import { UserAccountTypeEnum } from '../../../../../../../../Enums';

export const DuplicateContactsTableHeaderData = [
  {
    id: 1,
    label: 'name',
    input: 'name',
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
  },
  {
    id: 2,
    label: 'type',
    input: 'userType',
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
  },
  {
    id: 3,
    label: 'class',
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    component: (item) =>
      (item.accountType && UserAccountTypeEnum[item.accountType].tableImg && (
        <img src={UserAccountTypeEnum[item.accountType].tableImg} alt='account-type' />
      )) || <span />,
  },
  {
    id: 4,
    label: 'creation',
    input: 'creationDate',
    isHiddenFilter: true,
    fieldKey: 'createdOn',
    isDefaultFilterColumn: true,
    isDate: true,
  },
  {
    id: 5,
    label: 'progress',
    input: 'progressWithPercentage',
    fieldKey: 'data_completed',
    textInputType: 'number',
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
  },
  {
    id: 6,
    label: 'email',
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    component: (item) =>
      (item.details && item.details.map((el) => el.title === 'email' && el.value)) || <span />,
  },
  {
    id: 8,
    label: 'nationality',
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    component: (item) =>
      (item.details && item.details.map((el) => el.title === 'nationality' && el.value)) || (
        <span />
      ),
  },
  {
    id: 9,
    label: 'location',
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    component: (item) =>
      (item.details && item.details.map((el) => el.title === 'location' && el.value)) || <span />,
  },
];
