import { StaticLookupsIds } from '../../assets/json/StaticLookupsIds';

export const propertyFields = [
  {
    label: 'property_name',
    name: 'propertyName',
    type: 'text',
    required: true,
  },
  {
    label: 'property_type',
    name: 'propertyType',
    type: 'lookup',
    lookupItemId: StaticLookupsIds.PropertyType,
    required: true,
  },
  {
    label: 'country',
    name: 'country',
    type: 'lookup',
    lookupItemId: StaticLookupsIds.Country,
    required: true,
  },
  {
    label: 'city',
    name: 'city',
    type: 'lookup',
    lookupItemId: StaticLookupsIds.Cities,
    dependsOnLookup: 'country',
    required: true,
  },
  {
    label: 'district',
    name: 'district',
    type: 'lookup',
    lookupItemId: StaticLookupsIds.District,
    dependsOnLookup: 'city',
    required: true,
  },
  {
    label: 'community',
    name: 'community',
    type: 'lookup',
    lookupItemId: StaticLookupsIds.Community,
    dependsOnLookup: 'district',
    required: true,
  },
  {
    label: 'sub_community',
    name: 'subCommunity',
    type: 'lookup',
    lookupItemId: StaticLookupsIds.SubCommunity,
    dependsOnLookup: 'community',
  },
];
