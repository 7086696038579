import React from "react";

function AudioIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 4C4.75 2.20508 6.20508 0.75 8 0.75H24C24.1212 0.75 24.2375 0.798159 24.3232 0.883885L35.1161 11.6768C35.2018 11.7625 35.25 11.8788 35.25 12V36C35.25 37.7949 33.7949 39.25 32 39.25H8C6.20507 39.25 4.75 37.7949 4.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M24 0.5V8C24 10.2091 25.7909 12 28 12H35.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M16.85 28.5V19.7666C16.85 19.4054 16.85 19.2249 16.9157 19.0784C16.9736 18.9493 17.0669 18.8392 17.1847 18.7609C17.3183 18.672 17.4965 18.6423 17.8527 18.5829L24.4527 17.4829C24.9334 17.4028 25.1738 17.3627 25.3611 17.4323C25.5255 17.4934 25.6633 17.6101 25.7505 17.7622C25.85 17.9355 25.85 18.1792 25.85 18.6666V27M16.85 28.5C16.85 29.7427 15.8426 30.75 14.6 30.75C13.3573 30.75 12.35 29.7427 12.35 28.5C12.35 27.2574 13.3573 26.25 14.6 26.25C15.8426 26.25 16.85 27.2574 16.85 28.5ZM25.85 27C25.85 28.2427 24.8426 29.25 23.6 29.25C22.3573 29.25 21.35 28.2427 21.35 27C21.35 25.7574 22.3573 24.75 23.6 24.75C24.8426 24.75 25.85 25.7574 25.85 27Z"
        stroke="#DD2590"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default AudioIcon;
