/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useReducer, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { StepperComponent, UploaderComponent } from "../../../../Components";
import { Background } from "react-flow-renderer";

const translationPath = "dialogs.uploadDialog.";
export const UploadDialog = (props) => {
  const { t } = useTranslation(["FormBuilder", "Shared"]);
  const reducer = (state, action) => ({ ...state, [action.id]: action.value });
  const [state, setState] = useReducer(reducer, props.initialState);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});

  const totalSteps = () => props.initialState.selected.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    // It's the last step, but not all steps have been completed,
    // find the first step that has been completed
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? props?.initialState?.selected?.findIndex(
            (step, i) => !(i in completed)
          )
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  useEffect(() => {
    Object.keys(state).map(
      (el) =>
        el !== "selected" &&
        !props.initialState.selected?.some((a) => a.lookupItemName === el) &&
        setState({ id: el, value: [] })
    );
  }, [props.initialState]);
  
  return (
    <Dialog className="dialog" open={props.open} fullWidth maxWidth="md">
      <DialogTitle>{t(`${translationPath}upload-attachments`)}</DialogTitle>
      <DialogContent>
        <div className="w-100 mb-2">
          <StepperComponent
            steps={
              (props?.initialState?.selected &&
                props?.initialState?.selected.length > 0 &&
                props?.initialState?.selected) ||
              0
            }
            labelInput="lookupItemName"
            onStepperClick={(index) => {
              handleStep(index);
            }}
            activeStep={activeStep}
          />
        </div>
        {props.initialState.selected.map(
          (label, index) =>
            activeStep !== -1 &&
            props?.initialState?.selected[activeStep]?.lookupItemName ===
              label?.lookupItemName && (
              <UploaderComponent
                key={`importFileRef${index + 1}`}
                multiple
                isOpenGallery
                openGallery
                isDocuments
                idRef="importFileRef"
                accept={"image/*"}
                viewUploadedFilesCount
                initUploadedFiles={state[label?.lookupItemName] || []}
                uploadedChanged={(files) =>
                  setState({ id: label?.lookupItemName, value: files })
                }
              />
            )
        )}
      </DialogContent>
      <DialogActions className="d-flex-inline d-flex-h-bteween">
          {activeStep !== -1 && activeStep !== 0 && (
            <Button
              onClick={() => handleBack()}
              className="btns theme-solid bg-warning"
            >
              <span className="mdi mdi-chevron-double-left" />
              {t("Shared:back")}
            </Button>
          )}
          {!isLastStep() && (
            <Button
              onClick={() => handleNext()}
              className="btns theme-solid bg-secondary"
              id={t("Shared:next")}
            >
              {t("Shared:next")}
              <span className="mdi mdi-chevron-double-right" />
            </Button>
          )}
          <Button
            className="btns theme-solid bg-cancel"
            onClick={() => {
              props.closeDialog();
              setActiveStep(0);
            }}
          >
            {t("Shared:cancel")}
          </Button>
         

          {isLastStep() && (
            <>
             <Button
             className=" btns theme-solid"
             style={{ "background-color": "#368750e3" }}
             onClick={() => {
               Object.keys(state).map(
                 (el) => el !== "selected" && setState({ id: el, value: [] })

               );
               props.onChange(state , 'clearAll');
               props.closeDialog();
               setActiveStep(0);
             }}
           >
             {t("Shared:clear-all-and-save")}
           </Button>
            <Button
              className="btns theme-solid"
              onClick={(event) => {
                event.preventDefault();
                props.onChange(state);
                props.closeDialog();
                setActiveStep(0);
              }}
            >
              {t("Shared:save")}
            </Button>
            </>
          )}
      
      </DialogActions>
    </Dialog>
  );
};
