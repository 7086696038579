import { isEmpty } from 'lodash';
import { GetContacts } from '../../Services';

export const checkDuplication = async (value) => {
  const res = await GetContacts({
    pageIndex: 0,
    pageSize: 1,
    search: value,
    isAdvance: false,
  });

  return !isEmpty(res.result);
};
