import React, { useState, useEffect, useReducer, useRef } from "react";
import { ButtonBase } from "@material-ui/core";
import Joi from "joi";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../../Components";
import {
  GetParams,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../Helper";
import { CommissionTypes } from "../../../../../Enums";
import {
  CreateCommissionDetailsAPI,
  GetCommissionDetailsByDeveloperIdAPI,
} from "../../../../../Services";
import {
  CommissionDetailsTable,
  CommissionDetailsViewComponent,
} from "./CommissionDetailsComponent";

export const CommissionDetailsView = ({
  translationPath,
  parentTranslationPath,
  contactReletedWithDeveloperId,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const searchTimer = useRef(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [isReloadData, setIsReloadData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isLoading, setIsLoading] = useState({
    addCommission: false,
    allCommissions: false,
  });

  const [items, setItems] = useState({
    allCommissions: {
      result: [],
      totalCount: 0,
    },
  });

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    valueFrom: null,
    valueTo: null,
    commission: null,
    paymentTerms: null,
    commissionType: null,
    remarks: null,
    commissionProjects: [],
  });

  const [defaultSelected, setDefaultSelected] = useState({
    commissionType: null,
    commissionProjects: [],
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const schema = Joi.object({
    commissionType: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}commission-type-is-required`),
        "number.empty": t(`${translationPath}commission-type-is-required`),
        "number.required": t(`${translationPath}commission-type-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const GetCommissionDetailsByAgreementId = async (search) => {
    setIsLoading((item) => ({ ...item, allCommissions: true }));
    const rs = await GetCommissionDetailsByDeveloperIdAPI({
      developerContactId: +GetParams("id"),
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
      name: search,
    });
    if (!rs) {
      setItems((item) => ({
        ...item,
        allCommissions: { result: [], totalCount: 0 },
      }));
      return;
    } else {
      setItems((item) => ({
        ...item,
        allCommissions: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    }
    setIsLoading((item) => ({ ...item, allCommissions: false }));
  };

  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    setSelected({ id: "edit", value: defaultSelected });
    GetCommissionDetailsByAgreementId();
  };

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };

  const CreateCommissionDetailsHandler = async () => {
    setIsLoading((item) => ({ ...item, addCommission: true }));
    const body = { ...state };
    if (
      state.commissionType === CommissionTypes.specificProjects.key &&
      state.commissionProjects &&
      !state.commissionProjects.length
    ) {
      setIsLoading((item) => ({ ...item, addCommission: false }));
      showWarn(
        t`${translationPath}this-contact-developer-havent-master-developer-on-any-properties-please-select-properties-and-select-master-developer-with-this-contact-contact-id` +
          `${contactReletedWithDeveloperId}`
      );
      return;
    }
    const res = await CreateCommissionDetailsAPI(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}commission-details-created-successfully`);
      setIsLoading((item) => ({ ...item, addCommission: false }));
      setIsReloadData(
        isReloadData === null || isReloadData === false ? true : false
      );
      reloadData();
    } else {
      showError(t`${translationPath}commission-details-create-failed`);
      setIsLoading((item) => ({ ...item, addCommission: false }));
    }
  };

  useEffect(() => {
    if (state.developerContactId) reloadData();
  }, [filter]);

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <Spinner
          isActive={isLoading.allCommissions || isLoading.addCommission}
          isAbsolute
        />

        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <CommissionDetailsViewComponent
              state={state}
              schema={schema}
              selected={selected}
              onChangeHandler={onChangeHandler}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              className={"px-2 item"}
              contactReletedWithDeveloperId={contactReletedWithDeveloperId}
            />
          </div>
          <div className="mt-3 d-flex-v-center-h-end flex-wrap">
            <ButtonBase
              className="btns theme-solid"
              onClick={CreateCommissionDetailsHandler}
              disabled={isLoading.addCommission || !selected.commissionType}
            >
              <span className="mdi mdi-plus" />
              {t(`${translationPath}add-commission`)}
            </ButtonBase>
          </div>
        </div>
      </div>
      <CommissionDetailsTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        data={items.allCommissions}
        filter={filter}
        setFilter={setFilter}
        reloadData={reloadData}
        contactReletedWithDeveloperId={contactReletedWithDeveloperId}
      />
    </div>
  );
};
