import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  shareViaWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "640px",
    maxWidth: "640px",
    gap: "16px",
    [theme.breakpoints.down("768")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "25px",
      width: "100%",
      margin: 0,
    },
  },

  sectionNameStyles: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
    width: "160px",
    marginRight: "16px",
    [theme.breakpoints.down("768")]: {
      width: "100%",
      marginLeft: "40px",
    },
  },

  shareViaItem: {
    width: "216px",
    height: "102px",
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.background.primary,
    padding: "16px",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    gap: "8px",
    cursor: "pointer",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down("450")]: {
      height: "125px",
      width: "100%",
    },
  },

  selectedBox: {
    border: `2px solid ${theme.palette.primary.main}`,
  },

  shareDocumentDialogSection: {
    display: "flex",
    flexDirection: "row",
    width: "640px",
    maxWidth: "640px",
    minHeight: "104px",
    maxHeight: "fit-content",
    alignItems: "start",
    justifyContent: "space-between",
    "& > *:nth-child(1)": {
      width: "160px",
      marginRight: "32px",
      [theme.breakpoints.down("768")]: {
        width: "100%",
        alignItems: "center",
      },
    },
    "& > *:nth-child(2)": {
      width: "448px",
      backgroundColor: theme.palette.background.primary,
      "&:focus": {
        boxShadow: theme.shadows[12],
      },
      [theme.breakpoints.down("768")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("768")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
      width: "100%",
    },
  },

  shareDocumentDialogEmailSubjectSection: {
    display: "flex",
    flexDirection: "row",
    width: "640px",
    maxWidth: "640px",
    minHeight: "44px",
    maxHeight: "fit-content",
    alignItems: "start",
    justifyContent: "space-between",
    "& > *:nth-child(1)": {
      width: "160px",
      marginRight: "32px",
      alignContent: "center",
      justifyContent: "center",
      [theme.breakpoints.down("768")]: {
        width: "100%",
        alignItems: "center",
      },
    },
    "& > *:nth-child(2)": {
      backgroundColor: theme.palette.background.primary,
      width: "448px",
      "&:focus": {
        boxShadow: theme.shadows[12],
      },
      [theme.breakpoints.down("768")]: {
        width: "100%",
        minWidth: "100%",
      },
    },
    [theme.breakpoints.down("768")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
      width: "100%",
    },
  },

  shareDocumentDialogSendToOptionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },

  textAreaInput: {
    width: "100%",
    minHeight: "130px",
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: "8px",
    padding: "10px 14px",
    fontSize: "16px",
    lineHeight: "24px",
    resize: "none",
    color: theme.palette.text.primary,
    fontWeight: 500,
    "&::placeholder": {
      color: theme.palette.text.placeholder,
      fontWeight: 400,
    },
  },
  emailSubject: {
    width: "100%",
    minWidth: "448px",
    border: `1px solid ${theme.palette.border.primary}`,
    color: theme.palette.text.primary,
    borderRadius: "8px",
    padding: "10px 14px",
    fontSize: "16px",
    lineHeight: "24px",
    resize: "none",
    textOverflow: "ellipsis",
    fontWeight: 500,
    "&::placeholder": {
      color: theme.palette.text.placeholder,
      fontWeight: 400,
    },
  },

  checkBoxComponent: {
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: 10,
  },

  shareViaNameOption: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: theme.palette.text.secondary,
  },

  chipsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: "calc(100% - 10%)",
    gap: "6px",
    padding: "0px 8px",
    "& > *": {
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "6px",
      padding: "2px 8px",
    },
  },

  divider: {
    backgroundColor: theme.palette.border.secondary,
    margin: "20px 0px",
  },

  textPlaceHolderColorForSendViaWhatsApp: {
    "& > *:nth-child(2)": {
      color: theme.palette.text.placeholder,
    },
  },

  shareViaIconOptionStyles: {
    color: theme.palette.foreground.secondary,
  },
}));
