import Joi from 'joi';
import { phoneExpression } from '../../Utils';

// Schema for individual contact

export const createIndividualSchema = (t, ns) =>
  Joi.object({
    firstName: Joi.string()
      .required()
      .messages({
        'any.required': t('is-required', { field: t('first_name'), ns }),
        'string.empty': t('is-required', { field: t('first_name'), ns }),
      }),
    lastName: Joi.string()
      .required()
      .messages({
        'any.required': t('is-required', { field: t('last_name'), ns }),
        'string.empty': t('is-required', { field: t('last_name'), ns }),
      }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'string.empty': t('invalid_email', { field: t('email') }),
        'any.required': t('is-required', { field: t('email'), ns }),
        'string.email': t('invalid_email', { field: t('email') }),
      }),
    phone: Joi.string()
      .required()
      .regex(phoneExpression)
      .messages({
        'any.required': t('is-required', { field: t('phone'), ns }),
        'string.empty': t('is-required', { field: t('phone'), ns }),
        'string.pattern.base': t('invalid-mobile-number', { ns: 'HeaderView' }),
      }),
  });
// Schema for corporate contact
export const createCorporateSchema = (t, ns) =>
  Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'any.required': t('is-required', { field: t('email'), ns }),
        'string.email': t('invalid-email', { field: t('email'), ns }),
        'string.empty': t('invalid-email', { field: t('email'), ns }),
      }),
    companyName: Joi.string()
      .required()
      .messages({
        'any.required': t('is-required', { field: t('company_name'), ns }),
        'string.empty': t('is-required', { field: t('company_name'), ns }),
      }),
    landline: Joi.string()
      .required()
      .regex(phoneExpression)
      .messages({
        'any.required': t('is-required', { field: t('landline'), ns }),
        'string.empty': t('is-required', { field: t('landline'), ns }),
        'string.pattern.base': t('invalid-mobile-number', { ns: 'HeaderView' }),
      }),
  });
