import React, { useState, useRef, useCallback } from "react";
import { InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Inputs, AutocompleteComponent } from "../../../../../../Components";
import { CommissionTypes } from "../../../../../../Enums";
import { GetDeveloperPropartiesByContactIdAPI } from "../../../../../../Services";

export const CommissionDetailsViewComponent = ({
  translationPath,
  parentTranslationPath,
  contactReletedWithDeveloperId,
  className,
  editForm,
  selected,
  state,
  onChangeHandler,
  schema,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const searchTimer = useRef(null);
  const [isReloadData, setIsReloadData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isLoading, setIsLoading] = useState({
    properties: false,
  });

  const [items, setItems] = useState({
    properties: [],
  });

  const GetDeveloperPropartiesByContactId = useCallback(async (searchValue) => {
    let list = [];
    setIsLoading((loading) => ({ ...loading, properties: true }));
    const rs =
      (contactReletedWithDeveloperId &&
        (await GetDeveloperPropartiesByContactIdAPI(
          contactReletedWithDeveloperId
        ))) ||
      null;
    if (!rs) {
      setIsLoading((item) => ({ ...item, properties: false }));
      setItems((item) => ({ ...item, properties: list || [] }));
      return;
    } else {
      rs.map((valueLoop) => {
        list.push({
          propertyId: valueLoop?.propertyId,
          propertyName: valueLoop?.propertyName,
        });
      });
      setItems((item) => ({ ...item, properties: list || [] }));
      setIsLoading((item) => ({ ...item, properties: false }));
    }
  });

  return (
    <>
      <div className={`${className || "px-2 w-50 "}`}>
        <AutocompleteComponent
          key={`commissionType`}
          idRef={`commissionTypeRef`}
          multiple={false}
          selectedValues={selected.commissionType}
          inputPlaceholder={t("select-commission-type")}
          data={Object.values(CommissionTypes)}
          inputClasses="inputs theme-form-builder"
          displayLabel={(option) =>
            (option && t(`${translationPath}${option.name}`)) || ""
          }
          withoutSearchButton
          labelValue={"commission-type"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onChange={(e, newValue) => {
            onChangeHandler(
              "commissionType",
              newValue === null ? 1 : newValue.key,
              "commissionType",
              newValue
            );
            if (newValue === null || newValue?.key === 1)
              onChangeHandler(
                "commissionProjects",
                [],
                "commissionProjects",
                []
              );
          }}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <AutocompleteComponent
          key={`projects-names`}
          idRef={`projectsNamesRef`}
          multiple
          isDisabled={
            !state.commissionType ||
            state?.commissionType === CommissionTypes.forAllProjects.key
          }
          inputPlaceholder={t("select-properties")}
          data={items?.properties || []}
          selectedValues={selected.commissionProjects}
          inputClasses="inputs theme-form-builder"
          chipsLabel={(option) => (option && option.propertyName) || ""}
          displayLabel={(option) => (option && option.propertyName) || ""}
          onChange={(e, newValues) => {
            if (state?.commissionType === CommissionTypes.forAllProjects.key)
              return;

            const commissionProjectsIds =
              (newValues &&
                newValues.length &&
                newValues.map((property) => ({
                  propertyId: property?.propertyId,
                }))) ||
              [];
            onChangeHandler(
              "commissionProjects",
              commissionProjectsIds,
              "commissionProjects",
              newValues
            );
          }}
          isLoading={isLoading.properties}
          withLoader
          withoutSearchButton
          labelValue={"projects-names"}
          filterOptions={(options) => {
            const isFind = (id) =>
              selected.commissionProjects?.findIndex(
                (s) => s.propertyId === id
              ) === -1 || false;
            return options.filter((w) => isFind(w.propertyId));
          }}
          getOptionSelected={(option) =>
            (state &&
              state.commissionProjects &&
              state.commissionProjects.findIndex(
                (item) => item?.propertyId === option?.propertyId
              ) !== -1) ||
            false
          }
          filterSelectedOptions
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onOpen={() => {
            if (items && items.properties?.length === 0)
              GetDeveloperPropartiesByContactId();
          }}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          idRef={"valueFrom"}
          labelValue={"value-from"}
          inputPlaceholder={t("enter-value-from")}
          value={state.valueFrom || ""}
          withNumberFormat
          onInputBlur={(e) => {}}
          onInputChanged={(e) => {
            let { value } = e.target;
            onChangeHandler("valueFrom", value || null);
          }}
          onKeyUp={(e) => {
            let value = e && e.target && e.target.value ? e.target.value : 0;
            if (Number.isNaN(value)) value = 0;
            const fixed = value && value.replace(/,/g, "");
            onChangeHandler("valueFrom", (fixed && parseFloat(fixed)) || null);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          idRef={"valueTo"}
          labelValue={"value-to"}
          inputPlaceholder={t("enter-value-to")}
          value={state.valueTo || ""}
          withNumberFormat
          onInputBlur={(e) => {}}
          onInputChanged={(e) => {
            let { value } = e.target;
            onChangeHandler("valueTo", value || null);
          }}
          onKeyUp={(e) => {
            let value = e && e.target && e.target.value ? e.target.value : 0;
            if (Number.isNaN(value)) value = 0;
            const fixed = value && value.replace(/,/g, "");
            onChangeHandler("valueTo", (fixed && parseFloat(fixed)) || null);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          idRef={`commissionRef`}
          labelValue={"commission"}
          endAdornment={
            <InputAdornment position="end" className="px-2">
              %
            </InputAdornment>
          }
          inputPlaceholder={t("enter-commission")}
          type="number"
          min={0}
          max={100}
          withNumberFormat
          isAttachedInput
          value={state.commission || ""}
          onInputChanged={(e) => {
            let { value } = e.target;
            if (value > 100) value = 100;
            onChangeHandler("commission", value || null);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          idRef={`paymentTerms${1}`}
          labelValue={"payment-terms"}
          multiline
          rows={3}
          value={state.paymentTerms || ""}
          onInputChanged={(e) => {
            const { value } = e.target;
            onChangeHandler("paymentTerms", (value !== "" && value) || null);
          }}
          inputPlaceholder={"enter-payment-terms"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      {/* <div className={`${className || "px-2 w-50 "}`}>
              <Inputs
                isAttachedInput
                idRef={`remarks${1}`}
                labelValue={"remarks"}
                multiline
                rows={3}
                value={state.remarks || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("remarks", (value !=='' && value) || null );
                }}
                inputPlaceholder={"enter-remarks"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div> */}
    </>
  );
};
