import React, { useEffect, useState } from "react";
import clsx from "clsx";
import ReactDOM from "react-dom";
import { Paper, Box } from "@material-ui/core";
import {
  CustomIconButton,
  CustomInput,
  CustomButton,
  CustomCheckbox,
} from "../../../../../../../Components";
import { useSelectedTheme } from "../../../../../../../Hooks";

// Icons
import { CloseXIcon } from "../../../../../../../assets/icons";

// Styles
import useStyles from "./styles";

const ShowHideTableColumns = ({
  setUpdateSelectedColumnItems,
  open,
  onClose,
  allFormFields,
  filterModalData,
  setFilterModalData,
}) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const defaultCheckedItems = Array.from({ length: 11 }, (_, i) => i + 1);

  useEffect(() => {
    if (filterModalData?.selectedId) {
      const data = filterModalData?.data?.find(
        (item) => item?.id === filterModalData?.selectedId
      ).fieldsDetails;

      const filteredFormFields = allFormFields?.filter((item) =>
        data?.some((dataItem) => dataItem?.id === item?.id)
      );

      setUpdateSelectedColumnItems(filteredFormFields);

      setCheckedItems(data?.map((element) => element?.id));
    }
  }, [filterModalData?.selectedId, allFormFields]);

  const [checkedItems, setCheckedItems] = useState(defaultCheckedItems);
  const [searchValue, setSearchValue] = useState("");

  // Filtered items based on search input
  const filteredItems = allFormFields?.filter((item) =>
    item?.headerName?.toLowerCase()?.includes(searchValue?.toLowerCase())
  );

  const handleCheckboxChange = (itemId) => {
    if (filterModalData?.selectedId) {
      setFilterModalData((prevData) => ({
        ...prevData,
        selectedId: 0,
      }));
    }

    setCheckedItems((prevChecked) =>
      prevChecked.includes(itemId)
        ? prevChecked.filter((formFieldId) => formFieldId !== itemId)
        : [...prevChecked, itemId]
    );
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClear = () => {
    setFilterModalData((prevData) => ({
      ...prevData,
      selectedId: 0,
    }));

    setCheckedItems(defaultCheckedItems);
    setSearchValue("");
    const selectedItems = allFormFields?.filter((item) =>
      defaultCheckedItems?.includes(item?.id || item?.formFieldId)
    );
    setUpdateSelectedColumnItems(selectedItems);
  };

  const handleSelectAll = () => {
    if (checkedItems?.length === allFormFields?.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(allFormFields?.map((item) => item?.formFieldId));
    }
  };

  const handleApply = () => {
    const selectedItems = allFormFields?.filter((item) =>
      checkedItems?.includes(item?.id || item?.formFieldId)
    );
    setUpdateSelectedColumnItems(selectedItems);
  };

  useEffect(() => {
    if (checkedItems?.length > 0) {
      setFilterModalData((prevData) => ({
        ...prevData,
        selectedColumns:
          allFormFields?.filter((item) =>
            checkedItems?.includes(item?.id || item?.formFieldId)
          ) || [],
      }));
    }
  }, [checkedItems?.length]);

  return ReactDOM.createPortal(
    <Paper className={clsx(styles.contactSideMenu, { [styles.hidden]: !open })}>
      <Box className={styles.headingContainer}>
        <Box className={styles.titleContainer}>
          <h3 className={styles.title}>Role Pin</h3>
          <CustomIconButton
            variant="text"
            size="none"
            boxShadow="none"
            color="tertiaryColor"
            hideHoverBg
          >
            <CloseXIcon
              onClick={onClose}
              width="20"
              height="20"
              fill={palette.foreground.quinary}
            />
          </CustomIconButton>
        </Box>
      </Box>

      <Box className={styles.dropdownWrapper}>
        <CustomInput
          type="text"
          placeholder="Search"
          onChange={handleSearchChange}
          debounceTime={1000}
          value={searchValue}
          style={{ width: "100%" }}
        />
      </Box>

      <Box className={styles.itemsContainer}>
        <Box className={styles.filteredItemWrapper}>
          <CustomCheckbox
            isAllIconSelected={true}
            checked={checkedItems?.length === allFormFields?.length}
            onChange={handleSelectAll}
          />
          <span>Select All</span>
        </Box>        

        {filteredItems.map((item, idx) => {
          return (
            <Box key={idx} className={styles.filteredItemWrapper}>
              <CustomCheckbox
                checked={checkedItems.includes(item?.formFieldId || item?.id)}
                onChange={() =>
                  handleCheckboxChange(item?.formFieldId || item?.id)
                }
              />
              <span>{item?.headerName || item?.formFieldTitle}</span>
            </Box>
          )
        })}
      </Box>

      <Box className={styles.actions}>
        <Box>
          <CustomButton
            size="md"
            variant="outlined"
            color="secondary"
            onClick={handleClear}
          >
            Clear
          </CustomButton>
          <CustomButton
            onClick={() => {
              handleApply();
              onClose();
            }}
            boxShadow="xs"
            size="md"
            variant="contained"
          >
            Apply
          </CustomButton>
        </Box>
      </Box>
    </Paper>,
    document.getElementById("portal-root")
  );
};

export default ShowHideTableColumns;
