import React, { useCallback, useEffect, useReducer, useState } from "react";
import { SelectComponet, RadiosGroupComponent, AutocompleteComponent } from "../../../../../Components";
import { GetAllActivityTypes } from "../../../../../Services";

export const CreateLeadForm = ({ selectedData }) => {

  const parentTranslationPath = 'WorkFlow';
  const translationPath = '';

  const [selectedOrderBy, setSelectedOrderBy] = useState({ Value: null });
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, Value: value }))
  };

  const getFieldName = (formName) => { return formName?.replace('Condition', '') || '' };

  const fieldName = selectedData?.formName ? getFieldName(selectedData.formName) : null;

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    activityTypes: [],
  });

  const [loadings, setLoadings] = useReducer(reducer, {
    activityTypes: false,
  });

  const getAllActivityTypes = useCallback(async () => {
    setLoadings({ id: 'activityTypes', value: true });
    const res = await GetAllActivityTypes();
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: 'activityTypes',
        value: res || [],
      });
    } else {
      setData({
        id: 'activityTypes',
        value: [],
      });
    }
    setLoadings({ id: 'activityTypes', value: false });
  }, []);

  const renderSelectComponent = () => (
    <div className="mb-3 w-100">
      <SelectComponet
        idRef="Seeker"
        data={[
          { id: 1, LeadType: "Owner" },
          { id: 2, LeadType: "Seeker" },
        ]}
        value={selectedOrderBy.Value}
        onSelectChanged={orderByChanged}
        wrapperClasses="mb-3 w-100"
        isRequired
        valueInput="id"
        textInput="LeadType"
        emptyItem={{
          value: null,
          text: "Select",
          isDisabled: false,
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        translationPathForData={translationPath}
      />
    </div>
  );

  const renderPSIOpportunityComponent = () => (
    <div className="mb-3 w-100">
      <SelectComponet
        idRef="Seeker"
        data={[
          { id: 1, LeadType: "Owner" },
          { id: 2, LeadType: "Seeker" },
        ]}
        value={selectedOrderBy.Value}
        onSelectChanged={orderByChanged}
        wrapperClasses="mb-3 w-100"
        isRequired
        valueInput="id"
        textInput="LeadType"
        emptyItem={{
          value: null,
          text: "Select",
          isDisabled: false,
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        translationPathForData={translationPath}
      />
    </div>
  );



  const renderRadiosGroupComponent = () => (
    <div className="form-item">
      <RadiosGroupComponent
        idRef="paymentToExternalAgencyRef"
        labelValue="psi_opportunity"
        data={[
          { key: true, value: 'yes' },
          { key: false, value: 'no' },
        ]}
        value={selectedOrderBy.Value}
        parentTranslationPath={parentTranslationPath}
        translationPathForData={translationPath}
        translationPath={translationPath}
        labelInput="value"
        valueInput="key"
        onSelectedRadioChanged={(e, newValue) =>
          orderByChanged(newValue === 'true')
        }
      />
    </div>
  );


  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    activityType: null,
  });

  const [state, setState] = useReducer(reducer, {
    activityTypeId: null,
  });



  const renderActivityComponent = () => (
    <AutocompleteComponent
      idRef='activityTypeIdRef'
      labelValue='activity-type'
      labelClasses='Requierd-Color'
      selectedValues={selected.activityType}
      multiple={false}
      data={data.activityTypes}
      displayLabel={(option) => option.activityTypeName || ''}
      groupBy={(option) => option.categoryName || ''}
      // getOptionSelected={(option) => option.activityTypeId === state.activityTypeId}
      withoutSearchButton
      // helperText={getErrorByName(schema, 'activityTypeId').message}
      // error={getErrorByName(schema, 'activityTypeId').error}
      isLoading={loadings.activityTypes}
      isWithError
      isSubmitted={isSubmitted}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onChange={(event, newValue) => {
        setState({
          id: 'subject',
          value: (newValue && newValue.activityTypeName) || null,
        });
        setState({
          id: 'assignAgentId',
          value: (newValue && newValue.id) || null,
        });
        setSelected({ id: 'activityType', value: newValue });
        setState({
          id: 'activityTypeId',
          value: (newValue && newValue.activityTypeId) || null,
        });
        const assignedToActivityTypeRoles =
          (newValue && newValue.assignedToActivityTypeRoles) || [];
        const rolesIds =
          assignedToActivityTypeRoles &&
          assignedToActivityTypeRoles.map((item) => item.rolesId || []);
        setSelected({ id: 'activityAssign', value: null });
      }}
    />
  );

  const renderComponentByCondition = () => {
    switch (fieldName) {
      case 'LeadsType':
        return renderSelectComponent();
      case 'LeadType':
        return renderSelectComponent();
      case 'SourceId':
        return renderRadiosGroupComponent();
      case 'psi_opportunity':
        return renderSelectComponent();
      case 'LeadActivityTypeForm':
        return renderActivityComponent();
      case 'MediaDetailsWhitelist':
        return renderActivityComponent();
      case 'PSIOpportunity':
        return renderPSIOpportunityComponent();
      default:
        return null;
    }
  };



  useEffect(() => {
    getAllActivityTypes();
  }, []);

  return (
    <form className="w-100">
      <div className="px-2 w-100">
        {renderComponentByCondition()}
      </div>
    </form>
  );
};
