import React from "react";

function MKVIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <rect x="1" y="18" width="30" height="16" rx="2" fill="#155EEF" />
      <path
        d="M4.86941 22.7273H6.76571L8.76855 27.6136H8.85378L10.8566 22.7273H12.7529V30H11.2615V25.2663H11.2011L9.31898 29.9645H8.30336L6.42125 25.2486H6.36088V30H4.86941V22.7273ZM14.0198 30V22.7273H15.5574V25.9339H15.6533L18.2705 22.7273H20.1135L17.4147 25.9837L20.1455 30H18.306L16.3138 27.0099L15.5574 27.9332V30H14.0198ZM22.2282 22.7273L23.9861 28.2528H24.0535L25.8149 22.7273H27.5194L25.0123 30H23.0308L20.5202 22.7273H22.2282Z"
        fill="white"
      />
    </svg>
  );
}

export default MKVIcon;
