import { GetAllPropertyUnitTypeAPI, lookupItemsGetId } from "../Services";
import { UnitTypes , UnitsOperationTypeEnum } from "../Enums";


export async function OnPropertyChangeEffectOnUnitRule(
  v,
  itemList,
  onValueChanged,
  setNewValue,
  formType,
  item , 
  values
) {
   const pathName = window.location.pathname.split('/home/')[1];
   const shortageInIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === "shortage_in") ;
   const operationTypeIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === "operation_type") ;

  let operationType  = null ; 
  if(operationTypeIndex !== -1 )
    operationType  =   values && values.operation_type && values.operation_type.lookupItemName === 'Sale' ? UnitsOperationTypeEnum.sale.key  
  : ( values && values.operation_type && values.operation_type.lookupItemName === 'Rent'   &&  UnitsOperationTypeEnum.rent.key ) || null ; 


 else  if(shortageInIndex !== -1 )
   operationType  =   values && values.shortage_in && values.shortage_in === 'Sale' ? UnitsOperationTypeEnum.sale.key  : ( values && values.shortage_in && values.shortage_in === 'Leasing'  &&  UnitsOperationTypeEnum.rent.key ) || null ; 
 else 
  operationType = (pathName.includes('sale') || pathName.includes('Sale')) ? UnitsOperationTypeEnum.sale.key :  (pathName.includes('lease') || pathName.includes('Lease')) ? (UnitsOperationTypeEnum.rent.key) : null  ; 

  const unitTypeIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "unit_type"
  );
  const bedroomIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "bedroom"
  );
  // const budgetFromIndex = itemList.findIndex(
  //   (f) => f.field.id.toLowerCase() === "client_budget_from"
  // );
  // const budgetToIndex = itemList.findIndex(
  //   (f) => f.field.id.toLowerCase() === "client_budget_to"
  // );

  if (formType === 2) {
    if (item.data.DtoName === "property_name_unit_form")
      onValueChanged(null, 0, "unit_type");
    else if (item.data.DtoName === "property_inquiries" && v) {
      onValueChanged([], 0, "unit_type");
      onValueChanged([], 0, "bedroom");
    }
  } else if (formType === 1) {
    if (item.data.DtoName === "property_name_unit_form")
      onValueChanged("unit_type", {});
    else if (item.data.DtoName === "property_inquiries" && v) {
      onValueChanged("unit_type", []);
      onValueChanged("bedroom", []);
    }
  }
  if (v && v.id) {
    if (unitTypeIndex !== -1) {
      itemList[unitTypeIndex].data.enum = [];
      if (bedroomIndex !== -1) itemList[bedroomIndex].data.enum = [];

      const res = await GetAllPropertyUnitTypeAPI(v.id , operationType);
      if ((res && res.data && res.data.ErrorId) || !res) return;
      {
        const propertyUnitTypes =
          (res &&
            Array.isArray(res) &&
            res.length &&
            res[0] &&
            res[0].propertyUnitType) ||
          [];
        const availableBedrooms =
          (res &&
            Array.isArray(res) &&
            res.length &&
            res[0] &&
            res[0].availableBedrooms) ||
          [];
        setTimeout(() => {
          if (propertyUnitTypes.length > 0)
            propertyUnitTypes.map((ut) => {
              itemList[unitTypeIndex].data.enum.push({
                lookupItemId: ut?.lookupItemId,
                lookupItemName: ut?.lookupItemName,
              });
            });
          else if (propertyUnitTypes.length === 0)
            itemList[unitTypeIndex].data.enum = [];

          if (availableBedrooms.length > 0 && bedroomIndex !== -1) {
            availableBedrooms.map((b) => {
              itemList[bedroomIndex].data.enum.push(b);
            });
          } else if (availableBedrooms.length === 0 && bedroomIndex !== -1)
            itemList[bedroomIndex].data.enum = [];
        }, 500);
        if (formType === 2) itemList[unitTypeIndex].data.isReadonly = false;
      }
      setNewValue(Math.random());
    }
  } else if (!v) {
    if (item.data.DtoName === "property_inquiries") {
      itemList[unitTypeIndex].data.enum = [];

      if (bedroomIndex !== -1) {
        onValueChanged([], 0, "bedroom");
        itemList[bedroomIndex].data.enum = [
          "Any",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
        ];
      }
      const res = await lookupItemsGetId({
        lookupTypeId: UnitTypes.lookupTypeId,
      });
      if ((res && res.data && res.data.ErrorId) || !res) return;
      if (unitTypeIndex !== -1) {
        setTimeout(() => {
          res &&
            res.map((ut) => {
              itemList[unitTypeIndex].data.enum.push({
                lookupItemId: ut?.lookupItemId,
                lookupItemName: ut?.lookupItemName,
              });
            });
        }, 500);
      }
      setNewValue(Math.random());
    } else if (
      formType === 2 &&
      item.data.DtoName === "property_name_unit_form"
    )
      itemList[unitTypeIndex].data.isReadonly = true;
    setNewValue(Math.random());
  }
}

export async function UnitTypeEffectOnPropertyDefaultRule(
  item,
  setNewValue,
  allItemValues,
  itemList , 
  values 
) {
  if (item.field.id !== "unit_type") return;

  const unitOperationTypeIndex = itemList.findIndex(
    (f) => f.field.id.toLowerCase() === "operation_type" && item.data.specialKey && item.data.specialKey === "UnitOperationType" ) ;
   

    const shortageInIndex = itemList.findIndex(
      (f) => f.field.id.toLowerCase() === "shortage_in") ;
  

  const pathName = window.location.pathname.split('/home/')[1];
  let operationType  = null ; 
  if(unitOperationTypeIndex  !== -1)
    operationType = ((values && values.operation_type === 'Sale' ) || (values && values.operation_type === 'SaleAndRent'  && pathName.includes('sale') )? UnitsOperationTypeEnum.sale.key   :((values && values.operation_type === 'Rent' ) || (values && values.operation_type === 'SaleAndRent'  && pathName.includes('lease'))));
  
 else if(shortageInIndex !== -1 )
   operationType  =   (values && values.shortage_in && values.shortage_in === 'Sale') ? UnitsOperationTypeEnum.sale.key  : ( values && values.shortage_in && values.shortage_in === 'Leasing'  &&  UnitsOperationTypeEnum.rent.key ) || null ; 
 else 
  operationType =  pathName.includes('sale') || pathName.includes('Sale') ? UnitsOperationTypeEnum.sale.key :  (pathName.includes('lease') || pathName.includes('Lease') ? (UnitsOperationTypeEnum.rent.key) : null)  ; 


  const bedroomIndex =
    itemList &&
    itemList.findIndex((f) => f.field.id.toLowerCase() === "bedroom");

  if (allItemValues && allItemValues.property_name) {
    item.data.enum = [];

    const res = await GetAllPropertyUnitTypeAPI(allItemValues.property_name.id , operationType);

    setTimeout(() => {
      res &&
        Array.isArray(res) &&
        res[0] &&
        res[0].propertyUnitType &&
        res[0].propertyUnitType.map((value) => {
          item.data.enum.push({
            lookupItemId: value?.lookupItemId,
            lookupItemName: value?.lookupItemName,
          });
        });

      if (res && Array.isArray(res).length === 0) item.data.enum = [];
    });
    setNewValue(Math.random());
  } else if (
    item &&
    item.data.DtoName === "inquires_unit_type" &&
    allItemValues &&
    allItemValues.property
  ) {
    item.data.enum = [];
    if (bedroomIndex !== -1) itemList[bedroomIndex].data.enum = [];

    const res = await GetAllPropertyUnitTypeAPI(allItemValues.property.id , operationType );

    const availableBedrooms =
      (res &&
        Array.isArray(res) &&
        res.length &&
        res[0] &&
        res[0].availableBedrooms) ||
      [];
    const propertyUnitTypes =
      (res &&
        Array.isArray(res) &&
        res.length &&
        res[0] &&
        res[0].propertyUnitType) ||
      [];

    setTimeout(() => {
      propertyUnitTypes.map((value) => {
        item.data.enum.push({
          lookupItemId: value?.lookupItemId,
          lookupItemName: value?.lookupItemName,
        });
      });

      if (bedroomIndex !== -1 && availableBedrooms.length > 0) {
        availableBedrooms.map((b) => {
          itemList[bedroomIndex].data.enum.push(b);
        });
      } else if (bedroomIndex !== -1 && availableBedrooms.length === 0)
        itemList[bedroomIndex].data.enum = [];

      if (res && Array.isArray(res).length === 0) item.data.enum = [];
    });
    setNewValue(Math.random());
  } else if (
    item &&
    item.data.DtoName === "inquires_unit_type" &&
    !allItemValues.property
  ) {
    item.data.enum = [];
    const res = await lookupItemsGetId({
      lookupTypeId: UnitTypes.lookupTypeId,
    });
    if ((res && res.data && res.data.ErrorId) || !res) return;
    res &&
      res.map((ut) => {
        item.data.enum.push({
          lookupItemId: ut?.lookupItemId,
          lookupItemName: ut?.lookupItemName,
        });
      });
    if (bedroomIndex !== -1)
      itemList[bedroomIndex].data.enum = [
        "Any",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
      ];
    setNewValue(Math.random());
  }
}

export async function PropertyAddressDefaultRule(
  setNewValue,
  allItemValues,
  itemList
) {
  const subCommunityIndex =
    itemList &&
    itemList.findIndex((f) => f.field.id.toLowerCase() === "sub_community");

  const communityIndex =
    itemList &&
    itemList.findIndex((f) => f.field.id.toLowerCase() === "community");

  if (communityIndex !== -1)
    itemList[communityIndex].data.isReadonly = allItemValues.property
      ? true
      : false;

  if (subCommunityIndex !== -1)
    itemList[subCommunityIndex].data.isReadonly = allItemValues.property
      ? true
      : false;
  setNewValue(Math.random());
}
