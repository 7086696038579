import React, { useMemo, useState } from 'react';
import { DialogComponent, Spinner } from '../../../../../../Components';
import { GetMatchingUnitWithLead } from '../../../../../../Services';
import { showError, showWarn } from '../../../../../../Helper';
import { UnitMapper } from '../../../../UnitsLeaseView/UnitLeaseMapper';
import SearchView from './components/SearchView';
import MatchedUnitView from './components/MatchedUnitView';
import { StaticLookupsIds } from '../../../../../../assets/json/StaticLookupsIds';
import { useTranslation } from 'react-i18next';

const views = {
  search: 'search',
  viewUnits: 'viewUnits',
};

const parentTranslationPath = 'LeadsView';
function SearchAndMatchDialog({
  open,
  onClose,
  activeData,
  translationPath,
  operationType,
  leadId,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [data, setData] = useState([]);
  const [view, setView] = useState(views.search);
  const [isLoading, setIsLoading] = useState(false);
  const [unitTypeId, setUnitTypeId] = useState(null);
  const [location, setLocation] = useState({
    country: activeData.country,
    city: activeData.city,
    district: activeData.district,
    community: activeData.community,
    subCommunity: activeData.subCommunity,
  });
  const leadEmail = useMemo(() => {
    return activeData?.contact_name?.email_address;
  }, [activeData]);

  const [params, setParams] = useState({
    priceFrom: undefined,
    priceTo: undefined,
    sizeFrom: undefined,
    sizeTo: undefined,
    bedroomsNumber: undefined,
    bathroomsNumber: undefined,
  });
  const setCountry = (country) => {
    setLocation({
      country: country,
      city: null,
      district: null,
      community: null,
      subCommunity: {},
    });
  };

  const setCity = (city) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      city: city,
      district: null,
      community: null,
      subCommunity: null,
    }));
  };

  const setDistrict = (district) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      district: district,
      community: null,
      subCommunity: null,
    }));
  };

  const setCommunity = (community) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      community: community,
      subCommunity: null,
    }));
  };

  const setSubCommunity = (subCommunity) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      subCommunity: subCommunity,
    }));
  };
  const setPriceFrom = (priceFrom) => {
    setParams((prevParams) => ({
      ...prevParams,
      priceFrom: priceFrom,
    }));
  };

  const setPriceTo = (priceTo) => {
    setParams((prevParams) => ({
      ...prevParams,
      priceTo: priceTo,
    }));
  };

  const setSizeFrom = (sizeFrom) => {
    setParams((prevParams) => ({
      ...prevParams,
      sizeFrom: sizeFrom,
    }));
  };

  const setSizeTo = (sizeTo) => {
    setParams((prevParams) => ({
      ...prevParams,
      sizeTo: sizeTo,
    }));
  };

  const setBedroomsNumber = (bedroomsNumber) => {
    setParams((prevParams) => ({
      ...prevParams,
      bedroomsNumber: bedroomsNumber,
    }));
  };

  const setBathroomsNumber = (bathroomsNumber) => {
    const value = Number(bathroomsNumber);
    setParams((prevParams) => ({
      ...prevParams,
      bathroomsNumber: value,
    }));
  };

  const locationParams = useMemo(
    () => ({
      cityId: location.city?.lookupItemId,
      districtId: location.district?.lookupItemId,
      subCommunityId: location.subCommunity?.lookupItemId,
      countryId: location.country?.lookupItemId,
      communityId: location.community?.lookupItemId,
    }),
    [location]
  );
  const onSearchClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const overallParams = {
      ...params,
      ...locationParams,
      operationType,
    };

    if (!Object.values(overallParams).some((value) => !!value)) {
      return showWarn(t('fill-some-fields'));
    }
    try {
      const res = await GetMatchingUnitWithLead({
        ...params,
        ...locationParams,
        unitTypeId,
        operationType,
        pageIndex: 1,
        pageSize: 10,
      });
      setView(views.viewUnits);
      const result = res.map((item) =>
        UnitMapper({ ...item, unitImages: item.unitImage }, item.unit?.unit, operationType)
      );
      setData(result);
    } catch (error) {
      showError(t('something-went-wrong'));
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <DialogComponent
      isOpen={open}
      titleText={'search-and-match'}
      onCancelClicked={view === views.search ? onClose : undefined}
      onCloseClicked={onClose}
      dialogContent={
        <div className='p-relative'>
          <Spinner isAbsolute isActive={isLoading} />
          {view === views.search ? (
            <SearchView
              location={location}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              setCity={setCity}
              setDistrict={setDistrict}
              setCountry={setCountry}
              setBathroomsNumber={setBathroomsNumber}
              setBedroomsNumber={setBedroomsNumber}
              setCommunity={setCommunity}
              setPriceFrom={setPriceFrom}
              setPriceTo={setPriceTo}
              setSizeFrom={setSizeFrom}
              setSizeTo={setSizeTo}
              setSubCommunity={setSubCommunity}
              setUnitTypeId={(id) => setUnitTypeId(id)}
              unitTypeId={unitTypeId}
              params={params}
            />
          ) : (
            <MatchedUnitView
              data={data}
              setView={setView}
              views={views}
              leadEmail={leadEmail}
              leadId={leadId ?? activeData.id}
              isSale={StaticLookupsIds.Sales === operationType}
              onClose={onClose}
            />
          )}
        </div>
      }
      parentTranslationPath={'LeadsView'}
      translationPath={'utilities.cardDetailsComponent.'}
      onSaveClicked={view === views.search ? onSearchClick : undefined}
      saveText={'search'}
      saveIsDisabled={isLoading}
    />
  );
}

export default SearchAndMatchDialog;
