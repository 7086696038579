import React, { useState, useEffect, useReducer } from "react";
import { ButtonBase } from "@material-ui/core";
import Joi, { valid } from "joi";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../../Components";
import { CommunicationsIds } from "../../../../../Enums";
import { GetParams, showError, showSuccess } from "../../../../../Helper";

import {
  GetDeveloperEmployeessAPI,
  CreateDeveloperEmployeeAPI,
} from "../../../../../Services";

import {
  DeveloperEmployeesTable,
  DeveloperEmployeesManagementComponent,
} from "./DeveloperEmployeesComponents";

export const DeveloperEmployees = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [isReloadData, setIsReloadData] = useState(null);
  const [isCanSave, setIsCanSave] = useState({vaildEmail : false , validMobile :false  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isCheckIsValidValues, setIsCheckIsValidValues] = useState(false);
  const [communicationsIdsValues, setCommunicationsIdsValues] = useState({
    email: CommunicationsIds.email.value,
    mobile: CommunicationsIds.mobile.value,
  });

  const [isLoading, setIsLoading] = useState({
    allDeveloperEmployees: false,
    addDeveloperEmployee: false,
  });

  const [items, setItems] = useState({
    allDeveloperEmployees: {
      result: [],
      totalCount: 0,
    },
  });

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    employeeName: null,
    jobTitle: null,
    linkedInProfile: null,
    employeePhoto: null,
    files: null,
  });

  const [defaultSelected, setDefaultSelected] = useState({
    employeePhoto: null,
    emailAddresses: [],
    mobileNumbers: [],
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const schema = Joi.object({
    employeeName: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}employee-name-is-required`),
        "string.empty": t(`${translationPath}employee-name-is-required`),
        "string.required": t(`${translationPath}employee-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const GetDeveloperEmployeesseveloperContactId = async (search) => {
    setIsLoading((item) => ({ ...item, allDeveloperEmployees: true }));
    const rs = await GetDeveloperEmployeessAPI(+GetParams("id"), {
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
      name: search,
    });
    if (!rs) {
      setItems((item) => ({
        ...item,
        allDeveloperEmployees: { result: [], totalCount: 0 },
      }));
      return;
    } else {
      setItems((item) => ({
        ...item,
        allDeveloperEmployees: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    }
    setIsLoading((item) => ({ ...item, allDeveloperEmployees: false }));
  };

  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    setSelected({ id: "edit", value: defaultSelected });
    GetDeveloperEmployeesseveloperContactId();
  };

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };

  const CreateDeveloperEmployeeHandler = async () => {
    setIsLoading((item) => ({ ...item, addDeveloperEmployee: true }));
    const body = {
      ...state,
      developerEmployeeCommunications: [
        ...selected.emailAddresses?.filter((c)=> c.communicationValue !== ''),
        ...selected.mobileNumbers,
      ],
    };
    if (state.files) delete body.files;
    const res = await CreateDeveloperEmployeeAPI(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}developer-employee-created-successfully`);
      setIsLoading((item) => ({ ...item, addDeveloperEmployee: false }));
      setIsReloadData(
        isReloadData === null || isReloadData === false ? true : false
      );
      reloadData();
    } else {
      showError(t`${translationPath}developer-employee-create-failed`);
      setIsLoading((item) => ({ ...item, addDeveloperEmployee: false }));
    }
  };

  useEffect(() => {
    reloadData();
  }, [filter]);

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <Spinner
          isActive={
            isLoading.allDeveloperEmployees || isLoading.addDeveloperEmployee
          }
          isAbsolute
        />
        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <DeveloperEmployeesManagementComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={setState}
              onChangeHandler={onChangeHandler}
              className={"px-2 item mt-1"}
              selected={selected}
              setSelected={setSelected}
              key={"save"}
              schema={schema}
              isReloadData={isReloadData}
              setIsCheckIsValidValues={setIsCheckIsValidValues}
              communicationsIdsValues={communicationsIdsValues}
              setIsCanSave={setIsCanSave}
              isCanSave={isCanSave}
            />

            <div className="d-flex-v-center-h-end flex-wrap">
              <ButtonBase
                className="btns theme-solid"
                onClick={CreateDeveloperEmployeeHandler}
                disabled={
                  isLoading.addDeveloperEmployee ||
                  isCheckIsValidValues ||
                  state.employeeName === "" ||
                  !state.employeeName || isCanSave.vaildEmail || isCanSave.validMobile 
                }
              >
                <span className="mdi mdi-plus" />
                {t(`${translationPath}add-developer-employee`)}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
      <DeveloperEmployeesTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        data={items.allDeveloperEmployees}
        filter={filter}
        setFilter={setFilter}
        reloadData={reloadData}
        communicationsIdsValues={communicationsIdsValues}
        isReloadData={isReloadData}
        setIsReloadData={setIsReloadData}
      />
    </div>
  );
};
