import React, { useState, useEffect, useReducer } from "react";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../Components";
import { GetParams, showError, showSuccess } from "../../../../Helper";
import {
  CreateSocialMediaLogoAPI,
  GetSocialMediaLogoByDeveloperContactIdAPI,
} from "../../../../Services";
import {
  SocialMediaLinkComponentTable,
  SocialMediaLinkManagementComponents,
} from "./SocialMediaLinkComponent";

export const SocialMediaLink = ({ translationPath, parentTranslationPath }) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [isSaveValidValues, setIsSaveValidValues] = useState(false);
  const [isHaveValues, setIsHaveValues] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState({
    allSocialMediaLink: false,
    addSocialMediaLink: false,
  });

  const [items, setItems] = useState({
    allSocialMediaLink: {
      result: [],
      totalCount: 0,
    },
  });

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    linkedInLink: null,
    facebookLink: null,
    instagramLink: null,
    snapchatLink: null,
    tikTokLink: null,
    youtubeLink: null,
    xLink: null,
    instagramAccountNumber: null,
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const GetSocialMediaLinkByDeveloperContactId = async (search) => {
    setIsLoading((item) => ({ ...item, allSocialMediaLink: true }));
    const rs = await GetSocialMediaLogoByDeveloperContactIdAPI({
      developerId: +GetParams("id"),
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
      name: filter.search,
    });
    if (!(rs && rs.status && rs.status !== 200)) {
      setItems((item) => ({
        ...item,
        allSocialMediaLink: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    } else {
      setItems((item) => ({
        ...item,
        allSocialMediaLink: { result: [], totalCount: 0 },
      }));
    }
    setIsLoading((item) => ({ ...item, allSocialMediaLink: false }));
  };

  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    GetSocialMediaLinkByDeveloperContactId();
  };

  const CreateSocialMediaLinkHandler = async () => {
    setIsSubmitted(true);
    setIsLoading((item) => ({ ...item, addSocialMediaLink: true }));
    const res = await CreateSocialMediaLogoAPI(state);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}social-media-logo-created-successfully`);
      setIsLoading((item) => ({ ...item, addSocialMediaLink: false }));
      reloadData();
    } else {
      showError(t`${translationPath}social-media-logo-create-failed`);
      setIsLoading((item) => ({ ...item, addSocialMediaLink: false }));
    }
    setIsSubmitted(false);
  };

  useEffect(() => {
    reloadData();
  }, [filter]);

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column mt-2">
      <Spinner
        isActive={isLoading.addSocialMediaLink || isLoading.allSocialMediaLink}
      />
      <div className="w-100 agreement-details-add">
        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <SocialMediaLinkManagementComponents
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={setState}
              className={"px-2 item"}
              setIsHaveValues={setIsHaveValues}
              setIsSaveValidValues={setIsSaveValidValues}
            />
            <div className="mt-3 addBtn">
              <ButtonBase
                className="btns theme-solid"
                disabled={!isHaveValues || isSaveValidValues}
                onClick={() => {
                  CreateSocialMediaLinkHandler();
                }}
              >
                <span className="mdi mdi-plus" />
                {t(`${translationPath}add`)}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
      <SocialMediaLinkComponentTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        data={items.allSocialMediaLink}
        filter={filter}
        setFilter={setFilter}
        reloadData={reloadData}
      />
    </div>
  );
};
