import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { OverviewCard } from "../OverviewCard";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { useIsDesktop, useTranslate } from "../../../../../../Hooks";
import {
  ContactOverviewLeadsCardSkeleton,
  ContactOverviewTransactionCardSkeleton,
  ContactUnitListCardSkeleton,
  TransactionHistoryItemSkeleton,
  UserActivityTimelineCardSkeleton,
} from "../../../../../../Components";
import { TransactionHistoryItem } from "../TransactionHistoryItem";
import {
  UserActivityTimeline,
  ContactOverviewUnitCard,
  ContactOverviewCommentsCard,
  ContactOverviewLeadsCard,
} from "./UI";
import {
  GetLastActivitiesWithTotalCounts,
  GetLastUnitsWithTotalCounts,
  GetLastTransactionsWithTotalCounts,
  GetLastLeadsWithTotalCounts,
} from "../../../../../../Services";

// Styles
import useStyles from "./styles";

function ContactOverviewSummary({ setTabValue, contactId }) {
  const { isExpanded, setAlertBoxContent } = useVerticalNav();
  const { isDesktop } = useIsDesktop();
  const { translate } = useTranslate("NewContactsView");
  const { translate: sharedTranslate } = useTranslate("SharedV2");

  const [contactUnitsData, setContactUnitsData] = useState({
    result: [],
    totalCount: 0,
  });
  const [isContactLoading, setIsContactLoading] = useState(false);

  const [contactActivityData, setContactActivityData] = useState({
    result: [],
    totalCount: 0,
  });
  const [isActivityLoading, setIsActivityLoading] = useState(false);

  const [contactTransactionsData, setContactTransactionsData] = useState({
    result: [],
    totalCount: 0,
  });
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false);

  const [contactLeadsData, setContactLeadsData] = useState({
    result: [],
    totalCount: 0,
  });
  const [isLeadsLoading, setIsLeadsLoading] = useState(false);

  const styles = useStyles();

  const getContactUnits = useCallback(async () => {
    setIsContactLoading(true);
    try {
      const response = await GetLastUnitsWithTotalCounts(contactId); // 1981671

      if (!(response && response.status && response.status !== 200)) {
        setContactUnitsData({
          result: (response && response.units) || 0,
          totalCount: (response && response.totalUnits) || 0,
        });
      } else {
        setContactUnitsData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsContactLoading(false);
    }
  }, []);

  const getContactActivity = useCallback(async () => {
    setIsActivityLoading(true);
    try {
      const response = await GetLastActivitiesWithTotalCounts(contactId);

      if (!(response && response.status && response.status !== 200)) {
        setContactActivityData({
          result: (response && response.activities) || 0,
          totalCount: (response && response.totalActivities) || 0,
        });
      } else {
        setContactActivityData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsActivityLoading(false);
    }
  }, []);
  const getContactTransactions = useCallback(async () => {
    setIsTransactionsLoading(true);
    try {
      const response = await GetLastTransactionsWithTotalCounts(contactId);

      if (!(response && response.status && response.status !== 200)) {
        setContactTransactionsData({
          result: (response && response.transactions) || 0,
          totalCount: (response && response.totalTransactions) || 0,
        });
      } else {
        setContactTransactionsData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsTransactionsLoading(false);
    }
  }, []);
  const getContactLeads = useCallback(async () => {
    setIsLeadsLoading(true);
    try {
      const response = await GetLastLeadsWithTotalCounts(contactId);

      setContactLeadsData({
        result: response?.leads ?? [],
        totalCount: response?.totalLeads ?? 0,
      });
    } catch (error) {
      console.error("Failed to fetch Leads!", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed to fetch Leads!",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      setContactLeadsData({
        result: [],
        totalCount: 0,
      });
    } finally {
      setIsLeadsLoading(false);
    }
  }, []);

  useEffect(() => {
    getContactUnits();
    getContactActivity();
    getContactTransactions();
    getContactLeads();
  }, []);

  return (
    <Box
      className={clsx(styles.overviewContainer, {
        [styles.overviewContainerExpanded]: isExpanded && isDesktop,
      })}
    >
      <Box className={styles.mainContentBox}>
        {isActivityLoading ? (
          <UserActivityTimelineCardSkeleton numberOfCards={2} />
        ) : (
          <OverviewCard
            title={translate("user-activity-timeline")}
            label={`${contactActivityData?.totalCount} ${translate(
              "activities"
            )}`}
            onActionClick={() => setTabValue(7)}
            buttonText={translate("view-all")}
          >
            <UserActivityTimeline
              timelineItems={contactActivityData?.result}
              totalCount={contactActivityData?.totalCount}
            />
          </OverviewCard>
        )}

        {isTransactionsLoading ? (
          <ContactOverviewTransactionCardSkeleton numberOfCards={2} />
        ) : (
          <OverviewCard
            title={translate("transactions-history")}
            label={`${contactTransactionsData.totalCount} ${translate(
              "transactions"
            )}`}
            buttonText={translate("view-all")}
            onActionClick={() => setTabValue(9)}
          >
            {!isTransactionsLoading ? (
              contactTransactionsData?.result?.map((transactionItem, index) => (
                <TransactionHistoryItem
                  key={index}
                  detailsTransaction={transactionItem}
                  hideBorder
                  hideUnitType={false}
                />
              ))
            ) : (
              <TransactionHistoryItemSkeleton
                numberOfCards={2}
                hideBorder
                hideUnitType={false}
              />
            )}
            {contactTransactionsData.totalCount === 0 && (
              <Typography
                className={styles.emptyState}
                variant="h6"
                align="center"
                color="textSecondary"
              >
                {sharedTranslate("no-data-avlbl")}
              </Typography>
            )}
          </OverviewCard>
        )}

        <ContactOverviewCommentsCard contactId={contactId} />
      </Box>
      <Box
        className={clsx(styles.sideContentBox, {
          [styles.sideContentBoxExpanded]: isExpanded && isDesktop,
        })}
      >
        {isLeadsLoading ? (
          <ContactOverviewLeadsCardSkeleton numberOfCards={4} />
        ) : (
          <OverviewCard
            title={sharedTranslate("leads")}
            label={contactLeadsData?.totalCount}
            onActionClick={() => setTabValue(5)}
            buttonText={translate("view-all")}
          >
            <ContactOverviewLeadsCard
              leadItems={contactLeadsData?.result}
              totalCount={contactLeadsData?.totalCount}
            />
          </OverviewCard>
        )}

        {isContactLoading ? (
          <ContactUnitListCardSkeleton numberOfCards={3} />
        ) : (
          <OverviewCard
            title={translate("units")}
            label={`${contactUnitsData?.totalCount}`}
            onActionClick={() => setTabValue(6)}
            buttonText={translate("view-all")}
          >
            <ContactOverviewUnitCard contactUnitsData={contactUnitsData} />
          </OverviewCard>
        )}
      </Box>
    </Box>
  );
}

export default ContactOverviewSummary;
