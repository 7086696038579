import React, { Children, useEffect, useState } from 'react';
import { LoadableImageComponant } from '../../../../Components';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AgentCourseQualificationsLocalePath } from '../i18n/AgentCourseQualificationsLocale';
import { getDownloadableLink } from '../../../../Helper';
import { DefaultImagesEnum, LoadableImageEnum } from '../../../../Enums';
import ScrollableText from '../../../../SharedComponents/ScrollableText/ScrollableText';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents';

function AgentCard({
  agentName,
  branchName,
  email,
  staffCode,
  userProfileImage,
  userTeamNames = '',
  courseList = [],
}) {
  const { t } = useTranslation(AgentCourseQualificationsLocalePath);
  const [isCoursesExpanded, setIsCourseExpanded] = useState(false);
  const [imageSrc, setImageSrc] = useState(DefaultImagesEnum.man.defaultImg);
  const validateUserImage = async () => {
    const response = await fetch(getDownloadableLink(userProfileImage));
    if (response.ok) {
      setImageSrc(getDownloadableLink(userProfileImage));
    }
  };

  useEffect(() => {
    validateUserImage();
  }, [userProfileImage]);
  return (
    <div className='agent-card'>
      <div className='agent-card-header'>
        <LoadableImageComponant
          src={imageSrc}
          type={LoadableImageEnum.image.key}
          alt={agentName}
        />
        <h6>
          <strong>{t('staff_id')}: </strong>
          <CopyToClipboardComponents
            data={staffCode}
            childrenData={staffCode}
          />
        </h6>
      </div>
      <hr />
      <div className='agent-card-body'>
        <div>
          <strong>{t('name')}: </strong>
          <ScrollableText>{agentName}</ScrollableText>
        </div>
        {/* <div>
          <strong>{t('branch')}: </strong>
          <ScrollableText>{branchName}</ScrollableText>
        </div> */}
        {/* <div>
          <strong>{t('team')}: </strong>{' '}
          <ScrollableText>
            {userTeamNames
              ?.split(',')
              .filter((item, i, self) => self.indexOf(item) === i)
              .join(',')}
          </ScrollableText>
        </div> */}

        {/* <div>
          <strong>{t('email')}: </strong>
          <ScrollableText>{email}</ScrollableText>
        </div> */}
        <section>
          <h6>
            <strong>{t('courses')}</strong>
            {/* <IconButton
              className='p-1'
              onClick={() => setIsCourseExpanded((prev) => !prev)}
            >
              {isCoursesExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton> */}
          </h6>
          <div className='agent-courses-container'>
            {(
              <>
                {courseList.length === 0 && (
                  <h5>{t('no_courses_available')}</h5>
                )}
                <ul>
                  {Children.toArray(
                    courseList.map((course) => (
                      <li>
                        <h6>{course.courseName}</h6>
                        <span>
                          {!!course.matchWith &&
                            `${t('matches_with')} ${course.matchWith}`}
                        </span>
                      </li>
                    ))
                  )}
                </ul>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default AgentCard;
