import React from 'react';
import moment from 'moment';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { CopyToClipboardComponents } from '../../../ReusableComponents/UtilityComponents';
import { useTranslation } from 'react-i18next';
import { PropertyValidationContextLocalePath } from '../i18n/PropertyValidationContextLocale';

function PropertySummaryCard({
  cityId,
  communityId,
  countryId,
  districtId,
  propertyId,
  propertyName,
  propertyTypeId,
  subCommunityId,
}) {
  const { t } = useTranslation(PropertyValidationContextLocalePath);
  return (
    <Card className='w-320px'>
      <CardHeader
        title={
          <h5>
            <strong>{t('property_name')}: </strong>
            {propertyName}
          </h5>
        }
        subheader={
          <h6>
            <strong>{t('property_id')}: </strong>
            <CopyToClipboardComponents
              data={propertyId}
              childrenData={propertyId}
            />
          </h6>
        }
        className='py-1'
      />
      <CardContent>
        <hr className='my-3' />
        {!!propertyTypeId && (
          <h6>
            <strong>{t('property_type')}: </strong>
            {propertyTypeId}
          </h6>
        )}
        {!!countryId && (
          <h6>
            <strong>{t('country')}: </strong>
            {countryId}
          </h6>
        )}
        {!!cityId && (
          <h6>
            <strong>{t('city')}: </strong>
            {cityId}
          </h6>
        )}
        {!!districtId && (
          <h6>
            <strong>{t('district')}: </strong>
            {districtId}
          </h6>
        )}
        {!!communityId && (
          <h6>
            <strong>{t('community')}: </strong>
            {communityId}
          </h6>
        )}
        {!!subCommunityId && (
          <h6>
            <strong>{t('sub_community')}: </strong>
            {subCommunityId}
          </h6>
        )}
        <hr className='my-3' />
      </CardContent>
    </Card>
  );
}

export default PropertySummaryCard;
