import React from "react";
import { useTranslation } from "react-i18next";
import { GlobalHistory } from "../../../Helper";

export const MainWorkFlowView = () => {
  const parentTranslationPath = 'WorkFlow';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className="MainWorkFlowView-wrapper Card-Selection-CSS">
      <div className="all-cards">
        <label className="container"
          onClick={() => {
            setTimeout(() => {
              GlobalHistory.push('/home/WorkFlow');
            }, 1700);
          }}
        >
          <input type="checkbox" />
          <div className="custom-checkbox">
            <svg viewBox="0 0 75 75">
              <defs></defs>
              <g id="Layer_1" data-name="Layer 1">
                <path className="st0" d="M18.17,35a19.21,19.21,0,0,0,9.2,16.44v7.31a2.47,2.47,0,0,0,2.47,2.47H47.63V51.48A19.32,19.32,0,1,0,18.17,35Z" />
                <path className="st0" d="M28.31,39.64a3.07,3.07,0,1,1,6.13,0V42.7H31.37a3.06,3.06,0,0,1-3.06-3.06Z" />
                <line className="st0" x1="34.44" y1="42.7" x2="34.44" y2="54.19" />
                <path className="st0" d="M46.69,39.64a3.07,3.07,0,1,0-6.13,0V42.7h3.07a3.06,3.06,0,0,0,3.06-3.06Z" />
                <line className="st0" x1="40.56" y1="42.7" x2="40.56" y2="54.19" />
                <line className="st0" x1="34.44" y1="42.7" x2="40.56" y2="42.7" />
              </g>
              <g id="Layer_2" data-name="Layer 2">
                <line className="st0" x1="29.84" y1="65.13" x2="45.16" y2="65.13" />
                <line className="st0" x1="43.83" y1="69.05" x2="31.17" y2="69.05" />
                <line className="st0" x1="7.81" y1="35.65" x2="13.74" y2="35.65" />
                <line className="st0" x1="16.5" y1="14.65" x2="20.7" y2="18.85" />
                <line className="st0" x1="37.5" y1="5.95" x2="37.5" y2="11.89" />
                <line className="st0" x1="58.5" y1="14.65" x2="54.3" y2="18.85" />
                <line className="st0" x1="67.19" y1="35.65" x2="61.25" y2="35.65" />
              </g>
            </svg>
            <div className="card-label">{t(`${translationPath}Create-Work-Flow`)} </div>
          </div>
        </label>
        <label className="container"
          onClick={() => {
            setTimeout(() => {
              GlobalHistory.push('/home/ExistingWorkFlowView');
            }, 1300);
          }}
        >
          <input type="checkbox" />
          <div className="custom-checkbox">
            <svg className="svg" viewBox="0 0 42.7 42.7">
              <g>
                <polyline className="st0" points="3.6,29.3 27.7,5.1 37.6,15 13.4,39.1" />
                <path className="st0" d="M37.6,15l-9.9-9.9l2.6-2.6c2.7-2.7,7.1-2.7,9.9,0l0,0c2.7,2.7,2.7,7.1,0,9.9L37.6,15z" />
                <path className="st0" d="M13.4,39.1c3.4-3.4-6.4-13.3-9.9-9.9L0.7,42L13.4,39.1z" />
                <line className="st0" x1="31.8" y1="13.1" x2="14.6" y2="30.3" />
              </g>
            </svg>
            <div className="card-label">{t(`${translationPath}Existing-Work-Flow`)} </div>
          </div>
        </label>
      </div>
    </div>
  );
};
