import React, { useState, useRef, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";
import { Paper, Box, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelectedTheme, useOutsideClick } from "../../../Hooks";
import { CustomButton } from "..";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import {
  CreateUserColumnGroup,
  UpdateUserColumnGroups,
  GetAllUserColumnGroup,
  CreateUserSearchCriteria,
  GetAllUserSearchCriteria,
} from "../../../Services";
import { CloseXIcon } from "../../../assets/icons";
import { SearchCriteriaEnum } from "../../../Enums";

// Styles
import useStyles from "./styles";

const SaveFilterModal = () => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const {
    savedFiltersCriteria,
    setSavedFiltersCriteria,
    filterModalData,
    setFilterModalData,
  } = useContext(ContactLayoutContext);
  const { setAlertBoxContent } = useVerticalNav();

  const [title, setTitle] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [filterText, setFilterText] = useState("");
  const modalRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    setTitle(savedFiltersCriteria?.isOpen ? "filter" : "view");
  }, [savedFiltersCriteria?.isOpen, filterModalData?.isOpen]);

  const handleInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const getData = async () => {
    try {
      if (title === "view") {
        const res = await GetAllUserColumnGroup();
        setFilterModalData((prevData) => ({
          ...prevData,
          data: res,
        }));
      } else {
        const res = await GetAllUserSearchCriteria(
          SearchCriteriaEnum.Contact.typeId
        );
        setSavedFiltersCriteria((prevData) => ({
          ...prevData,
          data: res || [],
        }));
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      if (title === "view") {
        setFilterModalData((prevData) => ({
          ...prevData,
          data: [],
        }));
      } else {
        setSavedFiltersCriteria((prevData) => ({
          ...prevData,
          data: [],
        }));
      }
    }
  };

  const saveData = async () => {
    try {
      let result;
      let action = "created"; // Default action is "created"

      if (title === "view") {
        if (filterText && !selectedValue) {
          // Create a new view
          const body = {
            fieldsGroupName: filterText.trim(),
            fieldsGroupData: JSON.stringify(filterModalData?.selectedColumns),
            searchFiledsType: 1,
          };
          result = await CreateUserColumnGroup(body);
        } else {
          // Update existing view
          const view = filterModalData?.data?.find(
            (el) => el?.fieldsGroupName === selectedValue
          );
          const payload = [
            {
              userFilterGroupId: view?.id,
              fieldsGroupName: view?.fieldsGroupName,
              fieldsGroupData: JSON.stringify(filterModalData?.selectedColumns),
              searchFiledsType: 1,
            },
          ];
          result = await UpdateUserColumnGroups(payload);
          action = "updated"; // Set action to "updated" for update case
        }
      } else if (title === "filter") {
        // Save new filter
        const searchCriteriaBody = {
          searchCriteriaName: filterText.trim(),
          searchCriteria: JSON.stringify(savedFiltersCriteria?.filterDataToAdd),
          typeId: SearchCriteriaEnum.Contact.typeId,
        };
        result = await CreateUserSearchCriteria(searchCriteriaBody);
      }

      handleResult(result, action);
    } catch (error) {
      showError(
        `Failed to save ${title}`,
        `${title} was not saved. Please try again.`
      );
      console.error("Error:", error);
    }
  };

  const handleResult = (result, action = "created") => {
    if (result && result?.status && result?.status !== 200) {
      showError(
        `Failed to save ${title}`,
        `${title} was not saved. Please try again.`
      );
    } else {
      getData();

      if (title === "view") {
        // Differentiate between create and update success messages for views
        showSuccess(
          `The view "${
            filterText?.trim() || selectedValue
          }" was successfully ${action}.`
        );
      } else if (title === "filter") {
        // Success message for filters
        showSuccess(
          `New filter "${filterText?.trim()}" was created successfully.`
        );
      }
    }
  };

  const showSuccess = (message) => {
    setAlertBoxContent({
      display: true,
      variant: "success",
      title: message,
      onClose: () => setAlertBoxContent(null),
    });
  };

  const showError = (title, description) => {
    setAlertBoxContent({
      display: true,
      variant: "error",
      title: title,
      description: description,
      onClose: () => setAlertBoxContent(null),
    });
  };

  const handleSave = async () => {
    if (
      (title === "view" && filterModalData?.selectedColumns?.length > 0) ||
      (title === "filter" &&
        savedFiltersCriteria?.filterDataToAdd?.allValues?.length > 0)
    ) {
      await saveData();
    }
    onClose();
  };

  const onClose = () => {
    setFilterModalData((prevData) => ({ ...prevData, isOpen: false }));
    setSavedFiltersCriteria((prevData) => ({ ...prevData, isOpen: false }));
  };

  useOutsideClick({ ref: modalRef, handler: onClose, iconRef });

  return ReactDOM.createPortal(
    <Box className={styles.modalContainer}>
      <Paper ref={modalRef} className={clsx(styles.modalPaper)}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span className={styles.text}>Add New {title}</span>
          <CloseXIcon
            onClick={onClose}
            style={{ cursor: "pointer" }}
            width="20"
            height="20"
            fill={palette.foreground.quinary}
          />
        </Box>
        <Box>
          <Autocomplete
            classes={{ root: styles.inputField }}
            id={`add-${title}`}
            freeSolo
            options={
              filterModalData?.data?.map((option) => option?.fieldsGroupName) ||
              []
            }
            onChange={(event, newValue) => setSelectedValue(newValue)}
            renderInput={(params) => (
              <TextField
                placeholder={
                  title === "filter"
                    ? "Enter a new filter name"
                    : "Update an existing View or add a new name"
                }
                onChange={handleInputChange}
                {...params}
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Box>
        <Box className={styles.actions}>
          <CustomButton
            boxShadow="xs"
            size="md"
            onClick={onClose}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </CustomButton>
          <CustomButton
            boxShadow="xs"
            size="md"
            onClick={handleSave}
            variant="contained"
            disabled={!(filterText || selectedValue)}
          >
            {selectedValue ? "Update" : "Save"}
          </CustomButton>
        </Box>
      </Paper>
    </Box>,
    document.getElementById("portal-root")
  );
};

export default SaveFilterModal;
