import React from "react";

function ZIPIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <rect x="1" y="18" width="22" height="16" rx="2" fill="#344054" />
      <path
        d="M4.58034 30V29.0874L8.20961 23.995H4.57324V22.7273H10.1414V23.6399L6.50861 28.7322H10.1485V30H4.58034ZM12.866 22.7273V30H11.3284V22.7273H12.866ZM14.1311 30V22.7273H17.0004C17.5521 22.7273 18.022 22.8326 18.4102 23.0433C18.7985 23.2517 19.0944 23.5417 19.298 23.9134C19.504 24.2827 19.607 24.7088 19.607 25.1918C19.607 25.6747 19.5028 26.1009 19.2945 26.4702C19.0861 26.8395 18.7843 27.1271 18.3889 27.3331C17.9959 27.5391 17.5201 27.642 16.9614 27.642H15.1325V26.4098H16.7128C17.0087 26.4098 17.2526 26.3589 17.4443 26.2571C17.6385 26.1529 17.7829 26.0097 17.8776 25.8274C17.9746 25.6428 18.0232 25.4309 18.0232 25.1918C18.0232 24.9503 17.9746 24.7396 17.8776 24.5597C17.7829 24.3774 17.6385 24.2365 17.4443 24.1371C17.2502 24.0353 17.004 23.9844 16.7057 23.9844H15.6688V30H14.1311Z"
        fill="white"
      />
    </svg>
  );
}
export default ZIPIcon;
