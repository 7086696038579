import React from "react";

function MP4Icon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <rect x="1" y="18" width="29" height="16" rx="2" fill="#155EEF" />
      <path
        d="M4.93093 22.7273H6.82724L8.83008 27.6136H8.91531L10.9181 22.7273H12.8145V30H11.323V25.2663H11.2626L9.3805 29.9645H8.36488L6.48278 25.2486H6.42241V30H4.93093V22.7273ZM14.0813 30V22.7273H16.9506C17.5022 22.7273 17.9722 22.8326 18.3604 23.0433C18.7487 23.2517 19.0446 23.5417 19.2482 23.9134C19.4542 24.2827 19.5572 24.7088 19.5572 25.1918C19.5572 25.6747 19.453 26.1009 19.2447 26.4702C19.0363 26.8395 18.7345 27.1271 18.3391 27.3331C17.9461 27.5391 17.4703 27.642 16.9116 27.642H15.0827V26.4098H16.663C16.9589 26.4098 17.2028 26.3589 17.3945 26.2571C17.5887 26.1529 17.7331 26.0097 17.8278 25.8274C17.9248 25.6428 17.9734 25.4309 17.9734 25.1918C17.9734 24.9503 17.9248 24.7396 17.8278 24.5597C17.7331 24.3774 17.5887 24.2365 17.3945 24.1371C17.2004 24.0353 16.9542 23.9844 16.6559 23.9844H15.619V30H14.0813ZM20.4032 28.7216V27.5107L23.4395 22.7273H24.4835V24.4034H23.8656L21.9515 27.4325V27.4893H26.2662V28.7216H20.4032ZM23.894 30V28.3523L23.9224 27.8161V22.7273H25.3642V30H23.894Z"
        fill="white"
      />
    </svg>
  );
}

export default MP4Icon;
