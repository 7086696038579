import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { Spinner } from "../../../../../Components";
import { FooterWrapperComponent } from "../../DevelopersUtilities/Components/FooterWrapperComponent";
import { GetParams, showError, showSuccess } from "../../../../../Helper";
import { UpdateSocialMediaLogoAPI } from "../../../../../Services";
import { SocialMediaLinkManagementComponents } from "../SocialMediaLinkComponent/SocialMediaLinkManagementComponents";

export const SocialMediaLinkComponent = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  socialMediaLinkValues,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({ update: false });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSaveValidValues, setIsSaveValidValues] = useState(false);
  const [isHaveValues, setIsHaveValues] = useState(false);
  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    linkedInLink: null,
    facebookLink: null,
    instagramLink: null,
    snapchatLink: null,
    tikTokLink: null,
    youtubeLink: null,
    xLink: null,
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const UpdateSocialMediaLinkHandler = async () => {
    setIsSubmitted(true);
    setIsLoading((item) => ({ ...item, edit: true }));
    const res = await UpdateSocialMediaLogoAPI(
      socialMediaLinkValues.socialMediaLogoId,
      state
    );
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}social-media-logo-updated-successfully`);
      setIsLoading((item) => ({ ...item, edit: false }));
      onSave(true);
    } else {
      showError(t`${translationPath}social-media-logo-updated-failed`);
      setIsLoading((item) => ({ ...item, edit: false }));
    }
    setIsSubmitted(false);
  };

  useEffect(() => {
    if (socialMediaLinkValues) {
      const updateState = {
        developerContactId:
          socialMediaLinkValues?.developerContactId || +GetParams("id"),
        socialMediaLogoId: socialMediaLinkValues?.socialMediaLogoId,
        linkedInLink: socialMediaLinkValues?.linkedInLink,
        facebookLink: socialMediaLinkValues?.facebookLink,
        instagramLink: socialMediaLinkValues?.instagramLink,
        snapchatLink: socialMediaLinkValues?.snapchatLink,
        tikTokLink: socialMediaLinkValues?.tikTokLink,
        youtubeLink: socialMediaLinkValues?.youtubeLink,
        xLink: socialMediaLinkValues.xLink,
      };
      setState({ id: "edit", value: updateState });
    }
  }, [socialMediaLinkValues]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <Spinner isActive={isLoading.info} isAbsolute />
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-social-media-link`)}</span>
        </DialogTitle>
        <DialogContent className="dialog-content-wrapper">
          <SocialMediaLinkManagementComponents
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            state={state}
            setState={setState}
            className={"dialog-content-item w-50"}
            setIsHaveValues={setIsHaveValues}
            setIsSaveValidValues={setIsSaveValidValues}
            editForm
          />
        </DialogContent>
        <DialogActions>
          <FooterWrapperComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onClose={onClose}
            onSave={UpdateSocialMediaLinkHandler}
            disabled={!isHaveValues || isSaveValidValues}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
