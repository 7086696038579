import React, { useState } from "react";
import { PropTypes } from "prop-types";
import Styles from "./UnitOverviewComponent.module.scss";
import SideSection from "./Sections/SideSection/SideSection";
import MiddleSection from "./Sections/MiddleSection/MiddleSection";
import Button from "@material-ui/core/Button";
import { formatCommasForPriceUnit } from "../../../../../../../../Helper/formatCommas.Helper";
import { CopyToClipboardComponents } from "../../../../../../../../ReusableComponents/UtilityComponents";
import { showError, showSuccess } from "../../../../../../../../Helper";
import {
  GetSuitableTemplateIdForUnit,
  MarkOrUnmarkUnitAsShortListed,
} from "../../../../../../../../Services";
import { SelectComponet } from "../../../../../../../../Components";
import { UnitTemplateDialogPreviewDialog } from "../../../../../../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog";
import { UnitsOperationTypeEnum } from "../../../../../../../../Enums";
import { MessageConfirmDialog } from "../../../../../../../../SharedComponents";
import { useTranslation } from "react-i18next";

const UnitOverviewComponent = ({
  data,
  actionButtons,
  reload,
  unitId,
  inquiryId,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(-1);
  const [isLoading, setIsLoading] = useState({
    template: false,
    markAsShortListed: false,
  });
  const [templates, setTemplates] = useState([]);
  const [activeTemplateName, setActiveTemplateName] = useState(null);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const [isOpenUnmarkDialog, setIsOpenUnmarkDialog] = useState(false);

  const GetSuitableTemplateIdForUnitApi = async () => {
    setIsLoading((prevState) => ({ ...prevState, template: true }));
    let res;
    res = await GetSuitableTemplateIdForUnit(
      unitId,
      1,
      10000,
      UnitsOperationTypeEnum.rent.key
    );
    if (!((res && res.data && res.data.ErrorId) || !res))
      setTemplatesProposal((res && res.result) || []);
    else setTemplatesProposal([]);
    setIsLoading((prevState) => ({ ...prevState, template: false }));
  };

  const markOrUnmarkUnitAsShortListed = async (isShortListed) => {
    setIsLoading((prevState) => ({ ...prevState, markAsShortListed: true }));
    let res;
    res = await MarkOrUnmarkUnitAsShortListed({
      unitId,
      inquiryId,
      isShortListed,
    });
    if (res == true) {
      showSuccess("Successful Process!");
      reload();
    } else showError("Process Failed ..");
    setIsLoading((prevState) => ({ ...prevState, markAsShortListed: false }));
  };

  return (
    <div className={Styles.Page_Layout}>
      <div className={Styles.Overview}>
        <div className={Styles.Top_Section}>
          <div className="d-flex-v-center-h-between gap-8">
            <div className={Styles.Top_Details_Box}>
              <p className={Styles.Type}>{data.unitType}</p>
              <div className={Styles.Title}>
                {data.propertyName ? <h5>{data.propertyName}</h5> : null}
                <span>
                  {data.unitRefNo ? (
                    <CopyToClipboardComponents
                      data={data.unitRefNo}
                      childrenData={data.unitRefNo}
                    />
                  ) : null}
                </span>
              </div>
              <div className={Styles.Capsules_container}>
                <div className={Styles.Outlined_capsule__gray}>
                  <span>
                    {(data.operationType === "Rent"
                      ? data.leaseStatusName
                      : data.saleStatusName) || null}
                  </span>
                </div>
                {data.operationType ? (
                  <div className={Styles.Outlined_capsule__gray}>
                    <span>{data.operationType}</span>
                  </div>
                ) : null}
                {data.operationType !== "Rent" && data.saleType ? (
                  <div className={Styles.Outlined_capsule__gray}>
                    <span>{data.saleType}</span>
                  </div>
                ) : null}
                {data.masterDeveloper ? (
                  <div className={Styles.Outlined_capsule__gray}>
                    <span>{data.masterDeveloper}</span>
                  </div>
                ) : null}
              </div>
            </div>
            {data.rentPerYear || data.sellingPrice ? (
              <div className={Styles.Price}>
                <span>AED</span>
                <p>
                  {formatCommasForPriceUnit(
                    data.operationType == "Rent"
                      ? data.rentPerYear
                      : data.sellingPrice || 0
                  )}
                </p>
              </div>
            ) : null}
          </div>
           {data?.unitRefNo?
           <div className={Styles.Card_Buttons}>
            {actionButtons.sendPropsal ? (
              <Button
                className="btns theme-propx outlined"
                id="btn-save"
                onClick={() => {}}
              >
                <span>{t(`Send-Propsal`)}</span>
                <div className="Select_Button_Type">
                  <SelectComponet
                    data={templatesProposal}
                    keyLoopBy="systemTemplateId"
                    valueInput="systemTemplateId"
                    textInput="systemTemplateName"
                    value={activeTemplateId}
                    keyValue="actionsbuttons"
                    idRef="contactsActionsRef"
                    onSelectChanged={(value) => {
                      setActiveTemplateId(value);
                      if (value !== -1) {
                        const itemIndex = templates.findIndex(
                          (item) => item.systemTemplateId === value
                        );
                        if (itemIndex !== -1) {
                          setActiveTemplateName(
                            templates[itemIndex].systemTemplateName
                          );
                        }
                        setIsOpenTemplateDialog(true);
                      }
                    }}
                    themeClass="theme-action-buttons"
                    emptyItem={{
                      value: -1,
                      text: "templateProposal",
                      isHiddenOnOpen: true,
                    }}
                    onOpen={() => {
                      setTemplatesProposal([]);
                      GetSuitableTemplateIdForUnitApi(data.id);
                    }}
                    isLoading={isLoading.template}
                  />
                </div>
              </Button>
            ) : null}
            {actionButtons.markAsShortListed ? (
              <Button
                className={`btns theme-propx solid ${Styles.blueBtnTheme}`}
                id="btn-save"
                disabled={isLoading.markAsShortListed}
                onClick={() => markOrUnmarkUnitAsShortListed(true)}
              >
                <span>{`Mark as Shortlisted`}</span>
              </Button>
            ) : null}
          </div>: null}
        </div>
        <div className={Styles.Content}>
          <div className={Styles.Middle_Section}>
            <MiddleSection data={data} />
          </div>
          <div className={Styles.Side_Section}>
            <SideSection data={data} />
          </div>
        </div>
      </div>
      {isOpenUnmarkDialog && (
        <MessageConfirmDialog
          isDialogOpen={isOpenUnmarkDialog}
          theme="warning"
          content={{
            heading: "Remove unit from shortlist confirmation",
            body: `Are you sure you want to remove this unit ( ${data.unitRefNo} ) from your shortlist? This action cannot be undone.`,
          }}
          confirmButton={{
            label: "Unmark",
            handler: async () => {
              await markOrUnmarkUnitAsShortListed(false);
              setIsOpenUnmarkDialog(false);
            },
          }}
          cancelButton={{
            label: "Discard",
            handler: () => {
              setIsOpenUnmarkDialog(false);
            },
          }}
          allowContentCopy
        />
      )}
      {isOpenTemplateDialog && (
        <UnitTemplateDialogPreviewDialog
          activeItem={activeTemplateId}
          isOpen={isOpenTemplateDialog}
          unitID={unitId}
          maintitleText={activeTemplateName}
          unitOwner={data?.leadOwnerDetails}
          leaseUnitOwner={data?.leaseLeadOwnerDetails}
          isOpenChanged={() => {
            setIsOpenTemplateDialog(false);
            setActiveTemplateId(-1);
          }}
          templateName={activeTemplateName}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
UnitOverviewComponent.propTypes = {
  actionButtons: PropTypes.object,
  reload: PropTypes.func,
  data: PropTypes.object,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
};
UnitOverviewComponent.defaultProps = {
  actionButtons: {
    sendPropsal: false,
    markAsShortListed: false,
  },
  reload: () => {},
  parentTranslationPath: "Inquires",
  translationPath: "",
};
export default UnitOverviewComponent;
