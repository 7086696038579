import React from "react";
import {
  FolderIcon,
  VedioIcon,
  JPEGIcon,
  PNGIcon,
  WebpIcon,
  TIFFIcon,
  GIFIcon,
  SVGIcon,
  DOCXIcon,
  TXTIcon,
  CSVIcon,
  XLSXIcon,
  PPTIcon,
  FIGFigmaIcon,
  PSDPhotoshopIcon,
  INDDInDesignIcon,
  AEPAfterEffectsIcon,
  MP3Icon,
  WAVIcon,
  HTMLIcon,
  CSSIcon,
  SQLIcon,
  JSIcon,
  JAVAIcon,
  EXEIcon,
  ZIPIcon,
  EmptyIcon,
  PdfIcon,
} from "../../../assets/upload-icons";

export const GetFileIconbyDocumentName = (documentName) => {
  if (!documentName) {
    return <FolderIcon />;
  }
  switch (documentName) {
    case "pdf":
      return <PdfIcon />;
    case "xlsx":
    case "xls":
      return <XLSXIcon />;
    case "docx":
    case "doc":
      return <DOCXIcon />;
    case "txt":
      return <TXTIcon />;
    case "csv":
      return <CSVIcon />;
    case "ppt":
    case "pptx":
      return <PPTIcon />;
    case "fig":
      return <FIGFigmaIcon />;
    case "psd":
      return <PSDPhotoshopIcon />;
    case "indd":
      return <INDDInDesignIcon />;
    case "aep":
      return <AEPAfterEffectsIcon />;
    case "mp3":
      return <MP3Icon />;
    case "wav":
      return <WAVIcon />;
    case "mp4":
    case "mpeg":
    case "avi":
    case "mkv":
      return <VedioIcon />;
    case "html":
      return <HTMLIcon />;
    case "css":
      return <CSSIcon />;
    case "sql":
      return <SQLIcon />;
    case "js":
      return <JSIcon />;
    case "java":
      return <JAVAIcon />;
    case "exe":
      return <EXEIcon />;
    case "zip":
    case "rar":
      return <ZIPIcon />;
    case "png":
      return <PNGIcon />;
    case "jpeg":
    case "jpg":
      return <JPEGIcon />;
    case "webp":
      return <WebpIcon />;
    case "tiff":
      return <TIFFIcon />;
    case "gif":
      return <GIFIcon />;
    case "svg":
      return <SVGIcon />;
    default:
      return <EmptyIcon />; 
  }
};

export default GetFileIconbyDocumentName;
