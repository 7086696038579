import React, { createContext, useContext, useRef, useState } from 'react';
import { DialogComponent, Inputs, Spinner } from '../../Components';
import { propertyFields } from './fields';
import { PropertyValidationContextLocalePath } from './i18n/PropertyValidationContextLocale';
import { getErrorByName, GlobalHistory, showError } from '../../Helper';
import { Children } from 'react';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import LookupAutocomplete from '../../Components/Controls/LookupAutocomplete/LookupAutocomplete';
import {
  CheckPropertyForDuplicates,
  GetLocationByAddress,
} from '../../Services';
import PropertySummaryCard from './components/PropertySummaryCard';

const initialLocation = {
  country: {},
  city: {},
  district: {},
  community: {},
  subCommunity: {},
};
const PropertyValidationContext = createContext({
  isDuplicatedProperty: false,
  isValidationDialogOpen: false,
  togglePropertyValidationDialog: (callback = null, closeCallback = null) => {},
  setContactType: () => {},
  data: {
    propertyName: '',
    propertyType: {},
    country: {},
    city: {},
    district: {},
    community: {},
    subCommunity: {},
    map: '',
  },
  isValidProperty: false,
  onCloseHandler: () => {},
  resetStateHandler: () => {},
});

export const usePropertyValidationContext = () =>
  useContext(PropertyValidationContext);

export const PropertyValidationProvider = ({ children }) => {
  const { t } = useTranslation(PropertyValidationContextLocalePath);
  const [isDuplicatedProperty, setIsDuplicatedProperty] = useState(false);
  const [isFuzzyMatch, setIsFuzzyMatch] = useState(false);
  const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);
  const [isValidProperty, setIsValidProperty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [matchingProperties, setMatchingProperties] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const callbackRef = useRef();
  const closeCallbackRef = useRef();
  const [data, setData] = useState({
    propertyName: '',
    propertyType: {},
    country: {},
    city: {},
    district: {},
    community: {},
    subCommunity: {},
    map: null,
  });
  const lookupItemSchema = (field) =>
    Joi.object({
      lookupItemId: Joi.number()
        .required()
        .messages({
          'number.base': t('is-required', {
            ns: 'InquiryRotation',
            field: t(field),
          }),
          'number.empty': t('is-required', {
            ns: 'InquiryRotation',
            field: t(field),
          }),
          'any.required': t('is-required', {
            ns: 'InquiryRotation',
            field: t(field),
          }),
        }),
      lookupItemName: Joi.string().required(),
    });
  const schema = Joi.object({
    propertyName: Joi.string()
      .regex(/^(?![\W_]+$).+/)
      .required()
      .messages({
        'string.empty': t('is-required', {
          ns: 'InquiryRotation',
          field: t('property_name'),
        }),
        'any.required': t('is-required', {
          ns: 'InquiryRotation',
          field: t('property_name'),
        }),
        'string.pattern.base': t('invalid_property_name'),
      }),
    propertyType: lookupItemSchema('property_type').required(),
    country: lookupItemSchema('country').required(),
    city: lookupItemSchema('city').required(),
    district: lookupItemSchema('district').required(),
    community: lookupItemSchema('community').required(),
    subCommunity: lookupItemSchema().allow({}, null),
  }).validate(data, {
    allowUnknown: true,
    abortEarly: false,
  });

  const setMap = (map) => setData((prev) => ({ ...prev, map }));

  const changeDataHandler = async (field, value) => {
    if (Object.hasOwn(initialLocation, field)) {
      let map = data.map;
      let location = { ...initialLocation };
      Object.keys(location).forEach(async (key, i, self) => {
        if (i === self.indexOf(field)) {
          location[field] = value;
          const geolocation = await GetLocationByAddress(value.lookupItemName);
          const mapData = geolocation.results?.[0]?.geometry?.location;
          if (mapData) {
            const { lat, lng } = mapData;
            const latitude = lat.toString();
            const longitude = lng.toString();
            map = { latitude, longitude };
            setMap(map);
          }
        } else if (i < self.indexOf(field)) {
          delete location[key];
        }
      });
      setData((prevData) => ({ ...prevData, ...location }));
    } else {
      setData((prevData) => ({ ...prevData, [field]: value }));
    }
  };
  const togglePropertyValidationDialog = (callback, closeCallback) => {
    if (callback) {
      callbackRef.current = callback;
    }
    if (closeCallback) {
      closeCallbackRef.current = closeCallback;
    }
    setIsValidationDialogOpen((prev) => !prev);
  };

  const resetStateHandler = () => {
    setData({
      propertyName: '',
      propertyType: {},
      country: {},
      city: {},
      district: {},
      community: {},
      subCommunity: {},
      map: null,
    });
    setIsDuplicatedProperty(false);
    setIsFuzzyMatch(false);
    setIsValidProperty(false);
    setMatchingProperties([]);
    setIsSubmitted(false);
  };

  const onCloseHandler = () => {
    setIsValidationDialogOpen(false);
    if (!callbackRef.current && !closeCallbackRef.current) {
      GlobalHistory.goBack();
    } else {
      callbackRef.current = null;

      if (closeCallbackRef.current) {
        closeCallbackRef.current();
      }
    }
    closeCallbackRef.current = null;
    resetStateHandler();
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsSubmitted(true);
    try {
      if (isFuzzyMatch && isValidProperty) {
        if (callbackRef.current) {
          callbackRef.current();
        }
        setIsValidationDialogOpen(false);
        return;
      }
      if (schema.error) {
        return showError(
          t('fill_all_required_fields', { ns: 'InquiryRotation' })
        );
      }
      const res = await CheckPropertyForDuplicates({
        propertyName: data.propertyName,
        propertyTypeId: data.propertyType.lookupItemId,
        subCommunityId: data.subCommunity?.lookupItemId,
      });
      if (res.isExactMatch || res.isFuzzyMatch) {
        setIsDuplicatedProperty(res.isExactMatch);
        setIsFuzzyMatch(res.isFuzzyMatch);
        if (res.isExactMatch) {
          setMatchingProperties(res.matchingProperties);
        } else {
          setMatchingProperties(res.fuzzyMatchingProperties);
          setIsValidProperty(true);
        }
        return;
      }
      setIsValidProperty(true);
      togglePropertyValidationDialog();
      if (callbackRef.current) {
        callbackRef.current();
      }
    } catch (error) {
      showError('error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <PropertyValidationContext.Provider
      value={{
        isDuplicatedProperty,
        isValidationDialogOpen,
        data,
        togglePropertyValidationDialog,
        isValidProperty,
        onCloseHandler,
        resetStateHandler,
      }}
    >
      {children}
      {isValidationDialogOpen && (
        <DialogComponent
          parentTranslationPath={PropertyValidationContextLocalePath}
          onCancelClicked={onCloseHandler}
          onCloseClicked={onCloseHandler}
          isOpen
          titleText={
            isFuzzyMatch || isDuplicatedProperty
              ? 'similar_properties'
              : 'add_new_property'
          }
          maxWidth={matchingProperties.length > 0 ? 'lg' : 'sm'}
          dialogContent={
            <div className='flex gap-1 flex-col'>
              <Spinner isActive={loading} isAbsolute />
              {matchingProperties.length > 0 ? (
                <div className='flex flex-wrap gap-1 fj-center'>
                  <div className='w-100'>
                    {isDuplicatedProperty && (
                      <h3 className='text-lg fw-bold'>
                        {matchingProperties.length > 1
                          ? t('multiple_matching_properties', {
                              count: matchingProperties.length,
                            })
                          : t('single_matching_property')}
                      </h3>
                    )}
                    {isFuzzyMatch && (
                      <h3 className='text-lg fw-bold'>
                        {matchingProperties.length > 1
                          ? t('multiple_fuzzy_matching_properties', {
                              count: matchingProperties.length,
                            })
                          : t('single_fuzzy_matching_property')}
                      </h3>
                    )}
                  </div>
                  {Children.toArray(
                    matchingProperties.map((property) => (
                      <PropertySummaryCard
                        key={property.propertyId}
                        {...property}
                      />
                    ))
                  )}
                </div>
              ) : (
                propertyFields.map((field) => {
                  if (['text', 'email'].includes(field.type)) {
                    return (
                      <Inputs
                        type={field.type}
                        labelValue={field.label}
                        onInputChanged={(e) => {
                          e.persist();
                          changeDataHandler(field.name, e.target.value);
                        }}
                        key={field.name}
                        value={data[field.name]}
                        parentTranslationPath={
                          PropertyValidationContextLocalePath
                        }
                        isSubmitted={isSubmitted}
                        isWithError
                        error={getErrorByName(schema, field.name).error}
                        helperText={getErrorByName(schema, field.name).message}
                      />
                    );
                  } else if (field.type === 'lookup') {
                    return (
                      <LookupAutocomplete
                        lookupTypeId={field.lookupItemId}
                        selectedValues={data[field.name]}
                        label={field.label}
                        onChange={(_e, value) =>
                          changeDataHandler(field.name, value)
                        }
                        key={field.name}
                        disabled={
                          field.dependsOnLookup
                            ? !data[field.dependsOnLookup]?.lookupItemId
                            : undefined
                        }
                        parentTranslationPath={
                          PropertyValidationContextLocalePath
                        }
                        isSubmitted={isSubmitted}
                        helperText={getErrorByName(schema, field.name).message}
                        error={getErrorByName(schema, field.name).error}
                        lookupParentId={
                          field.dependsOnLookup
                            ? data[field.dependsOnLookup]?.lookupItemId
                            : undefined
                        }
                        required={field.required}
                        withError
                      />
                    );
                  }
                })
              )}
            </div>
          }
          saveText={isFuzzyMatch ? 'skip_and_continue' : 'submit'}
          saveIsDisabled={isDuplicatedProperty}
          onSaveClicked={submitHandler}
        />
      )}
    </PropertyValidationContext.Provider>
  );
};
