import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Spinner, Tables } from '../../../../../Components';
import { GetParams , bottomBoxComponentUpdate  } from '../../../../../Helper';
import { GetDeveloperLeadsAPI } from '../../../../../Services';

export const DeveloperProfileLeadsOverviewComponent = ({ 
  id,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [developerId, setDeveloperId] = useState(+GetParams('id'));
  const [leadsOverview, setLeadsOverview] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: localStorage.getItem('Pagination') || 25,
    pageIndex: 0,
  });
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  
  const transactionLinksActionClicked = useCallback(
    (item, key) => async (event) => {
      event.stopPropagation();
      if(key === 'lead' && item?.leadType === 'Owner'  && item.leadTypeId && item.leadId)
         window.open(`/home/leads/lead-profile-edit?formType=${item.leadTypeId}&id=${item.leadId}`, '_blank'); 
       else if(key === 'contact'  &&  item.contactId &&  item.contactTypeId)
       window.open(`/home/Contacts-CRM/contact-profile-edit?formType=${item.contactTypeId}&id=${item.contactId}` ,'_blank');

    else if(key === 'lead' && item.leadType === 'Seeker' && item.leadTypeId && item.leadId   )
      window.open(`/home/leads/lead-profile-edit?formType=${item.leadTypeId}&id=${item.leadId}`, '_blank'); 
   else  if(key === 'unit' && item?.unitOperationType === 'SaleAndRent' && item.unitOperationTypeId && item.unitRefNo)
      window.open(`/home/units-sales/unit-profile-edit?formType=${1}&id=${item.unitId || item.unitRefNo}&operationType=${item.unitOperationTypeId}`, '_blank')
    else if(key === 'unit' && item.unitOperationType === 'Sale' )
      window.open(`/home/units-sales/unit-profile-edit?formType=${1}&id=${item.unitId || item.unitRefNo}&operationType=${item.unitOperationTypeId}`, '_blank');
    else if(key === 'unit' && item.unitOperationType === 'Lease')
      window.open(`/home/units-lease/unit-profile-edit?formType=${1}&id=${item.unitId || item.unitRefNo}&operationType=${item.unitOperationTypeId}`, '_blank');
    else if(key === 'property' &&   item.interestedProperty && item.interestedPropertyId )
      window.open(`/home/Properties-CRM/property-profile-edit?formType=${1}&id=${item.interestedPropertyId}`, '_blank')
    }
  );
   
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const GetAllDeveloperLeads= useCallback(async () => {
    setIsLoading(true);
    const res = await GetDeveloperLeadsAPI({ developerId  : developerId , pageIndex : filter.pageIndex +1 , pageSize : filter.pageSize});
    if (!(res && res.status && res.status !== 200)) {
      setLeadsOverview({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setLeadsOverview({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter]);

  useEffect(() => {
    GetAllDeveloperLeads();
  }, [GetAllDeveloperLeads]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  )

  return (
    <div className='childs-wrapper p-relative mt-3 px-2'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section'>
        <span>{t(`${translationPath}leads-overview`)}</span>
      </div>
      <div className='w-100 px-2'>
        <div className='filter-sections-wrapper' />
        <Tables
          data={leadsOverview.result}
          headerData={[
            { 
              id: 1, label: 'lead-id', input: 'leadId'  , 
              component: (item) => (
                item.leadId ?  
                <a className='c-primary newTabActivites' onClick={transactionLinksActionClicked(item, 'lead')}>
                  {(item.leadId) || 'N/A'}
                </a>
                : <span>  {(item.leadId) || ''} </span>
              ),
            },
            { 
              id: 2, label: 'lead-name', input: 'leadName'  , 
              component: (item) => (
                item.leadId ?  
                <a className='c-primary newTabActivites' onClick={transactionLinksActionClicked(item, 'lead')}>
                  {(item.leadName) || ''}
                </a>
                : <span>  {(item.leadName) || ''} </span>
              ),


            },
            { 
              id: 3, label: 'contactId', input: 'contactId'  , 
              component: (item) => (
                item.contactId ?  
                <a className='c-primary newTabActivites' onClick={transactionLinksActionClicked(item, 'contact')}>
                  {(item.contactId) || ''}
                </a>
                :
                <span>  {(item.contactId) || ''}  </span>
              ),


            },
            {
              id: 4,
              label: 'interested-property',
              input: 'interestedProperty',
              component: (item) => (
                item.interestedPropertyId ?
                <a className='c-primary newTabActivites' onClick={transactionLinksActionClicked(item, 'property')}>
                  {(item.interestedProperty) || ''}
                </a>
                : 
                <span> {(item.interestedProperty) || ''} </span>
              ),
            },
            {
              id: 5,
              label: 'lead-stage',
              input: 'leadStage',
             
            },
         
            {
              id: 6,
              label: 'lead-status',
              input: 'leadStatus',
              component: (item, index) => (
                (item.leadStatus === 'Open' && <div className='globel-open-style' index={index}>{t(`${translationPath}open`)}</div>) || <div className='globel-closed-style' index={index}>{t(`${translationPath}closed`)}</div>
              ),
            },
            {
              id:7,
              label:'assigned-agent',
              input:'assignedAgent' ,
            } ,
            {
              id: 8,
              label: 'transaction-value',
              input: 'transactionValue',
            
            },
            {
              id: 9,
              label: 'unit-type',
              input: 'unitType',
            },
            {
              id: 10 ,
              label: 'unitRef',
              input: 'unitRefNo',
              component: (item) => (
                item.unitId ? 
                <a className='c-primary newTabActivites' onClick={transactionLinksActionClicked(item, 'unit')}>
                  {(item.unitRefNo) || ''}
                </a>
                :
                <span> {(item.unitRefNo) || ''} </span>
              ),
            },

          ]}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          dateFormat='DD, MMM, YYYY'
          defaultActions={[]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={leadsOverview.totalCount}
        />
      </div>
    </div>
  );

};

DeveloperProfileLeadsOverviewComponent.propTypes = {
  id: PropTypes.number.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
