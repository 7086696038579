import React from 'react';

function Image01Icon({ fill, ...restProps }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill={fill ?? ''}
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <path
        d='M5.92 2.041c-.769.051-1.248.164-1.74.41A3.776 3.776 0 0 0 2.124 5.16C2.013 5.729 2 6.455 2 12c0 6.358.006 6.526.245 7.32.332 1.102 1.333 2.103 2.435 2.435.794.239.962.245 7.32.245 6.358 0 6.526-.006 7.32-.245 1.102-.332 2.103-1.333 2.435-2.435.239-.794.245-.962.245-7.32 0-6.358-.006-6.526-.245-7.32-.331-1.099-1.29-2.058-2.435-2.434-.676-.222-.52-.217-6.98-.227-3.278-.005-6.167.005-6.42.022m12.572 2.05c.15.036.357.107.46.157.242.118.682.558.8.8.218.448.219.458.237 4.132l.017 3.4-1.373-1.362c-.755-.749-1.468-1.421-1.583-1.494a2.035 2.035 0 0 0-2.1-.001c-.222.138-9.551 9.417-9.82 9.767-.081.104-.172.19-.202.19-.091 0-.581-.524-.68-.728a2.86 2.86 0 0 1-.16-.48c-.063-.269-.068-.785-.068-6.472s.005-6.203.068-6.472c.087-.373.206-.61.432-.861.363-.405.762-.576 1.46-.628.22-.016 3.064-.026 6.32-.021 5.343.006 5.947.014 6.192.073M7.808 5.581c-.933.203-1.839 1.02-2.14 1.929-.448 1.356.056 2.772 1.265 3.55.889.572 2.245.572 3.134 0 1.208-.778 1.713-2.194 1.265-3.549-.304-.92-1.21-1.731-2.158-1.932-.34-.072-1.031-.071-1.366.002m1.215 2.081c.283.174.431.432.451.784.013.237 0 .305-.095.499-.349.713-1.379.724-1.748.018a.98.98 0 0 1 .418-1.341c.181-.092.251-.105.495-.094.232.011.319.035.479.134m8.993 5.774 1.996 1.996-.024 1.394c-.031 1.858-.079 2.065-.588 2.575-.28.28-.561.44-.92.522-.169.039-1.466.054-5.64.065l-5.42.014 4.28-4.281A644.113 644.113 0 0 1 16 11.44c.011 0 .918.898 2.016 1.996'
        fill={fill ?? ''}
      />
    </svg>
  );
}

export default Image01Icon;
