import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  DialogComponent,
  LoadableImageComponant,
  Spinner,
} from "../../../../Components";
import { GetShareUnitDetails } from "../../../../Services";
import "../style/CardDetailsComponent.scss";
import { DefaultImagesEnum } from "../../../../Enums";
import { isAdminInquiries } from "../../../../Helper/isAdmin.Helper";
import { CopyToClipboardComponents } from "../../../../ReusableComponents/UtilityComponents";
import { ShareTemplateUnitDialog } from "./ShareTemplateUnitDialog";
import { AddToFavouriteShareUnitsDialog } from "./AddToFavouriteShareUnitsDialog";

export const ShareUnitDialog = ({
  isOpen,
  isOpenChanged,
  activeItem,
  onClose,
  onSave,
  parentTranslationPath,
  translationPath,
  activeCard,
  commonExclusionsExpetitions,
  commonNOTExclusionsExpetitions,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState([]);
  const isAdminInquiriesValue = isAdminInquiries();
  let filteredData = [];
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [AddToFavouriteDialog, setAddToFavouriteDialog] = useState(false);

  const commonExclusions = [
    "totalCount",
    "imageUrl",
    "shareUnitId",
    "unitOperationSource",
    commonExclusionsExpetitions,
  ];

  let exclusions = commonExclusions.concat(
    state.unitOperationSource !== 1
      ? ["selling_price", "listing_agent", "sale_type"]
      : ["rentPerMonth", "rentPerSq", "rentPerYear", "rent_listing_agent"]
  );

  filteredData = Object.entries(state).filter(
    ([key]) => !exclusions.includes(key)
  );

  const GetShareUnitDetailsAPI = useCallback(async (activeItem) => {
    setIsLoading(true);
    const res = await GetShareUnitDetails(
      activeItem.shareUnitId,
      activeItem.unitOperationSource,
      isAdminInquiriesValue
    );
    if (!(res && res.status && res.status !== 200)) {
      setIsLoading(false);
      setState(res);
    } else {
      setIsLoading(false);
      setState({});
    }
  }, []);

  useEffect(() => {
    if (activeItem) GetShareUnitDetailsAPI(activeItem);
  }, [activeItem]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options)
      .format(date)
      .replace(",", "");
  };
  const Operation = state.unitOperationSource || activeItem.unitOperationSource;

  return (
    <DialogComponent
      titleText={`${t(`${translationPath}DetailsOfUnit`)}${" "}${
        activeItem.unit_ref_no || ""
      }`}
      saveText="send_proposal"
      saveType="button"
      SmothMove
      maxWidth="md"
      dialogContent={
        <div className="d-flex-column-center folder-plus">
          <Spinner isActive={isLoading} isAbsolute />
          <div className="w-100">
            <div className="UnitDetails">
              <div className="d-flex-center mb-3">
                <LoadableImageComponant
                  classes="details-img-uniots"
                  alt={t(`${translationPath}contact-image`)}
                  src={
                    activeItem && activeItem.imageUrl
                      ? activeItem.imageUrl
                      : DefaultImagesEnum.corporate.defaultImg
                  }
                />
              </div>{" "}
              {activeItem?.unit_type || ""} {activeItem?.property_name || " "}
            </div>
            <span onClick={() => setAddToFavouriteDialog(true)} class="UnitDetails-AddFavourite">
              <span class="heart mdi mdi-heart"></span>
              {t(`${translationPath}add-to-Favourite`)}
            </span>
            <div className="data-container">
              {filteredData.map(([key, value]) => {
                let displayValue;

                if (key === "creationDate" && value !== null) {
                  displayValue = formatDate(value);
                } else if (value !== null) {
                  displayValue = t(`${translationPath}${value.toString()}`);
                } else {
                  displayValue = "N/A";
                }

                return (
                  (key !== "unitName" &&
                    Operation === 2 &&
                    key !== "listing_agent_phone" &&
                    key !== "listing_agent_email" &&
                    key !== "listing_agent_branch" && (
                      <div key={key} className="data-item">
                        <strong>
                          {t(`${translationPath}${key}`)}&nbsp;:&nbsp;
                        </strong>
                        &nbsp;
                        {(key !== "rent_listing_agent_phone" &&
                          key !== "rent_listing_agent_email" &&
                          key !== "unitName" &&
                          key === commonNOTExclusionsExpetitions &&
                          ((activeCard &&
                            activeCard[commonNOTExclusionsExpetitions]) ||
                            "")) ||
                          displayValue}
                        {(key === "rent_listing_agent_phone" ||
                          key === "rent_listing_agent_email") && (
                          <CopyToClipboardComponents
                            data={
                              key === "rent_listing_agent_phone"
                                ? state.rent_listing_agent_phone
                                : state.rent_listing_agent_email
                            }
                            childrenData={
                              key === "rent_listing_agent_phone"
                                ? state.rent_listing_agent_phone
                                : state.rent_listing_agent_email
                            }
                          />
                        )}
                      </div>
                    )) ||
                  (key !== "unitName" &&
                    Operation === 1 &&
                    key !== "rent_listing_agent_phone" &&
                    key !== "rent_listing_agent_email" &&
                    key !== "rent_listing_agent_branch" && (
                      <div key={key} className="data-item">
                        <strong>
                          {t(`${translationPath}${key}`)}&nbsp;:&nbsp;
                        </strong>
                        &nbsp;
                        {(key !== "listing_agent_phone" &&
                          key !== "listing_agent_email" &&
                          key !== "unitName" &&
                          key === commonNOTExclusionsExpetitions &&
                          ((activeCard &&
                            activeCard[commonNOTExclusionsExpetitions]) ||
                            "")) ||
                          displayValue}
                        {(key === "listing_agent_phone" ||
                          key === "listing_agent_email") && (
                          <CopyToClipboardComponents
                            data={
                              key === "listing_agent_phone"
                                ? state.listing_agent_phone
                                : state.listing_agent_email
                            }
                            childrenData={
                              key === "listing_agent_phone"
                                ? state.listing_agent_phone
                                : state.listing_agent_email
                            }
                          />
                        )}
                      </div>
                    ))
                );
              })}
            </div>
          </div>
          {isShareDialogOpen && (
            <ShareTemplateUnitDialog
              isOpenShareTemplateUnitDialog={isShareDialogOpen}
              unitId={activeItem.unitId || null}
              operationType={activeItem?.operation_type}
              closeHandler={() => {
                setIsShareDialogOpen(false);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          )}
          {AddToFavouriteDialog && (
            <AddToFavouriteShareUnitsDialog
              isOpen={AddToFavouriteDialog}
              checkedDetailed={[activeItem] || []}
              reest={() => {}}
              onSave={() => {}}
              parentTranslationPath={parentTranslationPath}
              translationPath=""
              closeHandler={() => {
                setAddToFavouriteDialog(false);
              }}
            />
          )}
        </div>
      }
      saveClasses="btns theme-solid bg-primary w-100 mx-2 mb-2"
      isOpen={isOpen}
      onSaveClicked={() => setIsShareDialogOpen(true)}
      onCloseClicked={() => onClose()}
      onCancelClicked={() => onClose()}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};

ShareUnitDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  activeItem: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
