import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { Spinner } from "../../../../../../Components";
import {
  GetParams,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../../Helper";
import { CommissionTypes } from "../../../../../../Enums";
import { UpdateCommissionDetailsAPI } from "../../../../../../Services";
import { FooterWrapperComponent } from "../../../DevelopersUtilities/Components/FooterWrapperComponent";
import { CommissionDetailsViewComponent } from "./CommissionDetailsViewComponent";

export const CommissionDetailsManagementDialog = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  commissionDetailsValues,
  contactReletedWithDeveloperId,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({
    editCommission: false,
    info: false,
  });
  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    valueFrom: 0,
    valueTo: null,
    commission: null,
    paymentTerms: null,
    commissionType: 1,
    remarks: null,
    commissionProjects: [],
  });
  const [defaultSelected, setDefaultSelected] = useState({
    commissionType: null,
    commissionProjects: [],
  });
  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });
  const schema = Joi.object({
    commissionType: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}commission-type-is-required`),
        "number.empty": t(`${translationPath}commission-type-is-required`),
        "number.required": t(`${translationPath}commission-type-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };
  const UpdateCommissionDetailsHandler = async () => {
    setIsLoading((item) => ({ ...item, editCommission: true }));
    const body = { ...state };
    if (
      state.commissionType === CommissionTypes.specificProjects.key &&
      state.commissionProjects &&
      !state.commissionProjects.length
    ) {
      setIsLoading((item) => ({ ...item, editCommission: false }));
      showWarn(
        t`${translationPath}this-contact-developer-havent-master-developer-on-any-properties-please-select-properties-and-select-master-developer-with-this-contact-contact-id` +
          `${contactReletedWithDeveloperId}`
      );
      return;
    }
    const res = await UpdateCommissionDetailsAPI(
      state?.commissionDetailId,
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}commission-details-updated-successfully`);
      onSave(true);
    } else {
      showError(t`${translationPath}commission-details-update-failed`);
      setIsLoading((item) => ({ ...item, editCommission: false }));
    }
  };

  useEffect(() => {
    if (commissionDetailsValues) {
      setIsLoading((item) => ({ ...item, info: true }));
      const updateState = {
        commission: commissionDetailsValues?.commission,
        commissionDetailId: commissionDetailsValues?.commissionDetailId,
        commissionType: commissionDetailsValues?.commissionTypeId,
        valueFrom: commissionDetailsValues?.valueFrom,
        valueTo: commissionDetailsValues?.valueTo,
        commission: commissionDetailsValues?.commission,
        commissionProjects: commissionDetailsValues?.commissionProjects,
        paymentTerms: commissionDetailsValues?.paymentTerms,
        remarks: commissionDetailsValues?.remarks,
        developerContactId:
          commissionDetailsValues?.developerContactId || +GetParams("id"),
      };
      const updateSelected = {
        commissionType: commissionDetailsValues.commissionType
          ? {
              key: commissionDetailsValues.commissionTypeId,
              name: commissionDetailsValues.commissionType,
            }
          : null,
        commissionProjects: commissionDetailsValues.commissionProjects
          ? commissionDetailsValues.commissionProjects
          : [],
        createdBy: commissionDetailsValues?.createdBy,
        createdOn: commissionDetailsValues?.createdOn,
      };
      setState({ id: "edit", value: updateState });
      setSelected({ id: "edit", value: updateSelected });
      setIsLoading((item) => ({ ...item, info: false }));
    }
  }, [commissionDetailsValues]);

  return (
    <div>
      <Spinner
        isActive={isLoading.editCommission || isLoading.info}
        isAbsolute
      />
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-commission-details`)}</span>
        </DialogTitle>
        <DialogContent className="dialog-content-wrapper">
          <CommissionDetailsViewComponent
            state={state}
            schema={schema}
            selected={selected}
            onChangeHandler={onChangeHandler}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            className={"dialog-content-item"}
            contactReletedWithDeveloperId={contactReletedWithDeveloperId}
            editForm
          />
        </DialogContent>
        <DialogActions>
          <FooterWrapperComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onClose={onClose}
            onSave={() => {
              UpdateCommissionDetailsHandler();
            }}
            disabled={!state.commissionType || isLoading.editCommission}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
