import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    actionsContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "16px",
    },
    mergeActionWrapper: {
      display: "flex",
      gap: "12px",
    },
    selectedActionWrapper: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    selectedCount: {
      color: theme.palette.text.secondary,
      fontWeight: 500,
      fontSize: "18px",
    },
    viewModeWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    criteriaFiltersWrapper: {
      marginBottom: "24px",
      display: "flex",
      alignItems: "center",
      gap: "16px",
    },
    gridContainer: {
      display: "flex",
      flex: "1 1 100%",
      flexWrap: "wrap",
      gap: "18px",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    filteredItemWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "12px",

      "& span": {
        fontSize: "16px",
        fontWeight: 500,
        color: theme.palette.text.secondary,
      },
    },
    defaultAvatar: {
      backgroundColor: "#F2F4F7", //avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "40px",
      width: "40px",

      "& img": {
        height: "24px",
        width: "24px",
      },
    },
    avatar: {
      backgroundColor: "#F2F4F7", //avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "40px",
      width: "40px",

      "& img": {
        objectFit: "contain",
      },
    },
    container: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    detailsColumn: {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
    },
    nameRow: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
      cursor: "pointer",
    },
    socialLinksBox: {
      height: "28px",
    },
    nameText: (props) => ({
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: 500,
      color: props?.isDarkMode ? "#F5F5F6" : "#101828",
    }),
  };
});
