import React from "react";

function INDDInDesignIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <rect x="1" y="18" width="31" height="16" rx="2" fill="#BA24D5" />
      <path
        d="M6.31623 22.7273V30H4.77859V22.7273H6.31623ZM13.6644 22.7273V30H12.3363L9.17223 25.4226H9.11896V30H7.58132V22.7273H8.93075L12.07 27.3011H12.1339V22.7273H13.6644ZM17.513 30H14.9348V22.7273H17.5343C18.2658 22.7273 18.8955 22.8729 19.4235 23.1641C19.9514 23.4529 20.3574 23.8684 20.6415 24.4105C20.928 24.9527 21.0712 25.6013 21.0712 26.3565C21.0712 27.1141 20.928 27.7652 20.6415 28.3097C20.3574 28.8542 19.949 29.272 19.4164 29.5632C18.8861 29.8544 18.2516 30 17.513 30ZM16.4725 28.6825H17.449C17.9036 28.6825 18.2859 28.602 18.5961 28.4411C18.9086 28.2777 19.1429 28.0256 19.2992 27.6847C19.4578 27.3414 19.5371 26.8987 19.5371 26.3565C19.5371 25.8191 19.4578 25.38 19.2992 25.0391C19.1429 24.6982 18.9097 24.4472 18.5996 24.2862C18.2895 24.1252 17.9071 24.0447 17.4526 24.0447H16.4725V28.6825ZM24.7884 30H22.2102V22.7273H24.8097C25.5412 22.7273 26.1709 22.8729 26.6989 23.1641C27.2268 23.4529 27.6328 23.8684 27.9169 24.4105C28.2034 24.9527 28.3466 25.6013 28.3466 26.3565C28.3466 27.1141 28.2034 27.7652 27.9169 28.3097C27.6328 28.8542 27.2244 29.272 26.6918 29.5632C26.1615 29.8544 25.527 30 24.7884 30ZM23.7479 28.6825H24.7244C25.179 28.6825 25.5613 28.602 25.8714 28.4411C26.1839 28.2777 26.4183 28.0256 26.5746 27.6847C26.7332 27.3414 26.8125 26.8987 26.8125 26.3565C26.8125 25.8191 26.7332 25.38 26.5746 25.0391C26.4183 24.6982 26.1851 24.4472 25.875 24.2862C25.5649 24.1252 25.1825 24.0447 24.728 24.0447H23.7479V28.6825Z"
        fill="white"
      />
    </svg>
  );
}

export default INDDInDesignIcon;
