/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Styles from "../../LeadsSalesView/LeadsSalesProfileManagementView/Sections/LeadInquiriesView/LeadInquiriesView.module.scss";
import MatchedUnitCard from '../../LeadsSalesView/LeadsSalesProfileManagementView/Sections/LeadInquiriesView/Utilities/MatchedUnitCard/MatchedUnitCard';
import { ActiveInquiryViewsEnum } from '../../LeadsSalesView/LeadsSalesProfileManagementView/Sections/LeadInquiriesView/ActiveInquiryViewsEnum';
import MatchedUnitOverview from '../../LeadsSalesView/LeadsSalesProfileManagementView/Sections/LeadInquiriesView/MatchedUnitOverview/MatchedUnitOverview';

export const AssignUnitCards = ({
  data,
  parentTranslationPath,
  translationPath,

}) => {

  const [activeView, setActiveView] = useState(ActiveInquiryViewsEnum.MatchedUnit);
  const [activeUnit, setActiveUnit] = useState(null);

  const viewChangeHandler = useCallback(({ view, unit }) => {
    setActiveView(view);
    setActiveUnit(unit || null)
  }, []);

  return (
    <>
      <div className={`${Styles.Heading_box} mx-3 mb-4`}>
        <div className={Styles.Heading}>
          <h3 onClick={() => viewChangeHandler({ view: ActiveInquiryViewsEnum.MatchedUnit })}>Matched Units</h3>
          {activeView == ActiveInquiryViewsEnum.UnitOverview ? (
            <>
              <span className={`mdi mdi-page-last mt-1 mx-2`}></span>
              <h4><span>{activeUnit?.propertyName || null}</span>
                <span className="mx-2">{activeUnit?.unitRefNo ? `( ${activeUnit.unitRefNo} )` : "Overview"}</span>
              </h4>
            </>
          ) : null}
        </div>
        <div className="mt-2">
          {activeView == ActiveInquiryViewsEnum.MatchedUnit ? (<p> Browse the matching units based on the client's preferences.
            Use this page to <b>mark units as shortlisted</b> or <b>send proposals</b>.</p>
          ) : null}
        </div>
      </div>
      {activeView === ActiveInquiryViewsEnum.MatchedUnit ? (
        <div className='units-cards-wrapper d-flex m-3 flex-wrap gap-16'>
          {data.result &&
            data.result.map(item => (item ?
              <MatchedUnitCard
                key={item.inquiryId}
                data={item}
                actionButtons={{
                  sendPropsal: true,
                  openOverView: true,
                  viewUnit: true,
                }}
                viewUnitHandler={() => viewChangeHandler({ view: ActiveInquiryViewsEnum.UnitOverview, unit: item })}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              /> : null

            ))}
        </div>) : null}
      {activeView === ActiveInquiryViewsEnum.UnitOverview ? (
        <div style={{
          width: "95%",
          margin: "auto"
        }}>
          <MatchedUnitOverview 
          actionButtons={{
            sendPropsal: true,
          }}
          unitId={activeUnit?.unitId} />
        </div>
      ) : null}
    </>

  );
};

AssignUnitCards.propTypes = {
  data: PropTypes.shape({ result: PropTypes.instanceOf(Array), totalCount: PropTypes.number })
    .isRequired,
};
