import React from "react";

function Brush03({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.686 1.041c-.616.09-1.199.51-1.464 1.052-.223.456-.221.387-.221 5.207-.001 4.268.015 4.985.123 5.54a3.758 3.758 0 0 0 1.104 2.043C5.115 15.721 6.025 16 7.867 16h.606l.017 1.99c.019 2.147.024 2.194.259 2.798C9.261 22.096 10.589 23 12 23c1.411 0 2.739-.904 3.251-2.212.235-.604.24-.651.259-2.798l.017-1.99h.606c1.842 0 2.752-.279 3.639-1.117a3.758 3.758 0 0 0 1.104-2.043c.108-.555.124-1.272.123-5.54 0-4.82.002-4.751-.221-5.207-.211-.433-.637-.798-1.143-.981l-.255-.092-7.24-.005c-3.982-.003-7.336.008-7.454.026M19 6v3H5V3h14v3m-.018 5.61c-.048 1.045-.149 1.358-.582 1.791-.299.3-.58.45-.982.527-.407.076-10.429.076-10.836-.001a1.69 1.69 0 0 1-.982-.524c-.433-.437-.534-.748-.582-1.793L4.99 11h14.02l-.028.61m-5.502 6.288c0 1.823-.003 1.908-.083 2.121-.155.415-.544.794-.935.911a2.11 2.11 0 0 1-.462.048 2.11 2.11 0 0 1-.462-.048c-.391-.117-.78-.496-.935-.911-.08-.213-.083-.298-.083-2.121V16h2.96v1.898"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default Brush03;
