import React from "react";
import {
  FolderIcon,
  VedioIcon,
  AudioIcon,
  SpreadsheetIcon,
  IMGIcon,
  JPEGIcon,
  PNGIcon,
  WebpIcon,
  TIFFIcon,
  GIFIcon,
  SVGIcon,
  DOCXIcon,
  TXTIcon,
  CSVIcon,
  XLSXIcon,
  PPTIcon,
  FIGFigmaIcon,
  PSDPhotoshopIcon,
  INDDInDesignIcon,
  AEPAfterEffectsIcon,
  MP3Icon,
  WAVIcon,
  MP4Icon,
  MPEGIcon,
  AVIIcon,
  MKVIcon,
  HTMLIcon,
  CSSIcon,
  SQLIcon,
  JSIcon,
  JAVAIcon,
  EXEIcon,
  DMGIcon,
  ZIPIcon,
  RARIcon,
  EmptyIcon,
  PdfIcon,
} from "../../../../../../assets/upload-icons";

export const GetFileTypeIcon = (fileType) => {
  if (!fileType) {
    return <FolderIcon />;
  }
  switch (fileType !== null) {
    // Image Types
    case fileType.startsWith("image/"):
      return <IMGIcon />;
    case fileType.includes("jpeg"):
      return <JPEGIcon />;
    case fileType.includes("png"):
      return <PNGIcon />;
    case fileType.includes("gif"):
      return <GIFIcon />;
    case fileType.includes("webp"):
      return <WebpIcon />;
    case fileType.includes("svg"):
      return <SVGIcon />;
    case fileType.includes("tiff"):
      return <TIFFIcon />;

    // Video Types
    case fileType.startsWith("video/"):
      return <VedioIcon />;
    case fileType.includes("mp4"):
      return <MP4Icon />;
    case fileType.includes("mpeg"):
      return <MPEGIcon />;
    case fileType.includes("avi"):
      return <AVIIcon />;
    case fileType.includes("mkv"):
      return <MKVIcon />;

    // Audio Types
    case fileType.startsWith("audio/"):
      return <AudioIcon />;
    case fileType.includes("mp3"):
      return <MP3Icon />;
    case fileType.includes("wav"):
      return <WAVIcon />;

    // Document Types
    case fileType === "application/pdf":
      return <PdfIcon />;
    case fileType.includes("msword") || fileType.includes("wordprocessingml"):
      return <DOCXIcon />;
    case fileType.includes("ms-excel") || fileType.includes("spreadsheetml"):
      return <SpreadsheetIcon />;
    case fileType.includes("text/plain"):
      return <TXTIcon />;
    case fileType.includes("csv"):
      return <CSVIcon />;
    case fileType.includes("ppt") || fileType.includes("presentationml"):
      return <PPTIcon />;
    case fileType.includes("figma"):
      return <FIGFigmaIcon />;
    case fileType.includes("photoshop"):
      return <PSDPhotoshopIcon />;
    case fileType.includes("indesign"):
      return <INDDInDesignIcon />;
    case fileType.includes("aftereffects"):
      return <AEPAfterEffectsIcon />;
    case fileType.includes("xlsx"):
      return <XLSXIcon />;

    // Code Files
    case fileType.includes("html"):
      return <HTMLIcon />;
    case fileType.includes("css"):
      return <CSSIcon />;
    case fileType.includes("javascript") || fileType.includes("json"):
      return <JSIcon />;
    case fileType.includes("xml") || fileType.includes("java"):
      return <JAVAIcon />;
    case fileType.includes("sql"):
      return <SQLIcon />;

    // Compressed Files
    case fileType.includes("zip"):
      return <ZIPIcon />;
    case fileType.includes("rar"):
      return <RARIcon />;

    // Executable or Installer Files
    case fileType.includes("msdownload"):
      return <EXEIcon />;
    case fileType.includes("x-dmg"):
      return <DMGIcon />;

    // Default Icon
    default:
      return <EmptyIcon />;
  }
};
export default GetFileTypeIcon;
