import React, { useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { FormTablesGenerator } from "../Utilities/FormTablesGenerator/FormTablesGenerator";
import { IndividualKycFormMap } from "./IndividualKycFormMap";
import { GetLookupItemsByLookupTypeName } from "../../../../../../../../../../Services";
import { IndividualKycFormText } from "./IndividualKycFormText";

export const IndividualForm = ({
  clientFormRef,
  selected,
  onSelectedChange,
  clientFormMap,
}) => {

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    Salutation: [],
    SourceOfFund: [],
    MethodOfPayment: [],
    Country: [],
    CustomerType: [],
    ResidenceStatus: [],
    Yes_No: [
      { value: true, key: "Yes" },
      { value: false, key: "No" },
    ],
    Gender: [
      { value: "Male", key: "Male" },
      { value: "Female", key: "Female" },
    ],
  });
  
  const getMappedLookupData = (result, lookUpName) => {
    let mappedResult = result || [];

    if( lookUpName == "CustomerType" ) mappedResult = result.filter(item => item.lookupItemName == "Salaried" 
      || item.lookupItemName == "Self Employed" );
    
    return mappedResult;
  }
  

  const getLookupsByName = async (lookUpName, dataKey) => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 150,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)){
      setData({ id: dataKey, value: getMappedLookupData(res?.result, lookUpName) });
    }
    else setData({ id: dataKey, value: [] });
  };


  const getSalutation = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Salutation",
      pageSize: 150,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)){
      const mappedOptions = res?.result && 
              res?.result.map(item => ({ value: item.lookupItemName, key: item.lookupItemName }));
      setData({ id: "Salutation", value: mappedOptions || [] });
    }
    else setData({ id: "Salutation", value: [] });
  };


  useEffect(() => {
    getSalutation();
  }, []);


  return (
    <>
      <FormTablesGenerator
        formMap={IndividualKycFormMap}
        formText={IndividualKycFormText}
        selected={selected}
        optionsData={data}
        onSelectedChange={onSelectedChange}
        getLookupsByName={getLookupsByName}
        isIndividual={true}
      />
      <div className="client-form" ref={clientFormRef} >
        <FormTablesGenerator
          formMap={clientFormMap}
          formText={IndividualKycFormText}
          selected={selected}
          optionsData={data}
          isClientForm
          isIndividual={true}
        />
      </div>
    </>
  );
};

IndividualForm.propTypes = {
  clientFormMap: PropTypes.object,
  KycFormDetails: PropTypes.object,
};
IndividualForm.defaultProps = {
  clientFormMap: {}
};
