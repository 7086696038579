import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerCategorySection: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "16px",
      gap: "32px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    TitleItem: {
      minWidth: "100px",
      display: "flex",
      alignItems: "flex-start",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
    containerAttatchmetSection: {
      display: "flex",
      flexDirection: "row",
      marginTop: "16px",
      gap: "32px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    LinkOverviewTable: {
      display: "flex",
      flexDirection: "column",
    },
    container_Link_Section: {
      display: "flex",
      flexDirection: "row",
      marginTop: "16px",
      marginBottom: "16px",
      gap: "32px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    inputFullWidth: {
      width: "100% !important",
      maxWidth: "100% !important",
      [theme.breakpoints.down("xs")]: {
        width: "100% !important",
      },
    },
    containerInput: {
      width: "100%",
    },
    containerSectionFiledLink: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "flex-start",
      gap: "16px",
    },
    MoreLinkesContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "0px 0px 16px 0px",
    },
    HeaderTableSection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: theme.palette.background.hover,
      padding: "12px 24px",
    },
    TitleItemTable: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
    BodyTableSection: {
      display: "flex",
      flexDirection: "column",
    },
    rowMoreLinkes: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "16px 24px",
      borderBottom: `1px solid ${theme.palette.utility.utility_gray_200}`,
    },
    uploadedSection: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "16px",
    },
    containerUpladedFilesCrads: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    CardUploadedFilesInfo: {
      display: "flex",
      flexDirection: "row",
      padding: "16px",
      gap: "12px",
    },
    ContainerFileUploadedInfo: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    containerTitleAndDeleteIcon: {
      position: "relative",
    },
    DeleteIcon: {
      cursor: "pointer",
      position: "absolute",
      right: "0px",
      top: "0px",
    },
    TitleFileName: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "330px",
      display: "inline-block",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
    },
    FileSizeTitle: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
    HyperLinkItem: {
      color: theme.palette.text.tertiary,
      fontSize:'14px',
      fontWeight:'400',
      lineHeight:'20px',
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.text.tertiary,
        fontSize:'14px',
        fontWeight:'400',
        lineHeight:'20px'
      },
    },
  };
});
