import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../../../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);
  return {
    contactSideMenu: {
      direction: "ltr",
      position: "fixed",
      top: 0,
      ...(isAr ? { left: 0 } : { right: 0 }),
      width: "400px",
      height: "100%",
      backgroundColor: theme.palette.background.primary,
      overflowY: "auto",
      transition: "transform 0.3s ease-in-out",
      boxShadow: theme.shadows[7],
      padding: "24px",
      borderRight: `1px solid ${theme.palette.border.primary}`,
      borderLeft: `1px solid ${theme.palette.border.primary}`,
      borderRadius: theme.borderRadius[0],
      zIndex: 10,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    hidden: {
      transform: isAr ? "translateX(-100%)" : "translateX(100%)",
      visibility: "hidden",
      opacity: 0,
    },
    headingContainer: {
      display: "flex",
      flexDirection: "column",

      "& span": {
        color: theme.palette.text.tertiary,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
      },
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    dropdownWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      marginTop: "24px",
    },
    actions: {
      borderTop: `1px solid ${theme.palette.border.secondary}`,
      display: "flex",
      padding: "16px 24px",
      justifyContent: "flex-end",
      position: "fixed",
      bottom: 0,
      width: "400px",
      marginLeft: "-24px",
      background: theme.palette.background.paper,

      "& div": { display: "flex", gap: "12px" },
    },
    itemsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      paddingBottom: "24px",
      marginTop: "16px",
      maxHeight: "calc(100% - 212px)",
      overflow: "hidden",
      overflowY: "scroll",
    },
    filteredItemWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      padding: "4px 0 0 8px",

      "& span": {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  };
});
