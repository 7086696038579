import React, { useState, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@material-ui/core";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";

// Styles
import "./BasicTable.scss";

const BasicTable = ({
  columns,
  rowsData,
  setActiveItem,
  setActiveItemOnRowClick,
  tableActions,
  rowHeight = 100, // Default rowHeight
  borderRadius = "12px", // Default borderRadius
}) => {
  const [anchorPosition, setAnchorPosition] = useState({ x: 0, y: 0 });
  const { tablePopoverContent, setTablePopoverContent } = useVerticalNav();
  const { actionableItems, setActionableItems } =
    useContext(ContactLayoutContext);

  const handleOnRowClick = (params, event) => {
    if (Boolean(setActiveItemOnRowClick)) {
      setActiveItemOnRowClick(params?.row);
    }

    if (tableActions) {
      const rect = event.currentTarget.getBoundingClientRect();
      const y = rect.top + window.scrollY; // Vertical position based on the row's position

      setAnchorPosition({ y });
      setTablePopoverContent(params.row);
    }

    const slider = document.getElementsByClassName("MuiDataGrid-window")[0];

    if (!slider) return;

    let mouseDown = false;
    let startX;
    let scrollLeft;

    const startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const stopDragging = () => {
      mouseDown = false;
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", stopDragging);
      window.removeEventListener("mouseleave", stopDragging);
    };

    const handleMouseMove = (e) => {
      if (!mouseDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    };

    slider.addEventListener("mousedown", startDragging);
    window.addEventListener("mouseup", stopDragging);
    window.addEventListener("mouseleave", stopDragging);
    window.addEventListener("mousemove", handleMouseMove);

    // Clean up the event listeners
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", stopDragging);
      window.removeEventListener("mouseleave", stopDragging);
    };
  };

  const handleOnRowDoubleClick = (params) => {
    setTablePopoverContent(null);
    setActiveItem(params?.row);
  };

  const handleOnCellEnter = (params, event) => {
    if (params.field === "leadTypes" && params.formattedValue === "1,2,3,4") {
      event.target.classList.add("expanded-cell");
    }
  };

  const handleOnCellLeave = (params, event) => {
    if (params.field === "leadTypes" && params.formattedValue === "1,2,3,4") {
      event.target.classList.remove("expanded-cell");
    }
  };

  // Handle the checkbox selection event to update selectedItems
  const handleSelectionChange = (newSelectionModel) => {
    setActionableItems((prevState) => ({
      ...prevState,
      selectedIds: newSelectionModel,
    }));
  };

  const checkIsContactRowSelectable = (isWithTransaction, id) => {
    if (
      (actionableItems?.action === "merge" &&
        !(
          // Disable if length > 10 and not already selected
          (
            isWithTransaction === 1 ||
            (actionableItems?.selectedIds?.length >= 10 &&
              !actionableItems?.selectedIds?.includes(id))
          )
        )) ||
      actionableItems?.action === "bulk_update" ||
      actionableItems?.action === "pull"
    ) {
      return true;
    }

    return false;
  };

  const checkIsLeadRowSelectable = (leadClass) => {
    if (actionableItems?.selectedIds?.length > 0) {
      const item = rowsData?.find(
        (item) =>
          item.leadId ===
          actionableItems?.selectedIds[actionableItems?.selectedIds?.length - 1]
      );

      if (
        item?.leadClass !== leadClass &&
        actionableItems?.action !== "close-leads"
      ) {
        return false;
      }
    }

    return true;
  };

  return (
    <div
      style={{
        height: Math.min(rowsData?.length * rowHeight + 75, 800),
        width: "100%",
        overflowX: "auto",
        cursor: "grab",
        position: "relative",
      }}
    >
      <StyledDataGrid
        isRowSelectable={(params) => {
          if (params?.row?.leadId) {
            // lead element
            return checkIsLeadRowSelectable(params?.row?.leadClass);
          } else if (params?.row?.contactId) {
            // contact element
            return checkIsContactRowSelectable(
              params?.row?.isWithTransaction,
              params?.row?.id || params?.row?.contactId
            );
          } else {
            return true; // unit element || others elements
          }
        }}
        onSelectionModelChange={handleSelectionChange}
        checkboxSelection={actionableItems?.action}
        onCellClick={(params, event) => {
          if (params.field === "fullName") event.stopPropagation();
        }}
        style={{ direction: "ltr" }}
        rowHeight={rowHeight}
        onRowClick={handleOnRowClick}
        onRowDoubleClick={handleOnRowDoubleClick}
        onCellLeave={handleOnCellLeave}
        onCellEnter={handleOnCellEnter}
        rows={rowsData || []}
        columns={columns || []}
        hideFooterPagination={true}
        hideFooter={true}
        disableSelectionOnClick
        borderRadius={borderRadius}
      />
      {tableActions &&
        tablePopoverContent &&
        tableActions({
          anchorPosition,
        })}
    </div>
  );
};

const customCheckbox = (theme) => ({
  "& .MuiCheckbox-root svg": {
    width: 20,
    height: 20,
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.border.border_disabled}`,
    borderRadius: 6,
  },
  "& .MuiCheckbox-root svg path": {
    display: "none",
  },
  "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
    border: "none",
    background: theme.palette.background.brand_solid,
  },
  "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
    position: "absolute",
    display: "table",
    border: "2px solid #fff",
    borderTop: 0,
    borderLeft: 0,
    transform: "rotate(45deg) translate(-50%,-50%)",
    opacity: 1,
    transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
    content: '""',
    top: "50%",
    left: "39%",
    width: 5,
    height: 9,
    padding: 0,
  },
  "& .MuiCheckbox-root.Mui-checked:hover": {
    boxShadow: "none",
  },
  "& .MuiIconButton-colorPrimary:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiCheckbox-root.Mui-checked:not :hover": {
    boxShadow: "none",
  },
  "& .MuiDataGrid-cell": {
    lineHeight: "24px !important",
  },

  "& .MuiCheckbox-root.Mui-disabled .MuiIconButton-label svg": {
    border: `1px solid ${theme.palette.border.border_disabled}`,
    background: theme.palette.background.disabled,
  },

  "& .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-root": {
    display: "none",
  },
});

const StyledDataGrid = styled(DataGrid)(({ theme, borderRadius }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  borderRadius: borderRadius,
  padding: "2px",
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontFamily: "Inter",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.background.secondary,
    lineHeight: "20px",
    color: theme.palette.text.tertiary_hover,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: theme.palette.text.tertiary_hover,
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnsContainer": {
    maxHeight: "44px !important",
    minHeight: "44px !important",
    lineHeight: "44px !important",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  "& .MuiTablePagination-input": {
    display: "block !important",
  },
  ...customCheckbox(theme),
}));

export default BasicTable;
