import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import PropTypes from "prop-types";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { Spinner } from "../../../../../../Components";
import { showSuccess, showError, GetParams } from "../../../../../../Helper";
import { UpdateDeveloperEmployeeAPI } from "../../../../../../Services";
import { FooterWrapperComponent } from "../../../DevelopersUtilities/Components";
import { DeveloperEmployeesManagementComponent } from "../DeveloperEmployeesComponents";

export const DeveloperEmployeesManagementDialog = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  developerEmployeesValues,
  communicationsIdsValues,
  isReloadData,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCheckIsValidValues, setIsCheckIsValidValues] = useState(false);
  const [isLoading, setIsLoading] = useState({
    editDeveloperEmployee: false,
    infoDeveloperEmployee: false,
  });
  const [isCanSave, setIsCanSave] = useState({
    vaildEmail: false,
    validMobile: false,
  });
  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    employeeName: null,
    jobTitle: null,
    linkedInProfile: null,
    employeePhoto: null,
    files: null,
  });

  const [defaultSelected, setDefaultSelected] = useState({
    employeePhoto: null,
    emailAddresses: [],
    mobileNumbers: [],
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const schema = Joi.object({
    employeeName: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}employee-name-is-required`),
        "string.empty": t(`${translationPath}employee-name-is-required`),
        "string.required": t(`${translationPath}employee-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };

  const SaveUpdateDeveloperEmployeeHandler = async () => {
    setIsLoading((item) => ({ ...item, editDeveloperEmployee: true }));
    const body = {
      ...state,
      developerEmployeeCommunications: [
        ...selected.emailAddresses,
        ...selected.mobileNumbers,
      ],
      employeePhoto:
        (state?.files && state.files.length > 0 && state.files[0].fileId) ||
        null,
    };
    if (state.files) delete body.files;
    const res = await UpdateDeveloperEmployeeAPI(
      developerEmployeesValues?.employeeId,
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}developer-employee-updated-successfully`);
      setIsLoading((item) => ({ ...item, editDeveloperEmployee: false }));
      onSave(res);
    } else {
      showError(t`${translationPath}developer-employee-update-failed`);
      setIsLoading((item) => ({ ...item, editDeveloperEmployee: false }));
    }
  };

  useEffect(() => {
    if (developerEmployeesValues) {
      setIsLoading((item) => ({ ...item, info: true }));
      const updateState = {
        employeeId: developerEmployeesValues?.employeeId,
        employeeName: developerEmployeesValues?.employeeName,
        jobTitle: developerEmployeesValues?.jobTitle,
        developerContactId: developerEmployeesValues?.developerContactId,
        linkedInProfile: developerEmployeesValues.linkedInProfile,
        employeePhoto: developerEmployeesValues.employeePhoto,
        files: developerEmployeesValues.employeePhoto
          ? [
              {
                uuid: developerEmployeesValues.employeePhoto,
                isUploaded: true,
                fileName: `Employee Photo ${developerEmployeesValues.employeeId}.png`,
                fileId: developerEmployeesValues.employeePhoto,
              },
            ]
          : [],
      };
      const updateSelected = {
        emailAddresses:
          developerEmployeesValues.developerEmployeeCommunications &&
          developerEmployeesValues.developerEmployeeCommunications.filter(
            (e) => +e.communicationTypeId === +communicationsIdsValues?.email
          ),
        mobileNumbers:
          developerEmployeesValues.developerEmployeeCommunications &&
          developerEmployeesValues.developerEmployeeCommunications.filter(
            (e) => +(e.communicationTypeId === +communicationsIdsValues?.mobile)
          ),
        createdBy: developerEmployeesValues?.createdBy,
        createdOn: developerEmployeesValues?.createdOn,
      };
      setState({ id: "edit", value: updateState });
      setSelected({ id: "edit", value: updateSelected });
      setIsLoading((item) => ({ ...item, info: false }));
    }
  }, [developerEmployeesValues]);

  return (
    <div>
      <Spinner
        isActive={isLoading.editDeveloperEmployee || isLoading.info}
        isAbsolute
      />
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-developer-employee`)}</span>
        </DialogTitle>
        <DialogContent className="dialog-content-wrapper">
          <DeveloperEmployeesManagementComponent
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            state={state}
            setState={setState}
            onChangeHandler={onChangeHandler}
            editForm={true}
            className={"dialog-content-item"}
            selected={selected}
            setSelected={setSelected}
            key={"save"}
            schema={schema}
            isReloadData={isReloadData}
            communicationsIdsValues={communicationsIdsValues}
            setIsCheckIsValidValues={setIsCheckIsValidValues}
            setIsCanSave={setIsCanSave}
          />
        </DialogContent>
        <DialogActions>
          <FooterWrapperComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onClose={onClose}
            onSave={() => {
              SaveUpdateDeveloperEmployeeHandler();
            }}
            disabled={
              isLoading.editDeveloperEmployee ||
              isCheckIsValidValues ||
              state.employeeName === "" ||
              !state.employeeName ||
              isCanSave.vaildEmail ||
              isCanSave.validMobile
            }
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeveloperEmployeesManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.instanceOf(Object),
};
DeveloperEmployeesManagementDialog.defaultProps = {
  isLoading: undefined,
};
