
import { LeadsAdvanceSearchTest, GetAllPropertyUnitModelsAPI , GetAllPropertyUnitTypeAPI  , lookupItemsGetId } from '../Services';
import { UnitTypes  , UnitsOperationTypeEnum} from "../Enums";
let oldvalue = "";
let timer = null;
var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const LeadsRule = async (item, value, setRerender, type, loginResponse) => {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== "Leads") return;
  if (item.value === "") return;
  if (value === "") return;
  if (timer !== null) clearTimeout(timer);
  if (oldvalue === value) return;
  oldvalue = value;

  let isLeadId = value? !isNaN(value) : false;
  let isValidEmail = value? value.match(validEmailRegex) : false;


  let criteria = {
    "lead_type_id": [{ searchType: 1, value: 2 }],
    "status.lookupItemName": [{ searchType: 1, value: "open" }],
  }

  if(loginResponse?.userId) criteria.referredtoid = [{ searchType: 1, value: loginResponse?.userId}];
  
  if(isValidEmail) criteria.All = [{ value }];
  else if(isLeadId) criteria.Ids = [{ searchType: 1, value }];
  else criteria.contact_name = [{ searchType: 2, value }];


  const body = {
    criteria,
    orderBy: 2,
    filterBy: "createdOn",
    OperationType: type === "1" || type === "3" ? "Sale" : "Lease",
  };

  timer = setTimeout(async () => {
    const rs = await LeadsAdvanceSearchTest(
      { pageIndex: 0, pageSize: 10 },
      body
    );
    item.data.enum = [];
    if (!rs || !rs.result) return;

    rs.result.map((itemValue) => {
      const leadJson = JSON.parse(itemValue.leadJson);
      item.data.enum.push({
        leadId: itemValue.leadId,
        name:
          leadJson &&
          leadJson.lead &&
          leadJson.lead.contact_name &&
          leadJson.lead.contact_name.name,
        leadType: itemValue.leadsType,
        leadClass:
          (leadJson && leadJson.lead && leadJson.lead.leadClass) || null,
        budgetFrom:
          (leadJson && leadJson.lead && leadJson.lead.budgetFrom) || null,
        budgetTo: (leadJson && leadJson.lead && leadJson.lead.budgetTo) || null,
        bathrooms:
          (leadJson.lead &&
            leadJson.lead.bathrooms &&
            leadJson.lead.bathrooms.length > 0 &&
            leadJson.lead.bathrooms) ||
          null,
        bedrooms:
          (leadJson.lead &&
            leadJson.lead.bedrooms &&
            leadJson.lead.bedrooms.length > 0 &&
            leadJson.lead.bedrooms) ||
          null,
        sizeAreaFrom: (leadJson.lead && leadJson.lead.sizeAreaFrom) || null,
        sizeAreaTo: (leadJson.lead && leadJson.lead.sizeAreaTo) || null,
        community: (leadJson.lead && leadJson.lead.community) || null,
        property: (leadJson.lead && leadJson.lead.property_name) || null,
        primaryView: (leadJson.lead && leadJson.lead.view) || null,
        unitType: (leadJson.lead && leadJson.lead.propertyunit_type) || null,
        sub_community: (leadJson.lead && leadJson.lead.sub_community) || null,
        leadStage: (leadJson.lead && leadJson.lead.lead_stage) || null,
        mobile:
          leadJson &&
          leadJson.lead &&
          leadJson.lead.contact_name &&
          leadJson.lead.contact_name.mobile || null,
      });
    });

    setRerender(Math.random());
  }, 500);
};

export const LeadsDefaultRule = async (item, setRerender, type, loginResponse) => {
  if (item.data.searchKey !== "Leads") return;
  if (item.data.enum) return;

  let criteria = {
    "lead_type_id": [{ searchType: 2, value: 2 }],
    "status.lookupItemName": [{ searchType: 1, value: "open" }],
  };

  if(loginResponse?.userId) criteria.referredtoid = [{ searchType: 1, value: loginResponse?.userId}];

  const body = {
    criteria,
    orderBy: 2,
    filterBy: "createdOn",
    OperationType: type === "1" || type === "3" ? "Sale" : "Lease",
  };

  const rs = await LeadsAdvanceSearchTest({ pageIndex: 0, pageSize: 10 }, body);

  item.data.enum = [];
  if (!rs || !rs.result) return;
  rs.result.map((value) => {
    const leadJson = JSON.parse(value.leadJson);
    item.data.enum.push({
      leadId: value.leadId,
      name:
        leadJson.lead &&
        leadJson.lead.contact_name &&
        leadJson.lead.contact_name.name,
      leadType: value.leadsType,
      leadClass: leadJson.lead && leadJson.lead.leadClass,
      budgetFrom:
        (leadJson && leadJson.lead && leadJson.lead.budgetFrom) || null,
      budgetTo: (leadJson && leadJson.lead && leadJson.lead.budgetTo) || null,
      bathrooms:
        (leadJson.lead &&
          leadJson.lead.bathrooms &&
          leadJson.lead.bathrooms.length > 0 &&
          leadJson.lead.bathrooms) ||
        null,
      bedrooms:
        (leadJson.lead &&
          leadJson.lead.bedrooms && 
          leadJson.lead.bedrooms.length > 0 &&
          leadJson.lead.bedrooms) ||
        null,
      sizeAreaFrom: (leadJson.lead && leadJson.lead.sizeAreaFrom) || null,
      sizeAreaTo: (leadJson.lead && leadJson.lead.sizeAreaTo) || null,
      community: (leadJson.lead && leadJson.lead.community) || null,
      property: (leadJson.lead && leadJson.lead.property_name) || null,
      sub_community: (leadJson.lead && leadJson.lead.sub_community) || null,
      leadStage: (leadJson.lead && leadJson.lead.lead_stage) || null,
      mobile:
        leadJson &&
        leadJson.lead &&
        leadJson.lead.contact_name &&
        leadJson.lead.contact_name.mobile || null,
    });
  });
  setRerender(Math.random());
};

export const onChangeLeadsRule = async (item, value, setRerender, itemList, values, setData, formType) => {
  if (item.data.searchKey !== 'Leads') return;
  const pathName = window.location.pathname.split('/home/')[1];
  let operationType = (pathName.includes('sale') || pathName.includes('Sale')) ? UnitsOperationTypeEnum.sale.key :  (pathName.includes('lease') || pathName.includes('Lease')) ? (UnitsOperationTypeEnum.rent.key)  : null ; 

  const unitTypeIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'unit_type'));
  setTimeout(() => {
    if(unitTypeIndex !== -1){
    setData(itemList[unitTypeIndex].field.id, []); 
    }
  }, 50);
  
  if (!value)
    return;
  

  setTimeout(async () => 
  {
  const budgetFromIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'client_budget-from'));
  const budgetToIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'client_budget-to'));
  const bedroomIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'bedroom'));
  const propertyIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'property'));



  if (formType === 1) {

    if (propertyIndex !== -1)
      setData('property', ((value && value.property) || {}));

    if (unitTypeIndex !== -1 && !value.property )
      setData('unit_type', ((value && value.unitType) || []));

    if (bedroomIndex !== -1) {
      setData(
        itemList[bedroomIndex].field.id,
        (value && value.bedrooms && value.bedrooms[0] === ""
          ? null
          : value && value.bedrooms) || "  "
      );
      itemList[bedroomIndex].data.enum = [];
    }
    if(unitTypeIndex !== -1 && (value.property && value.property.id))
    {

      itemList[unitTypeIndex].data.enum =  [] ;
      setTimeout(() => {
        setData(itemList[unitTypeIndex].field.id, []); 
      }, 50);
      timer = setTimeout(async () => {
        const res = await GetAllPropertyUnitTypeAPI(
          value.property && value.property.id ,  operationType
        );
        if ((res && res.data && res.data.ErrorId) || !res) return;
        if (unitTypeIndex !== -1) {
          const propertyUnitTypes =
            (res &&
              Array.isArray(res) &&
              res.length &&
              res[0] &&
              res[0].propertyUnitType) ||
            [];
          setTimeout(() => {
            if (propertyUnitTypes.length > 0) {
              propertyUnitTypes.map((ut) => {
                itemList[unitTypeIndex].data.enum.push({
                  lookupItemId: ut?.lookupItemId,
                  lookupItemName: ut?.lookupItemName,
                });
              });
            } else if (propertyUnitTypes.length === 0)
              itemList[unitTypeIndex].data.enum = [];
          }, 500);
          if (formType === 2) itemList[unitTypeIndex].data.isReadonly = false;
        }
        if (bedroomIndex !== -1) {
          const resAvailableBedrooms =
            (res &&
              Array.isArray(res) &&
              res.length &&
              res[0] &&
              res[0].availableBedrooms &&
              res[0].availableBedrooms) ||
            [];
          if (resAvailableBedrooms.length === 0)
            itemList[bedroomIndex].data.enum = [];
          else if (resAvailableBedrooms.length > 0)
            resAvailableBedrooms.map((b) => {
              itemList[bedroomIndex].data.enum.push(b);
            });
        }
        setRerender(Math.random());
      }, 300);
    }
    else if (unitTypeIndex !== -1 && !value.property) 
    {
      timer = setTimeout(async () => {
      const resUnitTyps = await lookupItemsGetId({
        lookupTypeId: UnitTypes.lookupTypeId,
      });
      if ((resUnitTyps && resUnitTyps.data && resUnitTyps.data.ErrorId) || !resUnitTyps) return;
      resUnitTyps &&
      resUnitTyps.map((ut) => {
        itemList[unitTypeIndex].data.enum.push({
            lookupItemId: ut?.lookupItemId,
            lookupItemName: ut?.lookupItemName,
          });
        });
      })
    }
  }
},50);
};
