import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Spinner, Tables } from "../../../../../../../../Components";
import {
  RiskRatingConfigEnum,
  TableActions,
} from "../../../../../../../../Enums";
import { Tooltip } from "@material-ui/core";
import { getDownloadableLink, GetParams } from "../../../../../../../../Helper";
import { ShareDocumentsDialog } from "../../../../../../../../SharedComponents/ShareDocumentsDialog/ShareDocumentsDialog";
import { MediaPreviewDialog } from "../../../../../../TemplatesView/Dialogs";
import { RiskRatingDialog } from "./RiskRatingDialog";
import { GetAllSharedKycDocuments } from "../../../../../../../../Services";
import moment from "moment";

export const DocumentsHistoryView = ({
  parentTranslationPath = "",
  translationPath = "",
}) => {
  const contactId = +GetParams("id");
  const formType = GetParams("formType");
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
  });
  const [activeItem, setActiveItem] = useState(null);
  const [tableActions, setTableActions] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useReducer(reducer, {
    ShareDoc: false,
    PreviewDoc: false,
    RiskRating: false,
    agentApprove: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [kycDocs, setKycDocs] = useState([]);

  const getRatingStyleClass = (ratingName) =>
    Object.values(RiskRatingConfigEnum).find((item) => item.name === ratingName)
      ?.class || "";

  const getPostStatusClass = (postStatus) => {
    switch (postStatus) {
      case "Approved":
        return "c-success";
      case "Pending":
        return "c-pending";
      case "Rejected":
        return "c-danger";
      default:
        break;
    }
  };

  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem({
      ...item,
      templateFileId: item.documentFileGuid || null,
      templateFileName: "KycDocumentTemplate.pdf",
      templateText: "",
      documentName: "KycDocumentTemplate.pdf",
      documentId: item.documentFileGuid,
    });

    if (actionEnum === TableActions.openFile.key)
      setIsDialogOpen({ id: "PreviewDoc", value: true });

    if (actionEnum === TableActions.share.key)
      setIsDialogOpen({ id: "ShareDoc", value: true });

    if (actionEnum === TableActions.RiskRating.key)
      setIsDialogOpen({ id: "RiskRating", value: true });
    if (actionEnum === TableActions.agentApprovedCheck.key)
      if (item.sharedKycDocmentHistoryId && formType) {
        window.open(
          `/share/agent-approval-kyc?documentId=${item.sharedKycDocmentHistoryId}&formType=${formType}`,
          "_blank"
        );
      } else {
        console.error("Missing documentId or formType.");
      }

    if (actionEnum === TableActions.downloadText.key) {
      try {
        const link = document.createElement("a");
        link.setAttribute("download", item.documentName);
        link.href = getDownloadableLink(item.documentFileGuid);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setActiveItem(null);
      } catch (error) {}
    }
  }, []);

  const focusedRowChanged = (rowIndex, item) => {
    if (rowIndex === -1) return;
    const commonActions = [
      {
        enum: TableActions.share.key,
        isDisabled: false,
        externalComponent: null,
      },
      {
        enum: TableActions.downloadText.key,
        isDisabled: false,
        externalComponent: null,
      },
      {
        enum: TableActions.openFile.key,
        isDisabled: false,
        externalComponent: null,
      },
      {
        enum: TableActions.RiskRating.key,
        isDisabled: false,
        externalComponent: null,
      },
    ];
    if (
      item?.postStatusInfo?.enumName.toLowerCase() === "approved" &&
      item?.agentPostStatusInfo?.enumName.toLowerCase() === "pending"
    ) {
      setTableActions([
        ...commonActions,
        {
          enum: TableActions.agentApprovedCheck.key,
          isDisabled: false,
          externalComponent: null,
        },
      ]);
    } else {
      setTableActions(commonActions);
    }
  };

  const getAllSharedKycDocuments = async () => {
    setIsLoading(true);
    const res = await GetAllSharedKycDocuments({
      contactId,
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
    });
    if (!(res && res.status && res.status !== 200)) {
      setKycDocs(res || []);
    } else {
      setKycDocs([]);
    }
    setIsLoading(false);
  };
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    getAllSharedKycDocuments();
  }, [filter]);

  return (
    <div className="RiskRatingView propx-view-container sm p-3">
      <Spinner isActive={isLoading} isAbsolute />
      <Tables
        data={kycDocs?.result}
        headerData={[
          {
            id: 1,
            label: "Adding date",
            input: "addingDate",
            component: (item) => (
              <span>
                {item.addingDate ? moment(item.addingDate).format("LL") : ""}
              </span>
            ),
          },
          {
            id: 1,
            label: "Expiry date",
            input: "expiryDate",
            component: (item) => (
              <span>
                {item.expiryDate ? moment(item.expiryDate).format("LL") : ""}
              </span>
            ),
          },
          {
            id: 2,
            label: "Created by",
            input: "createdBy",
          },
          {
            id: 3,
            label: "Risk rating",
            input: "riskRatingDurationTypeInfo",
            component: (item) => (
              <div className="d-flex fa-center gap-12">
                <span
                  className={`RateDot ${getRatingStyleClass(
                    item.riskRatingDurationTypeInfo?.enumName
                  )}`}
                ></span>
                <span className="fw-simi-bold">
                  {item.riskRatingDurationTypeInfo?.enumName || "-"}
                </span>
              </div>
            ),
          },
          {
            id: 4,
            label: "KYC status",
            input: "kycStatusInfo",
            component: (item) => (
              <div className="d-flex fa-center gap-12">
                <span
                  className={`info-capsule ${
                    item.kycStatusInfo?.enumName == "Active" ? "green" : "red"
                  }`}
                >
                  {item.kycStatusInfo?.enumName || "-"}
                </span>
              </div>
            ),
          },
          {
            id: 5,
            label: "Post status",
            input: "postStatusInfo",
            component: (item) => (
              <div className="d-flex-h-between fa-center gap-12">
                <span
                  className={`${getPostStatusClass(
                    item.postStatusInfo?.enumName
                  )}`}
                >
                  {item.postStatusInfo?.enumName || ""}
                </span>
                {item.isRiskRatingHasBeenChanged ? (
                  <Tooltip title="Risk rating has been changed">
                    <span className="mdi mdi-help-circle"></span>
                  </Tooltip>
                ) : null}
              </div>
            ),
          },
          {
            id: 5,
            label: "Agent Post status",
            input: "agentPostStatusInfo",
            component: (item) => (
              <div className="d-flex-h-between fa-center gap-12">
                <span
                  className={`${getPostStatusClass(
                    item.agentPostStatusInfo?.enumName
                  )}`}
                >
                  {item.agentPostStatusInfo?.enumName || ""}
                </span>
              </div>
            ),
          },
          {
            id: 6,
            label: "Reason",
            input: "reason",
          },
        ]}
        defaultActions={tableActions}
        focusedRowChanged={focusedRowChanged}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        totalItems={kycDocs?.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />

      {isDialogOpen.ShareDoc && (
        <ShareDocumentsDialog
          open={isDialogOpen.ShareDoc}
          activeItem={activeItem}
          onSave={() => {
            setIsDialogOpen({ id: "ShareDoc", value: false });
          }}
          close={() => setIsDialogOpen({ id: "ShareDoc", value: false })}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          disableLeadsRecipient
        />
      )}

      {isDialogOpen.RiskRating && (
        <RiskRatingDialog
          open={isDialogOpen.RiskRating}
          activeItem={activeItem}
          onSave={() => {
            setIsDialogOpen({ id: "RiskRating", value: false });
            getAllSharedKycDocuments();
          }}
          close={() => setIsDialogOpen({ id: "RiskRating", value: false })}
        />
      )}
      {isDialogOpen.PreviewDoc && (
        <MediaPreviewDialog
          activeItem={activeItem}
          maintitleText="FILE-VIEW"
          isOpen={isDialogOpen.PreviewDoc}
          isOpenChanged={() => {
            setIsDialogOpen({ id: "PreviewDoc", value: false });
            setActiveItem(null);
          }}
          parentTranslationPath="Shared"
        />
      )}
    </div>
  );
};
