import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import Mustache from 'mustache';
import { useSelector } from "react-redux";
import "./KycFormsView.scss";
import {
  PageHeading,
} from "../../../../../../../../SharedComponents";
import { CustomButton } from "../../../../../../../../Components";
import btnLoader from "../../../../../../../../assets/images/GIF_images/btn-loader.gif";
import { IndividualForm } from "./Forms/IndividualForm/IndividualForm";
import {
  GetKycFormDetailsByContactId,
  ShareKycDocument,
  UpdateKycDetails,
} from "../../../../../../../../Services";
import {
  bottomBoxComponentUpdate,
  GetParams,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../../../../Helper";
import {
  useLayoutDetection
} from "../../../../../../../../Hooks";
import { CorporateForm } from "./Forms/CorporateForm/CorporateForm";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IndividualKycFormMap } from "./Forms/IndividualForm/IndividualKycFormMap";
import { CorporateKycFormMap } from "./Forms/CorporateForm/CorporateKycFormMap";
import { KYCFormHTMLTemplate } from "./Forms/Utilities/KYCFormHTMLTemplate";

// Icons
import {
  PrintIcon,
  WhatsappIcon,
  EmailIcon,
} from "../../../../../../../../assets/icons";

export const KycFormsView = ({parentTranslationPath = "", translationPath = "",}) => {
  const contactId = GetParams("id");
  const contactsType = +GetParams("formType");
  const { t } = useTranslation(parentTranslationPath);
  const clientFormRef = useRef(null);
  const {isNewLayout, hasNewLayout} = useLayoutDetection();
  const { isDarkMode } = useSelector((state) => state.theme);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const ShareViaEnum = {
    Email: {
      id: 1,
      name: "Email",
    },
    WhatsApp: {
      id: 2,
      name: "WhatsApp",
    },
  };

  const [selected, setSelected] = useReducer(reducer, {});
  const [isLoading, setIsLoading] = useReducer(reducer, {
    save: false,
    get: false,
    discard: false,
    shareForm: false,
  });

  const clearPEPFields = () => {
      setSelected({ id: "nameOfPEP", value: "" });
  }
  const clearOnBehalfFields = () => {
    setSelected({
      id: "edit", value: {
        ...selected,
        nameOfBeneficiary: "",
        natureOfRelationship: "",
        idType: "",
        nationalityOfBeneficiaryInfo: null,
        isBeneficiaryPEP: null,
        emailAddressOfBeneficiary: "",
        contactNumberOfBeneficiary: "",
      }
    });
  }

  const getKYCIndividualFormDto = () => {

    const occupationLookupId =  selected.occupationInfo?.lookupItemId ||
    selected.occupationInfo?.lookupsId || null;
    const occupationInfo = occupationLookupId? {
      lookupItemName: selected.occupationInfo?.lookupItemName || null,
      lookupsId: occupationLookupId,
    } : null;

    const sourceOfFundLookupId = selected.sourceOfFundInfo?.lookupItemId ||
    selected.sourceOfFundInfo?.lookupsId || null;
    const sourceOfFundInfo = sourceOfFundLookupId? {
      lookupItemName: selected.sourceOfFundInfo?.lookupItemName || null,
      lookupsId: sourceOfFundLookupId,
    } : null;


    const paymentMethodLookupId = selected.paymentMethodInfo?.lookupItemId ||
    selected.paymentMethodInfo?.lookupsId || null;
    const paymentMethodInfo = paymentMethodLookupId? {
      lookupItemName: selected.paymentMethodInfo?.lookupItemName || null,
      lookupsId: paymentMethodLookupId,
    } : null;


    const countryOfResidenceLookupId = selected.countryOfResidenceInfo?.lookupItemId ||
    selected.countryOfResidenceInfo?.lookupsId || null;
    const countryOfResidenceInfo = countryOfResidenceLookupId? {
      lookupItemName: selected.countryOfResidenceInfo?.lookupItemName || null,
      lookupsId: countryOfResidenceLookupId,
    } : null;


    const nationalityOfBeneficiaryLookupId = selected.nationalityOfBeneficiaryInfo?.lookupItemId ||
    selected.nationalityOfBeneficiaryInfo?.lookupsId || null;
    const nationalityOfBeneficiaryInfo = nationalityOfBeneficiaryLookupId? {
      lookupItemName: selected.nationalityOfBeneficiaryInfo?.lookupItemName || null,
      lookupsId: nationalityOfBeneficiaryLookupId,
    } : null;


    const docsAttachedAsObj = getDocsAttachedAsObj();

    if(!selected.isActingOnBehalf) clearOnBehalfFields();
    if(!selected.isRelatedToPEP) clearPEPFields();

    return {
      ...selected,
      occupationInfo,
      sourceOfFundInfo,
      paymentMethodInfo,
      countryOfResidenceInfo,
      nameOfPEP: selected.isRelatedToPEP? selected.nameOfPEP : null,
      nameOfBeneficiary: selected.isActingOnBehalf? selected.nameOfBeneficiary : null,
      natureOfRelationship: selected.isActingOnBehalf? selected.natureOfRelationship : null,
      idType: selected.isActingOnBehalf? selected.idType : null,
      nationalityOfBeneficiaryInfo: selected.isActingOnBehalf? nationalityOfBeneficiaryInfo : null,
      isBeneficiaryPEP: selected.isActingOnBehalf? selected.isBeneficiaryPEP : null,
      emailAddressOfBeneficiary: selected.isActingOnBehalf? selected.emailAddressOfBeneficiary : null,
      contactNumberOfBeneficiary: selected.isActingOnBehalf? selected.contactNumberOfBeneficiary : null,
      occupationAsString: null,
      sourceOfFundsAsString: null,
      paymentMethodAsString: null,
      countryOfResidenceAsString: null,
      nationalityOfBeneficiaryInfoAsString: null,
      attachedDocTypes: null,
      ...(docsAttachedAsObj || {}),
    };
  };



  const getKYCCorporateFormDto = () => {

    const paymentMethodLookupId =  selected.paymentMethodInfo?.lookupItemId ||
    selected.paymentMethodInfo?.lookupsId || null;
    const paymentMethodInfo = paymentMethodLookupId? {
      lookupItemName: selected.paymentMethodInfo?.lookupItemName || null,
      lookupsId: paymentMethodLookupId,
    } : null;

    const legalStructureLookupId =  selected.legalStructureInfo?.lookupItemId ||
    selected.legalStructureInfo?.lookupsId || null;
    const legalStructureInfo = legalStructureLookupId? {
      lookupItemName: selected.legalStructureInfo?.lookupItemName || null,
      lookupsId: legalStructureLookupId,
    } : null;

    const companySignatories = selected.companySignatories
      ? selected.companySignatories.map((item) => ({
          companySignatoryId: item.companySignatoryId || 0,
          contactId: item.contactId,
          specimenSignature: item.specimenSignature,
          contactName: null,
        }))
      : [];

    const companyStakeholdersInformation =
      selected.companyStakeholdersInformation
        ? selected.companyStakeholdersInformation.map((item) => ({
            companyStakeholdersInformationId:
              item.companyStakeholdersInformationId || 0,
            contactStakeholderId: item.contactStakeholderId || item.contactId,
            residenceStatus:
              item.residenceStatus?.lookupItemName ||
              item.residenceStatus ||
              null,
            sharePercentage: +item.sharePercentage,
            contactName: null,
          }))
        : [];

    const companyPartnerNamesAndNationalities =
      selected.companyPartnerNamesAndNationalities
        ? selected.companyPartnerNamesAndNationalities.map((item) => ({
            companyPartnerNamesAndNationalityId:
              item.companyPartnerNamesAndNationalityId || 0,
            contactId: item.contactId,
            percentage: +item.percentage,
            contactName: null,
          }))
        : [];

    return {
      ...selected,
      legalStructureInfo,
      paymentMethodInfo,
      companySignatories,
      companyStakeholdersInformation,
      companyPartnerNamesAndNationalities,
      legalStructureAsString: null,
      paymentMethodAsString: null,
      companyPartnerNamesAndNationalitiesAsString: null,
      companySignatoriesAsString: null,
      companyStakeholdersInformationAsString: null,
    };
  };


  const getFormattedDTO = () => {
    const isIndividualContact = contactsType === 1;

    return {
      contactId,
      contactsType,
      kycIndividualFormDto: isIndividualContact ? getKYCIndividualFormDto() : null,
      kycCorporateFormDto: !isIndividualContact ? getKYCCorporateFormDto() : null,
    };
  };

  const getDocsAttachedAsList = (formDetails) => {
    const docsAttachedAsList = [
      {
        key: "hasProofOfAddress",
        label: "Has Proof Of Address",
        value: formDetails.hasProofOfAddress || false,
      },
      {
        key: "hasProofOfSourceOfFunds",
        label: "Has Proof Of Source Of Funds",
        value: formDetails.hasProofOfSourceOfFunds || false,
      },
      {
        key: "hasProofOfPayment",
        label: "Has Proof Of Payment",
        value: formDetails.hasProofOfPayment || false,
      },
      {
        key: "hasDealDocuments",
        label: "Has Deal Documents",
        value: formDetails.hasDealDocuments || false,
      },
      {
        key: "hasIdCardOrPassport",
        label: "Has Id Card Or Passport",
        value: formDetails.hasIdCardOrPassport || false,
      },
    ];

    setSelected({ id: "attachedDocTypes", value: docsAttachedAsList });
  };

  
  const getKycFormDetails = async () => {
    setIsLoading({ id: "get", value: true });

    const res = await GetKycFormDetailsByContactId(contactId, contactsType);
    if (!(res && res.status && res.status !== 200)) {
      setIsLoading({ id: "get", value: false });
      const isIndividualContact = contactsType === 1;
      const formDetails = isIndividualContact
        ? res?.kycIndividualFormDto
        : res?.kycCorporateFormDto;
      setSelected({ id: "edit", value: formDetails });
      if (isIndividualContact) getDocsAttachedAsList(formDetails);
      return formDetails;
    } else setSelected({ id: "edit", value: {} });

    setIsLoading({ id: "get", value: false });
  };

  const isFormValidated = () => {
    let isValidated = true;
    const isCorporateRequriedFieldsExist = selected.paymentMethodInfo && selected.legalStructureInfo;
    const isPEPRequriedFieldsExist = selected.nameOfPEP;
    const isOnBehalfRequriedFieldsExist = selected.nameOfBeneficiary && selected.natureOfRelationship
      && selected.idType && selected.nationalityOfBeneficiaryInfo
      && (typeof selected.isBeneficiaryPEP === "boolean") && selected.emailAddressOfBeneficiary
      && selected.contactNumberOfBeneficiary;

    if(contactsType == 2 && !isCorporateRequriedFieldsExist) { showWarn(`Please fill the required fields .`); isValidated = false; };
    if(contactsType == 1 && selected.isActingOnBehalf && !isOnBehalfRequriedFieldsExist) { showWarn(`Please fill On Behalf fields .`); isValidated = false; };
    if(contactsType == 1 && selected.isRelatedToPEP  && !isPEPRequriedFieldsExist) { showWarn(`Please fill PEP relationship field .`); isValidated = false; };

    return isValidated;
  }
  
  const reloadData = async () => {
    const formDetails = await getKycFormDetails();
    return formDetails;
  }

  const updateKycDetails = async ({isForFormCreate}) => {
    const body = getFormattedDTO();

    const res = await UpdateKycDetails(body);
    if (res === true) {
      if (!isForFormCreate) showSuccess(`Changes are saved successfully!`);
      const formDetails =  await reloadData();
      return formDetails;
    } else if(!isForFormCreate) showError(`Failed to save changes..`);
  };

  const getDocsAttachedAsObj = () => {
    const docsAttachedAsObj = {};
    if (selected.attachedDocTypes?.length > 0)
      selected.attachedDocTypes.forEach((item) => {
        docsAttachedAsObj[item.key] = item.value;
      });
    return docsAttachedAsObj;
  };

  const saveHandler = async() => {
    if(!isFormValidated()) return;

    setIsLoading({ id: "save", value: true });
    await updateKycDetails({});
    setIsLoading({ id: "save", value: false });
  };

  
  const discardClickHandler = async () => {
    setIsLoading({ id: "discard", value: true });
    await reloadData();
    setIsLoading({ id: "discard", value: false });
  }
  
  const getClientFormMap = () => {
    const isIndividualContact = contactsType === 1;
    const formMap = isIndividualContact ? IndividualKycFormMap : CorporateKycFormMap;
    const stringifiedMap = JSON.stringify(formMap);
    
    const clientFormMap = stringifiedMap.replace(/selectInput|readOnly/g, "textInput");
    const parsedMap = JSON.parse(clientFormMap);

    return parsedMap;
  }

  const returnFormAsHtml = async({showActionButtons}) => {
    const KYCFormContent = {
      innerHTML: clientFormRef.current?.innerHTML || "",
      showActionButtons 
    }
    const KYCHtml = await Mustache.render(KYCFormHTMLTemplate, KYCFormContent);
    return KYCHtml;
  }
  
  const printHandler = async () => {
    const content = await returnFormAsHtml({showActionButtons: false});
    const iframeElement = document.getElementById('formPrintIframe').contentWindow;
    iframeElement.document.open();
    iframeElement.document.write(content);
    iframeElement.document.close();
    iframeElement.focus();
    iframeElement.print();
  }

  const shareKycDocument = async ({kycFormId, shareVia}) => {

    if(shareVia == ShareViaEnum.WhatsApp.id && !isWhatsAppNumExist()) return;
    setIsLoading({ id: "shareForm", value: true });
    const body = {
      kycFormId: kycFormId || selected.kycFormId || null,
      contactId: +contactId,
      shareVia: shareVia,
      kycDocumentContent: await returnFormAsHtml({showActionButtons: true}),
    }
    const res = await ShareKycDocument(body);

    if (res?.isSuccess === true) {
      if (shareVia == ShareViaEnum.Email.id) showSuccess(`Shared Successfully!`);
      if (shareVia == ShareViaEnum.WhatsApp.id) openWhatsApp(res.message);
    } else showError(`Sharing has failed ..`);

    setIsLoading({ id: "shareForm", value: false });
  };

  const getWhatsAppNum = () => {
    const activeContact = localStorage.getItem('parentContact')
    const parsedActiveContact = activeContact? JSON.parse(activeContact) : null;
    return parsedActiveContact?.whatsapp_mobile?.phone || parsedActiveContact?.landline_number?.phone  || null;
  }
  
  const isWhatsAppNumExist = () => {
    const whatsAppNumber = getWhatsAppNum();
    if(!whatsAppNumber) {
      showWarn(`This contact has no available WhatsApp number.`);
      return;
    } else return whatsAppNumber;
  }
  
  const openWhatsApp = (msg) => {
    const UrlEncodedMessage =  encodeURIComponent(msg);
    const UrlToWhatsApp = `https://wa.me/${getWhatsAppNum()}?text=${UrlEncodedMessage}`
    window.open(UrlToWhatsApp);
  }

  const shareHandler = async (shareVia) => {
    const isFormIdExist = selected.kycFormId;
    if(isFormIdExist) shareKycDocument({shareVia});
    else {
      const formDetails = await updateKycDetails({ isForFormCreate: true });
      if(formDetails) shareKycDocument({kycFormId: formDetails.kycFormId, shareVia});
    }
  }
  
  const checkIsButtonDisabled = () => (isLoading.discard || isLoading.get || isLoading.shareForm || isLoading.save);

  useEffect(() => {
    getKycFormDetails();
  }, []);
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    [selected]
  );

  return (
    <div>
      {isNewLayout && hasNewLayout && (
        <div className="buttonActions">
          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="secondary"
            onClick={printHandler}
            startIcon={<PrintIcon width="20" height="20" fill={!isDarkMode ? "#635235" : "#CECFD2"} />}
          >
            Print
          </CustomButton>

          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="secondary"
            onClick={() => shareHandler(ShareViaEnum.WhatsApp.id)}
            startIcon={<WhatsappIcon width="20" height="20" fill={!isDarkMode ? "#635235" : "#CECFD2"} />}
          >
            Send by Whatsapp
          </CustomButton>

          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="secondary"
            onClick={() => shareHandler(ShareViaEnum.Email.id)}
            startIcon={<EmailIcon width="20" height="20" fill={!isDarkMode ? "#635235" : "#CECFD2"} />}
          >
            Send by mail
          </CustomButton>
        </div>
      )}

      <div className={isNewLayout && hasNewLayout ? "newKycFormsView" : "KycFormsView"}>
        <div
          className={`d-flex-h-between ${
            isNewLayout && hasNewLayout ? "mx-0 mt-0" : "w-75 mx-5 mt-3"
          } fa-center`}
        >
          <PageHeading
            headerTitle={"Know Your Client Form (KYC)"}
            subTitle={`All details must be completed by each Individual Client.`}
            headerTitleClasses={"fz-18 mb-3"}
            wrapperClasses={!(isNewLayout && hasNewLayout) ?"pt-2 w-75": ""}
            subTitleClasses={"fz-16 m-0 ml-0"}
          />

          {!(isNewLayout && hasNewLayout) && (
            <div className="form-top-actions d-flex-default fj-center my-4">
              <ButtonBase
                onClick={printHandler}
                className="btns theme-propx outlined"
              >
                <span className="mdi mdi-printer" title="Print form"></span>
              </ButtonBase>

              <ButtonBase
                className="btns theme-propx solid mr-0"
                onClick={() => shareHandler(ShareViaEnum.WhatsApp.id)}
                disabled={checkIsButtonDisabled()}
              >
                <span
                  className="mdi mdi-whatsapp"
                  title="Share via whatsapp"
                ></span>
              </ButtonBase>
              <ButtonBase
                className="btns theme-propx solid mr-0"
                onClick={() => shareHandler(ShareViaEnum.Email.id)}
                disabled={checkIsButtonDisabled()}
              >
                <span className="mdi mdi-email" title="Share via email"></span>
              </ButtonBase>
            </div>
          )}
        </div>

        {!isLoading?.get && contactsType === 1 ? (
          <IndividualForm
            clientFormRef={clientFormRef}
            clientFormMap={getClientFormMap()}
            selected={selected}
            onSelectedChange={(key, selectValue) =>
              setSelected({ id: key, value: selectValue })
            }
          />
        ) : null}
        {!isLoading?.get && contactsType === 2 ? (
          <CorporateForm
            clientFormRef={clientFormRef}
            clientFormMap={getClientFormMap()}
            selected={selected}
            onSelectedChange={(key, selectValue) =>
              setSelected({ id: key, value: selectValue })
            }
          />
        ) : null}
        <iframe
          id="formPrintIframe"
          style={{ minHeight: 500, display: "none" }}
          width="100%"
          height="100%"
        />

        {isNewLayout && hasNewLayout ? (
          <div className="actions">
            <CustomButton
              variant="outlined"
              color="secondary"
              onClick={discardClickHandler}
            >
              {t(`${translationPath}Discard`)}
            </CustomButton>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={saveHandler}
              disabled={checkIsButtonDisabled()}
            >
              {t(`${translationPath}Save`)}
            </CustomButton>
          </div>
        ) : (
          <div className="mx-5 w-75 d-flex fj-center my-4">
            <ButtonBase
              onClick={discardClickHandler}
              className="btns theme-propx outlined"
              disabled={checkIsButtonDisabled()}
            >
              {t(`${translationPath}Discard`)}
              {isLoading.discard ? (
                <img src={btnLoader} className="ml-1" width="12" height="12" />
              ) : null}
            </ButtonBase>

            <ButtonBase
              className="btns theme-propx solid mr-0"
              onClick={saveHandler}
              disabled={checkIsButtonDisabled()}
            >
              {t(`${translationPath}Save`)}
              {isLoading.save ? (
                <img src={btnLoader} className="ml-1" width="12" height="12" />
              ) : null}
            </ButtonBase>
          </div>
        )}
      </div>
    </div>
  );
};
