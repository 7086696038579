import React, { useLayoutEffect, useRef, useState } from 'react';
import './ScrollableText.scss'; // Add styles here
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

const ScrollableText = ({ children, scrollStep = 100 }) => {
  const scrollRef = useRef(null);
  const [showButtons, setShowButtons] = useState(false);

  const updateButtonVisibility = () => {
    if (scrollRef.current) {
      const { offsetWidth, scrollWidth } = scrollRef.current;
      setShowButtons(scrollWidth > offsetWidth);
    }
  };
  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -scrollStep : scrollStep; // Adjust scroll amount
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };
  useLayoutEffect(() => {
    updateButtonVisibility();
  }, []);
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      {showButtons && (
        <IconButton onClick={() => scroll('left')} size='small'>
          <ChevronLeft fontSize='small' />
        </IconButton>
      )}
      <div ref={scrollRef} className='scroll-container'>
        <div className='scroll-content'>{children}</div>
      </div>
      {showButtons && (
        <IconButton onClick={() => scroll('right')} size='small'>
          <ChevronRight fontSize='small' />
        </IconButton>
      )}
    </div>
  );
};

export default ScrollableText;
