import React, { useState, useEffect, useRef, useReducer } from "react";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../../Components";
import { GetParams, showError, showSuccess } from "../../../../../Helper";
import { AgreementDetailsTable } from "./AgreementDetailsTable";
import {
  CreateAgreementDetailsAPI,
  GetAgreementDetailsByDeveloperContactIdAPI,
} from "../../../../../Services";
import { AgreementDetailsManagementComponents } from "./AgreementDetailsManagement";

export const AgreementDetails = ({
  translationPath,
  parentTranslationPath,
  contactReletedWithDeveloperId 
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [isLoading, setIsLoading] = useState({
    allAgreementDetails: false,
    addAgreementDetails: false,
  });

  const [items, setItems] = useState({
    allAgreementDetails: {
      result: [],
      totalCount: 0,
    },
  });

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    agreementTypeId: null,
    agreementStartDate: null,
    agreementDuration: null,
    agreementEndDate: null,
    isMarketingBudgetAllocation: false,
    marketingBudgetAmount: null,
    agreementDetailsProjects: [],
    agreementDetailsSigningBranches: [],
    agreementDetailsAuthorizedSignatories: [],
  });

  const [defaultSelected, setDefaultSelected] = useState({
    agreementType: null,
    marketingBudgetAllocation: null,
    agreementDetailsProjects: [],
    agreementDetailsSigningBranches: [],
    agreementDetailsAuthorizedSignatories: [],
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const GetAgreementDetailsByDeveloperContactId = async (search) => {
    setIsLoading((item) => ({ ...item, allAgreementDetails: true }));
    const rs = await GetAgreementDetailsByDeveloperContactIdAPI(
      +GetParams("id"),
      {
        pageIndex: filter.pageIndex + 1,
        pageSize: filter.pageSize,
        name: filter.search,
      }
    );

    if (!rs) {
      setItems((item) => ({
        ...item,
        allAgreementDetails: { result: [], totalCount: 0 },
      }));
      return;
    } else {
      setItems((item) => ({
        ...item,
        allAgreementDetails: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    }
    setIsLoading((item) => ({ ...item, allAgreementDetails: false }));
  };

  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    setSelected({ id: "edit", value: defaultSelected });
    GetAgreementDetailsByDeveloperContactId();
  };

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };
  const AddAgreementDetailsHandler = async () => {
    setIsLoading((item) => ({ ...item, addAgreementDetails: true }));
    const res = await CreateAgreementDetailsAPI(state);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}agreement-details-created-successfully`);
      setIsLoading((item) => ({ ...item, addAgreementDetails: false }));

      reloadData();
    } else {
      showError(t`${translationPath}agreement-details-create-failed`);
      setIsLoading((item) => ({ ...item, addAgreementDetails: false }));
    }
  };

  useEffect(() => {
    reloadData();
  }, [filter]);

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <Spinner isActive={isLoading.allAgreementDetails} isAbsolute />

        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <AgreementDetailsManagementComponents
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={setState}
              selected={selected}
              onChangeHandler={onChangeHandler}
              className={"px-2 item"}
              contactReletedWithDeveloperId={contactReletedWithDeveloperId}
            />
            <div className="mt-3 d-flex-v-center-h-end flex-wrap">
              <ButtonBase
                className="btns theme-solid"
                onClick={AddAgreementDetailsHandler}
                disabled={
                  isLoading.addAgreementDetails || !selected.agreementType
                }
              >
                <span className="mdi mdi-plus" />
                {t(`${translationPath}add-agreement-details`)}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
      <AgreementDetailsTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        data={items.allAgreementDetails}
        filter={filter}
        setFilter={setFilter}
        reloadData={reloadData}
        contactReletedWithDeveloperId={contactReletedWithDeveloperId}
      />
    </div>
  );
};
